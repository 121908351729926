/* istanbul ignore file */
import _get from 'lodash/fp/get';
import { useEffect, useState } from 'react';

import { api } from 'app/api/base';
import { URL_AUTH_CONNECTOR_ENROLLMENTS } from 'app/api/urls';
import type { AuthBlueprint } from 'src/pages/ManualEnrollment/ManualEnrollment.types';
import { getAuthConfigBlueprints } from 'src/pages/ManualEnrollment/hooks/useGetAuthBlueprints';

const getConn = _get([0, 'custom_web_view', 'sso', 'auth0_connection_id']);
const getEnrollment = (id) => api(URL_AUTH_CONNECTOR_ENROLLMENTS(id)).get();

function buildADEEnrollments(connections: Array<{ data: any }>) {
  const adeEnrollments = {};

  for (const connection of connections) {
    const { data } = connection;
    const connectionId = getConn(data);

    if (connectionId != null) {
      adeEnrollments[connectionId] = data.map(({ id, name }) => ({
        id,
        name,
      }));
    }
  }

  return adeEnrollments;
}

function buildManualEnrollments(authBlueprints: AuthBlueprint[]) {
  const manualEnrollments = {};

  for (const blueprint of authBlueprints) {
    const auth0Client = blueprint.authConfig.auth0_client;
    const auth0Config = auth0Client.auth0_configuration;
    const connectionId = auth0Config.connection_id;
    const manualEnrollment = manualEnrollments[connectionId] ?? [];

    manualEnrollment.push({
      id: blueprint.id,
      name: blueprint.name,
    });

    manualEnrollments[connectionId] = manualEnrollment;
  }

  return manualEnrollments;
}
function useConnectionEnrollments({ connectionIds = [] }) {
  const [enrollments, setEnrollments] = useState({
    adeEnrollments: {},
    manualEnrollments: {},
  });

  useEffect(() => {
    Promise.all([
      getAuthConfigBlueprints(),
      ...connectionIds.map(getEnrollment),
    ])
      .then((res) => {
        const [authBlueprints, ...connections] = res;
        const adeEnrollments = buildADEEnrollments(connections);
        const manualEnrollments = buildManualEnrollments(authBlueprints);
        const enrollments = {
          adeEnrollments,
          manualEnrollments,
        };

        setEnrollments({ ...enrollments });
      })
      .catch(() => null);
  }, []);

  return enrollments;
}

export default useConnectionEnrollments;
