import { Button, setClass, Toaster as toaster } from '@kandji-inc/bumblebee';
import { Chip, Flex, Paragraph, styled } from '@kandji-inc/nectar-ui';
import { links } from 'app/common/constants';
import {
  useAuthority,
  useConnector,
  useModal,
} from 'features/integrations/components-for-specific-types/adcs/hooks';
import {
  STATUS_CONNECTED,
  STATUS_PENDING,
} from 'features/integrations/components-for-specific-types/adcs/status';
import { newIntegrationsService } from 'features/integrations/data-service/new-integrations-service';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { func, object } from 'prop-types';
/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import OptionsMenu from '../../../util/components/options-menu';
import configurations from '../../components-for-specific-types/configurations';
import { INT_CATEGORIES, INT_TYPES } from '../../constants';
import cardConfigurations from '../../generic-cards-view/cards-config';
import './overview-integration-card.styles.scss';

import featureFlags from '../../../../config/feature-flags';
import useGetIntegrationDetails from '../../components-for-specific-types/ms-device-compliance/hooks/use-get-integration-details';
import { useGetMSTeamsIntegrations } from '../../components-for-specific-types/ms-teams/hooks';
import { useGetOktaDeviceTrust } from '../../components-for-specific-types/okta/hooks';
import { StyledHeaderImage } from '../../components/styled-components/main';
import Bell from './assets/bell.svg';
import CheckCircle from './assets/check-circle.svg';
import Clock from './assets/clock.svg';

const StyledSubTitle = styled(Paragraph, {
  color: '$neutral60',
});

const StyledMainText = styled(Paragraph, {
  color: '$neutral70',
});

const StyledChip = styled(Chip, {
  marginRight: '$2',
});

const DESCRIPTIVE_WORDS = ['yesterday', 'today'];
function formatRelativeDate(dateAsString) {
  const asDate = DateTime.fromISO(dateAsString);
  const fromToday = asDate.toRelativeCalendar({ locale: 'en' });
  const time = asDate.toFormat('h:mm a');
  const relative = asDate.toRelative();
  const date =
    relative === fromToday || !DESCRIPTIVE_WORDS.includes(fromToday)
      ? asDate.toFormat('MMM d yyyy')
      : capitalize(fromToday);
  return `Last import: ${date} at ${time} (${relative})`;
}

function OverviewIntegrationCard({
  integrationData,
  requestDeletingIntegration,
  startViewingIntegration,
  startEditingIntegration,
  requestRotatingScimIntegration,
}) {
  const {
    type,
    name,
    amount,
    category,
    synced_at,
    is_syncing,
    servicenow_configs: servicenowConfigs,
    uuid: integrationId,
    id,
  } = integrationData;

  const { title, img } = cardConfigurations[type];
  const { integrationAlias, makeIntegrationLink } = configurations[type] || {};
  const history = useHistory();
  const [isSyncing, setIsSyncing] = useState(is_syncing);

  const isReAuthenticateFlagActive = featureFlags.getFlag(
    'int-re-authenticate',
  );
  const isAzureReauthenticationFlagActive = featureFlags.getFlag(
    'int-azure-re-authenticate',
  );

  const isAssetManagement = category === INT_CATEGORIES.assetManagement;
  const isCommunicationInt = category === INT_CATEGORIES.communication;
  const isSecurityInt = category === INT_CATEGORIES.security;
  const isDirectoryInt = category === INT_CATEGORIES.user;
  const isScim = type === INT_TYPES.scim;
  const isADCS = type === INT_TYPES.adcs;
  const isODT = type === INT_TYPES.okta; // okta device trust
  const isServicenow = type === INT_TYPES.servicenow;
  const isMsTeams = type === INT_TYPES.msTeams;
  const isGSuite = type === INT_TYPES.gsuite;
  const isAzure = type === INT_TYPES.office365;
  const isMSDC = type === INT_TYPES.msDeviceCompliance;

  const { openModal, isOpen } = useModal();
  const {
    connectorList,
    fetchConnectorList,
    setConnector,
    clearConnectorList,
  } = useConnector();
  const { authorityList, fetchAuthorityList, clearAuthorityList } =
    useAuthority();

  const { data: teamsIntegrations } = useGetMSTeamsIntegrations();
  const { data: odtData } = useGetOktaDeviceTrust();
  const { data: msdcIntegrationData = {}, isLoading: isMsdcLoading = true } =
    isMSDC ? useGetIntegrationDetails(integrationId) : {};

  const isInteractive = [
    isCommunicationInt,
    isSecurityInt,
    isAssetManagement,
  ].some(Boolean);

  const odtIntegrationData = odtData?.results?.[0];

  // isConnected is true if the status of ANY connector is connected
  const isConnected =
    connectorList.length > 0 &&
    !!connectorList.find((connector) => connector.status === STATUS_CONNECTED);

  const hasSinglePendingConnector =
    connectorList.length === 1 && connectorList[0].status === STATUS_PENDING;

  useEffect(() => {
    if (isADCS && isOpen) {
      fetchConnectorList();
      fetchAuthorityList();
    }
    return () => {
      clearConnectorList();
      clearAuthorityList();
    };
  }, []);

  function getTitle() {
    // This determines if some integrations have names provided by the user or if the title is the integration name.
    return [isCommunicationInt, isSecurityInt, isAssetManagement].some(Boolean)
      ? title
      : name;
  }

  function getNumEventNotificationsText() {
    const integrations = teamsIntegrations?.integrations;
    if (!integrations) {
      return '';
    }

    const numEventNotifications = integrations.reduce(
      (prev, curr) => prev.concat(curr.notifications),
      [],
    ).length;
    if (numEventNotifications < 1) {
      return '';
    }
    const pluralized =
      numEventNotifications > 1 ? 'notifications' : 'notification';
    return `${numEventNotifications} event ${pluralized}`;
  }

  function getSubTitle() {
    const showSubTitle = !(isCommunicationInt || !synced_at);
    return (
      showSubTitle && (
        <StyledSubTitle size="2">
          {formatRelativeDate(synced_at)}
        </StyledSubTitle>
      )
    );
  }

  function getMainText() {
    let mainTitle = title;

    if (isServicenow) {
      const servicenowTenant = servicenowConfigs?.[0]?.servicenow_tenant;
      mainTitle = `Domain: ${servicenowTenant}.service-now.com`;
    } else if (isCommunicationInt) {
      const integrationWord = capitalize(integrationAlias);
      mainTitle =
        amount === 1
          ? `${integrationWord}: ${makeIntegrationLink(name)}`
          : `${integrationWord}s: ${amount}`;
    } else if (isADCS) {
      mainTitle = isConnected
        ? `Connectors: ${connectorList.length}`
        : 'Pending installation...';
    } else if (isODT) {
      mainTitle = odtIntegrationData?.domain
        ?.replace('https://', '')
        .replace('/', '');
    } else if (isMSDC && !isMsdcLoading) {
      mainTitle = msdcIntegrationData?.data?.ms_domain_name;
    }

    return <StyledMainText size="1">{mainTitle}</StyledMainText>;
  }

  function getIcon() {
    if (!isADCS) {
      return null;
    }
    const src = isConnected ? CheckCircle : Clock;
    const alt = isConnected ? 'success icon' : 'pending icon';
    return <img className="int-overview-card_icon" src={src} alt={alt} />;
  }

  function getBadges() {
    const badgeList = [];

    // ODT
    if (isODT && odtIntegrationData) {
      const { ios_configured, mac_configured } = odtIntegrationData;
      badgeList.push(
        {
          ...(mac_configured && { label: 'Mac', icon: 'sf-desktop-computer' }),
        },
        { ...(ios_configured && { label: 'iPhone', icon: 'sf-iphone' }) },
        { ...(ios_configured && { label: 'iPad', icon: 'sf-ipad-landscape' }) },
      );
    }

    // MSDC
    if (isMSDC && !isMsdcLoading) {
      const { ios_policy_id, macos_policy_id } = msdcIntegrationData?.data;
      badgeList.push(
        {
          ...(macos_policy_id && { label: 'Mac', icon: 'sf-desktop-computer' }),
        },
        { ...(ios_policy_id && { label: 'iPhone', icon: 'sf-iphone' }) },
        { ...(ios_policy_id && { label: 'iPad', icon: 'sf-ipad-landscape' }) },
      );
    }

    // ADCS
    if (isADCS && isConnected) {
      const serverCount = authorityList.length;
      const labelText = serverCount === 1 ? 'server' : 'servers';
      badgeList.push({ label: labelText, icon: 'server' });
    }

    return (
      <Flex pt2>
        {badgeList &&
          badgeList.map(
            (badge) =>
              badge.label && (
                <StyledChip
                  label={badge.label}
                  key={badge.label}
                  iconLeft={{ icon: badge.icon }}
                />
              ),
          )}
      </Flex>
    );
  }

  function onSyncUsersClick() {
    setIsSyncing(true);
    newIntegrationsService
      .syncUsers(id)
      .then(() => {
        toaster(`Successfully syncing users from ${name}.`);
      })
      .catch((e) => {
        let errMessage;
        let newIsSyncing = false;
        switch (e.response.status) {
          case 400:
            errMessage = `User sync is already running for ${name}`;
            newIsSyncing = true;
            break;
          case 404:
            errMessage = `Could not sync users from ${name}`;
            break;
          default:
            errMessage = 'Something went wrong';
        }
        toaster(errMessage);
        setIsSyncing(newIsSyncing);
      });
  }

  function renderMenuOptions(close) {
    if (isADCS) {
      const connector = connectorList[0]?.id;

      return (
        <>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setConnector(connector);
              openModal(INT_TYPES.adcs, 2, false);
              close();
            }}
            theme="action"
            kind="link"
            icon="eye"
          >
            View install instructions
          </Button>
          {hasSinglePendingConnector && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                setConnector(connector);
                openModal(INT_TYPES.adcs, 1, false);
                close();
              }}
              theme="action"
              kind="link"
              icon="arrow-down-to-line"
            >
              Redownload connector
            </Button>
          )}
        </>
      );
    }

    const handleReAuthentication = async (data) => {
      const { id: intId, type: intType } = data;

      try {
        const response = await newIntegrationsService.integrationsRotateToken(
          intId,
          intType,
        );
        window.location = response?.data?.redirect_url;
      } catch (e) {
        toaster('Something went wrong');
      }
    };

    let thirdButtonText = isSyncing ? 'Syncing users...' : 'Sync users';
    if (isScim) {
      thirdButtonText = 'Rotate token';
    }

    const isGoogleReauthenticationActive =
      isGSuite && isReAuthenticateFlagActive;

    const isAzureReauthenticationActive =
      isAzure && isAzureReauthenticationFlagActive;

    const hasReauthenticateButton = [
      isGoogleReauthenticationActive,
      isAzureReauthenticationActive,
    ].some(Boolean);

    return (
      <>
        {hasReauthenticateButton && (
          <Button
            onClick={() => {
              handleReAuthentication(integrationData);
              close();
            }}
            theme="action"
            kind="link"
            icon="circle-check"
          >
            Re-authenticate
          </Button>
        )}

        <Button
          onClick={() => {
            startViewingIntegration(integrationData);
            close();
          }}
          theme="action"
          kind="link"
          icon="eye"
        >
          View details
        </Button>
        <Button
          onClick={() => {
            startEditingIntegration(integrationData);
            close();
          }}
          icon="pencil"
          theme="action"
          kind="link"
        >
          Rename
        </Button>
        <Button
          icon="arrows-rotate"
          kind="link"
          theme="action"
          disabled={isSyncing}
          onClick={() => {
            if (isScim) {
              requestRotatingScimIntegration(integrationData);
            } else {
              onSyncUsersClick();
            }
            close();
          }}
        >
          {thirdButtonText}
        </Button>
        <Button
          icon="trash-can"
          kind="link"
          theme="error"
          onClick={() => {
            requestDeletingIntegration(integrationData);
            close();
          }}
        >
          Delete integration
        </Button>
      </>
    );
  }

  // For some integrations, we should show the "menu container" component which means this could be only the icon, or it may be the ellipsis button, which is the case for ADCS - security integration. We are hiding the ellipsis button for ADCS but showing the status icon.
  const showButtonforADCS = isADCS && hasSinglePendingConnector;
  const hasStatusMenuContainer = [isDirectoryInt, isSecurityInt].some(Boolean);
  const hasEllipsisButton = [isAzure, isScim, isGSuite, showButtonforADCS].some(
    Boolean,
  );

  return (
    <div
      className={setClass(
        'int-card_wrapper',
        'int-card_wrapper--overview',
        isInteractive && 'int-card_wrapper--interactive',
      )}
      onClick={(e) => {
        if (isInteractive && !e.defaultPrevented) {
          const withIntegrationId = [isServicenow].some(Boolean);
          const route = `${links.integrations.root}/${type}`;
          history.push(withIntegrationId ? `${route}/${id}` : route);
        }
      }}
    >
      <StyledHeaderImage src={img} alt="image icon" />

      <Flex flow="column" gap="sm" pl3 css={{ width: '100%' }}>
        <div className="int-overview-card_header">
          <h3>{getTitle()}</h3>
          {hasStatusMenuContainer && (
            <OptionsMenu render={renderMenuOptions}>
              {hasEllipsisButton && (
                <Button
                  onClick={(e) => e.preventDefault()}
                  icon="ellipsis"
                  kind="link"
                  className="int-overview-card_options-btn"
                />
              )}
              {getIcon()}
            </OptionsMenu>
          )}
        </div>

        {getSubTitle()}

        {getMainText()}

        {getBadges()}

        {isMsTeams && getNumEventNotificationsText() && (
          <div className="event-notifications-wrapper">
            <img
              className="int-overview-card_event-notifications"
              src={Bell}
              alt="event-notifications-bell"
            />
            <p className="b-mt1 b-txt-light event-notifications">
              {getNumEventNotificationsText()}
            </p>
          </div>
        )}
      </Flex>
    </div>
  );
}

OverviewIntegrationCard.propTypes = {
  integrationData: object.isRequired,
  requestDeletingIntegration: func.isRequired,
  startViewingIntegration: func.isRequired,
  startEditingIntegration: func.isRequired,
  requestRotatingScimIntegration: func.isRequired,
};

export default OverviewIntegrationCard;
