import type {
  SortColumnDirection,
  SortColumnName,
  ThreatClassification,
  ThreatPosture,
  ThreatStatus,
} from '../threat.types';

const THREAT_CLASSIFICATION: Readonly<{
  [Prop in ThreatClassification]: Extract<ThreatClassification, Prop>;
}> = {
  MALWARE: 'MALWARE',
  PUP: 'PUP',
  BENIGN: 'BENIGN',
  UNKNOWN: 'UNKNOWN',
};

// Subset of all threat classifications to enable as filters
const THREAT_CLASSIFICATION_FILTERS = Object.fromEntries(
  Object.entries(THREAT_CLASSIFICATION),
);

const THREAT_CLASSIFICATION_LABELS = {
  PUP: 'Potentially Unwanted Programs (PUP)',
  MALWARE: 'Malware',
  BENIGN: 'Benign',
  UNKNOWN: 'Unknown',
} as const;

const THREAT_CLASSIFICATION_ICON_MAP = {
  [THREAT_CLASSIFICATION.MALWARE]: 'bug',
  [THREAT_CLASSIFICATION.PUP]: 'layer-group',
  [THREAT_CLASSIFICATION.BENIGN]: 'circle-check',
  [THREAT_CLASSIFICATION.UNKNOWN]: 'circle-question',
} as const;

const THREAT_STATUS: Readonly<{
  [Prop in ThreatStatus]: Extract<ThreatStatus, Prop>;
}> = {
  QUARANTINED: 'QUARANTINED',
  NOT_QUARANTINED: 'NOT_QUARANTINED',
  RESOLVED: 'RESOLVED',
  RELEASED: 'RELEASED',
} as const;

const THREAT_POSTURE: Readonly<{
  [Prop in ThreatPosture]: Extract<ThreatPosture, Prop>;
}> = {
  PROTECT: 'PROTECT',
  DETECT: 'DETECT',
} as const;

const THREAT_CLASSIFICATION_LIST = Object.values(THREAT_CLASSIFICATION_FILTERS);
const THREAT_STATUS_LIST = Object.values(THREAT_STATUS);
const THREAT_POSTURE_LIST = Object.values(THREAT_POSTURE);
const THREAT_STATUS_JOINT_LIST = THREAT_STATUS_LIST.sort()
  .map((value) => value.toLowerCase())
  .join(',');

const THREAT_POSTURE_DISPLAY_NAME_MAP = {
  [THREAT_POSTURE.PROTECT]: 'Protect',
  [THREAT_POSTURE.DETECT]: 'Detect',
} as const;

const THREAT_STATUS_DISPLAY_NAME_MAP = {
  [THREAT_STATUS.QUARANTINED]: 'Quarantined',
  [THREAT_STATUS.NOT_QUARANTINED]: 'Not quarantined',
  [THREAT_STATUS.RESOLVED]: 'Resolved',
  [THREAT_STATUS.RELEASED]: 'Released',
} as const;

const THREAT_STATUS_COLOR_MAP = {
  [THREAT_STATUS.QUARANTINED]: 'blue',
  [THREAT_STATUS.NOT_QUARANTINED]: 'red',
  [THREAT_STATUS.RESOLVED]: 'green',
  [THREAT_STATUS.RELEASED]: 'neutral',
} as const;

const THREAT_STATUS_ICON_MAP = {
  [THREAT_STATUS.NOT_QUARANTINED]: 'shield-xmark',
  [THREAT_STATUS.QUARANTINED]: 'shield-check',
  [THREAT_STATUS.RESOLVED]: 'octagon-check',
  [THREAT_STATUS.RELEASED]: 'shield-minus',
} as const;

const THREAT_TABLE_SORTABLE_FIELDS: SortColumnName[] = [
  'threat_id',
  'threat_name',
  'latest_process_name',
  'classification',
  'device_name',
  'process_name',
  'detection_date',
  'status',
  'device_count',
] as const;

const THREAT_TABLE_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 75, 100] as const;

const THREAT_TABLE_DATE_RANGE_OPTIONS = [7, 30, 60, 90] as const;

const THREAT_SORT_ORDER: SortColumnDirection[] = [
  'none',
  'asc',
  'desc',
] as const;

const THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS = [
  {
    label: 'Last 24 hours',
    value: 'last_24_hours',
  },
  {
    label: 'Last 7 days',
    value: 'last_7_days',
  },
  {
    label: 'Last 30 days',
    value: 'last_30_days',
  },
  {
    label: 'Last 60 days',
    value: 'last_60_days',
  },
  {
    label: 'Last 90 days',
    value: 'last_90_days',
  },
  {
    label: 'All time',
    value: 'all_time',
  },
  {
    label: 'Custom date range',
    value: 'custom_date_range',
  },
] as const;

const THREAT_VIEW = {
  THREATS: 'by-threats',
  DEVICES: 'by-devices',
} as const;

const THREAT_VIEW_LIST = Object.values(THREAT_VIEW);

const VIRUS_TOTAL_URL = 'https://www.virustotal.com/gui/file/';

const constants = {
  THREAT_CLASSIFICATION,
  THREAT_STATUS,
  THREAT_CLASSIFICATION_LIST,
  THREAT_CLASSIFICATION_LABELS,
  THREAT_CLASSIFICATION_ICON_MAP,
  THREAT_STATUS_LIST,
  THREAT_STATUS_DISPLAY_NAME_MAP,
  THREAT_STATUS_COLOR_MAP,
  THREAT_STATUS_ICON_MAP,
  THREAT_TABLE_SORTABLE_FIELDS,
  THREAT_TABLE_ROWS_PER_PAGE_OPTIONS,
  THREAT_TABLE_DATE_RANGE_OPTIONS,
  THREAT_STATUS_JOINT_LIST,
  THREAT_SORT_ORDER,
  THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS,
  THREAT_VIEW,
  THREAT_VIEW_LIST,
  THREAT_POSTURE,
  THREAT_POSTURE_LIST,
  THREAT_POSTURE_DISPLAY_NAME_MAP,
  VIRUS_TOTAL_URL,
};

export default constants;
