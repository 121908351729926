import { styled } from '@kandji-inc/nectar-ui';
import type {
  AllowedSelectValue,
  CSS,
  SelectOptionMemberList,
  SelectSize,
} from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ViewMultiSelect from '../../../integrator/layouts/partials/view-multi-select';
import useGetUserGroupList from '../../hooks/use-get-user-group-list';

interface Props {
  field: string;
  defaultOptions: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>;
  size?: SelectSize;
  css?: CSS;
}

const StyledTypeaheadContainer = styled('div', {
  paddingLeft: '$5',
});

const SelectUserGroup = ({
  field,
  defaultOptions = [],
  size = 'md',
  css = {},
}: Props) => {
  const { watch, getValues } = useFormContext();
  const [searchOptions, setUserGroupOptions] = useState<
    SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>
  >([]);
  const [searchTerm, setSearchTerm] = useState('');

  watch([field]);
  const formInputs = getValues();

  useGetUserGroupList({ setUserGroupOptions, searchTerm });

  return (
    <StyledTypeaheadContainer css={css}>
      {formInputs[field] && (
        <ViewMultiSelect
          size={size}
          label={
            field === 'macOsDevices'
              ? 'macOS user group '
              : 'iOS/iPadOS user group'
          }
          defaultValue={defaultOptions}
          options={searchOptions}
          handleChange={setSearchTerm}
          debounceTime={500}
          name={`${field}_groups`} // macOsDevices_groups or iosDevices_groups
          validations={{ required: 'Please select a user group' }}
        />
      )}
    </StyledTypeaheadContainer>
  );
};

export default SelectUserGroup;
