/* istanbul ignore file */
import { Button } from '@kandji-inc/nectar-ui';
import { apiFetchUser } from 'app/api/account';
import { getMillisecondsInactive } from 'app/api/base';
import UniversalAlert from 'app/components/interface/banners/UniversalAlert';
import { getLogoutInactivityTime } from 'app/components/sidebar/LogoutMonitor';
import { getLogoutWarningDuration } from 'app/components/sidebar/LogoutWarningMonitor';
import { InterfaceContext } from 'contexts/interface';
import React, { useContext, useState } from 'react';

export const LogoutWarningBanner = () => {
  const { setBanner } = useContext(InterfaceContext);
  const [warningBanner, setWarningBanner] = useState(null);

  const drawBanner = () => {
    const logoutAfter = getLogoutInactivityTime();
    const logoutWarningDuration = getLogoutWarningDuration();
    const timeTillLogout = logoutAfter - getMillisecondsInactive();
    const timeTillLogoutWarning = timeTillLogout - logoutWarningDuration;
    let warningBannerTimeout;
    let durationStr;

    if (timeTillLogout > 60 * 1000) {
      const minutes = Math.ceil(timeTillLogout / (60 * 1000));
      durationStr = `in ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (timeTillLogout <= 0) {
      durationStr = '';
    } else {
      const seconds = Math.round(timeTillLogout / 1000);
      durationStr = `in ${seconds} second${seconds > 1 ? 's' : ''}`;
    }

    setWarningBanner(
      <UniversalAlert
        type="info"
        text={
          <>
            <span style={{ marginRight: '1em' }}>
              You will be automatically logged out {durationStr} due to
              inactivity.
            </span>
            <Button
              onClick={() => {
                clearTimeout(warningBannerTimeout);
                setBanner(null);
                apiFetchUser();
              }}
            >
              Stay Logged In
            </Button>
          </>
        }
      />,
    );

    if (timeTillLogoutWarning <= 0) {
      warningBannerTimeout = setTimeout(drawBanner, 1000);
    }
  };

  if (warningBanner === null) {
    drawBanner();
  }

  return warningBanner;
};

export const useLogoutWarningBanner = () => {
  const { setBanner } = useContext(InterfaceContext);

  const show = () => {
    setBanner(<LogoutWarningBanner />);
  };

  return {
    show,
    hide: () => setBanner(null),
  };
};
