/* istanbul ignore file */
export const formatDate = (date: string) => {
  // Use a regular expression to extract the date part (YYYY-MM-DD)
  const datePart = date.match(/^\d{4}-\d{2}-\d{2}/)?.[0];

  if (!datePart) {
    throw new Error('Invalid date format');
  }

  const [year, month, day] = datePart.split('-').map(Number);
  const dateObj = new Date(year, month - 1, day); // Months are zero-based
  const formattedDay = String(dateObj.getDate()).padStart(2, '0');
  const formattedMonth = String(dateObj.getMonth() + 1).padStart(2, '0');
  const formattedYear = dateObj.getFullYear();

  return `${formattedMonth}/${formattedDay}/${formattedYear}`;
};

const getTextWidth = (text: string, element: HTMLElement = document.body) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Get the computed style of the element and set it on the canvas context
  const style = window.getComputedStyle(element);
  context.font = `${style.fontSize} ${style.fontFamily}`;

  return context.measureText(text).width;
};

export const displayAndTruncateList = (
  strings: string[],
  maxWidth: number = 530,
  separator = ', ',
  element = document.body,
) => {
  const displayed = [];
  let hidden = [];
  let totalWidth = 0;

  for (let i = 0; i < strings.length; i++) {
    const string = strings[i];
    const stringWidth = getTextWidth(string, element);
    const separatorWidth = i > 0 ? getTextWidth(separator, element) : 0;

    if (totalWidth + stringWidth + separatorWidth > maxWidth) {
      hidden = strings.slice(i); // All remaining strings are hidden
      break;
    }

    displayed.push(string);
    totalWidth += stringWidth + separatorWidth;
  }

  return { displayed, hidden };
};
