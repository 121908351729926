import { useFieldArray, useForm } from '@kandji-inc/nectar-form';
import { Box, Button, Flex, Text } from '@kandji-inc/nectar-ui';
import { AssignmentMapOSVersionRule } from 'features/rules-modal/components/AssignmentMapOSVersionRule';
import type { FacetMap } from 'features/rules-modal/types';
import React, { useEffect } from 'react';

export const AssignmentMapOSVersion = ({
  arrayName,
  facetMap,
}: {
  arrayName: string;
  facetMap: FacetMap;
}) => {
  const form = useForm(false);
  const maxOSVersionRules = 10;
  const osVersionArray = useFieldArray(arrayName);

  useEffect(() => {
    form.addFieldArray(osVersionArray);
    if (osVersionArray.getLength() === 0) {
      osVersionArray.addFieldIndex();
    }
    return () => {
      form.removeFieldArray(osVersionArray);
    };
  }, [form, osVersionArray]);

  return (
    <Box wFull css={{ borderLeft: '1px solid $neutral20' }}>
      <Flex
        flow="column"
        gap="sm"
        css={{
          marginLeft: '$3',
          padding: '$2',
          border: '1px solid $neutral20',
          borderRadius: '$1',
        }}
      >
        {osVersionArray.getFieldIndexNames().map((fieldIndexName, index) => (
          <React.Fragment key={fieldIndexName}>
            {index !== 0 && <Text css={{ marginLeft: '$2' }}>or</Text>}
            <AssignmentMapOSVersionRule
              key={fieldIndexName}
              inputFieldName={`${fieldIndexName}.input`}
              operatorFieldName={`${fieldIndexName}.operator`}
              valueFieldName={fieldIndexName}
              facetMap={facetMap}
              canRemoveRule={true}
              removeRule={() => {
                osVersionArray.removeFieldIndex(fieldIndexName);
                /* istanbul ignore next */
                if (index === 0 && osVersionArray.getLength() < 1) {
                  osVersionArray.addFieldIndex();
                }
              }}
            />
          </React.Fragment>
        ))}
        <Flex justifyContent="end">
          <Button
            icon={{ name: 'fa-plus-minus-small', position: 'left' }}
            onClick={() => osVersionArray.addFieldIndex()}
            disabled={osVersionArray.getLength() >= maxOSVersionRules}
          >
            or
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
