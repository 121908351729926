// istanbul ignore file
import { useQuery } from '@tanstack/react-query';
import { prismService } from '../../prism/data-service/prism-service';
import type { PrismViewCountRequestSchema } from '../../prism/types/prism.types';

export const useDeviceViewsCountQuery = ({
  params,
  filterBody,
}: {
  params: PrismViewCountRequestSchema;
  filterBody?: Record<string, unknown>;
}) =>
  useQuery({
    enabled:
      !!params.view_id || !!(params.columns && params.columns.length > 0),
    queryKey: ['prism-view-count', params, filterBody],
    queryFn: async () => prismService.getPrismViewCount(params, filterBody),
  });
