import React, { useEffect, useState } from 'react';

import { NFMultiSelect } from '@kandji-inc/nectar-form';

import useBuilder from 'src/features/rules/builder/use-builder';
import { getValueClient } from 'src/features/rules/use-rule-service';

type MultiSelectApiProps = {
  apiData: any;
  validation: any;
  name: string;
};

export const MultiSelectApi = (props: MultiSelectApiProps) => {
  const { apiData, validation, name } = props;

  const { type, endpoint, search_param: searchParam } = apiData;
  const client = getValueClient(type, endpoint);

  const [apiOptions, setApiOptions] = useState([]);
  const { countOfUserDirectoryIntegrations } = useBuilder();

  const defaultGetLabel = (option) => option.name;

  const getLabel = /* istanbul ignore next */ (option) => {
    if (countOfUserDirectoryIntegrations > 1) {
      return `${option.integration_name} - ${option.name}`;
    }
    return option.name;
  };

  useEffect(() => {
    client.get({}).then((res) =>
      setApiOptions(
        res.data.results.map((option) => ({
          label:
            /* istanbul ignore next */
            apiData.type === 'integrations'
              ? getLabel(option)
              : defaultGetLabel(option),
          value: option.id,
        })),
      ),
    );
  }, []);

  return (
    <NFMultiSelect
      componentCss={{
        root: {
          width: '100%',
        },
        trigger: {
          width: '100%',
        },
        menu: {
          width: '100%',
        },
      }}
      name={name}
      // disabled={disabled}
      options={apiOptions}
      selectAllLabel="All"
      validation={validation}
    />
  );
};
