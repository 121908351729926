// istanbul ignore file
import { createColumnHelper } from '@tanstack/react-table';
import type { z } from 'zod';
import {
  BytesCell,
  DateCell,
  HighlightedTextCell,
  NaCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getBlueprintCell,
  getDeviceCell,
  getDeviceUserCell,
  getSpecificDeviceFamilyCell,
} from './column-utils';

const GB = 1000000000;

export const getColumnsForDeviceView = ({
  schema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const deviceInfoColumnHelper = createColumnHelper<z.infer<typeof schema>>();

  return {
    columnDefs: [
      deviceInfoColumnHelper.accessor((row) => row.device__name, {
        id: 'device__name',
        header: 'Device Name',
        enableHiding: false,
        cell: (info) => getDeviceCell(info, 'device_information'),
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.model_name'],
        {
          id: 'device_information.model_name',
          header: 'Model Name',
          meta: {
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.display_os_version'],
        {
          id: 'device_information.display_os_version',
          header: 'OS Version',
          meta: {
            filterType: 'version',
            filterIcon: 'hashtag',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor((row) => row.serial_number, {
        id: 'serial_number',
        cell: HighlightedTextCell,
        header: 'Serial Number',
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor((row) => row.device__user_name, {
        id: 'device__user_name',
        cell: getDeviceUserCell,
        header: 'Device User',
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor((row) => row.blueprint_name, {
        id: 'blueprint_name',
        cell: (info) => getBlueprintCell(info),
        header: 'Blueprint',
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor((row) => row.asset_tag, {
        id: 'asset_tag',
        cell: HighlightedTextCell,
        header: 'Asset Tag',
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_enrollment_date'],
        {
          id: 'device_information.last_enrollment_date',
          cell: DateCell,
          header: 'Last Enrollment Date',
          meta: {
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_collected_at'],
        {
          id: 'device_information.last_collected_at',
          cell: DateCell,
          header: 'Last Collected',
          meta: {
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_checkin'],
        {
          id: 'device_information.last_checkin',
          cell: DateCell,
          header: 'Last Check-In',
          meta: {
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_checkin_agent'],
        {
          id: 'device_information.last_checkin_agent',
          cell: DateCell,
          header: 'Last Check-In (Agent)',
          meta: {
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_checkin_mdm'],
        {
          id: 'device_information.last_checkin_mdm',
          cell: DateCell,
          header: 'Last Check-In (MDM)',
          meta: {
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.device_id'],
        {
          id: 'device_information.device_id',
          header: 'Device ID',
          meta: {
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor((row) => row.device__user_email, {
        id: 'device__user_email',
        cell: HighlightedTextCell,
        header: 'User Email',
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor(
        (row) =>
          Number.isNaN(row['device_information.device_capacity'])
            ? null
            : row['device_information.device_capacity'] * GB,
        {
          id: 'device_information.device_capacity',
          cell: BytesCell,
          header: 'Device Capacity',
          meta: {
            filterType: 'number',
            filterIcon: 'hashtag',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.host_name'],
        {
          id: 'device_information.host_name',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
          header: 'Hostname',
          meta: {
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.apple_silicon'],
        {
          id: 'device_information.apple_silicon',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
          header: 'Apple Silicon',
          meta: {
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.local_hostname'],
        {
          id: 'device_information.local_hostname',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
          header: 'Local Hostname',
          meta: {
            filterIcon: 'text-size',
            filterType: 'string',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.model'],
        {
          id: 'device_information.model',
          header: 'Model',
          meta: {
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor((row) => row.model_id, {
        id: 'model_id',
        header: 'Model Identifier',
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.os_build'],
        {
          id: 'device_information.os_build',
          header: 'OS Build',
          meta: {
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.marketing_name'],
        {
          id: 'device_information.marketing_name',
          header: 'OS Name',
          meta: {
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.supplemental_build_version'],
        {
          id: 'device_information.supplemental_build_version',
          header: 'Supplemental Build Version',
          meta: {
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.supplemental_os_version_extra'],
        {
          id: 'device_information.supplemental_os_version_extra',
          header: 'Supplemental OS Version Extra',
          meta: {
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.os_version'],
        {
          id: 'device_information.os_version',
          header: 'Simple OS Version',
          meta: {
            filterType: 'version',
            filterIcon: 'hashtag',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.first_enrollment_date'],
        {
          id: 'device_information.first_enrollment_date',
          cell: DateCell,
          header: 'First Enrollment Date',
          meta: {
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.agent_version'],
        {
          id: 'device_information.agent_version',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
          header: 'Agent Version',
          meta: {
            filterType: 'version',
            filterIcon: 'hashtag',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.mdm_enabled'],
        {
          id: 'device_information.mdm_enabled',
          cell: YesNoCell,
          header: 'MDM Enabled',
          meta: {
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.agent_installed'],
        {
          id: 'device_information.agent_installed',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
          header: 'Agent Installed',
          meta: {
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.shared_ipad'],
        {
          id: 'device_information.shared_ipad',
          cell: (info) =>
            getSpecificDeviceFamilyCell(info, ['iPad'], 'boolean'),
          header: 'Shared iPad',
          meta: {
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['iPad'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.cellular_technology'],
        {
          id: 'device_information.cellular_technology',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone']),
          header: 'Cellular Technology',
          meta: {
            filterType: 'enum',
            filterIcon: 'list-check',
            filterOptions: [
              { value: 'None', label: 'None' },
              { value: 'GSM', label: 'GSM' },
              { value: 'CDMA', label: 'CDMA' },
              { value: 'GSM and CDMA', label: 'Both GSM and CDMA' },
            ],
            deviceFamily: ['iPad', 'iPhone'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.data_roaming'],
        {
          id: 'device_information.data_roaming',
          cell: (info) => {
            if (
              info.row.original.device__family === 'iPad' &&
              info.row.original['device_information.cellular_technology'] ===
                'None'
            ) {
              return NaCell();
            }
            return getSpecificDeviceFamilyCell(
              info,
              ['iPad', 'iPhone'],
              'boolean',
            );
          },
          header: 'Data Roaming',
          meta: {
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['iPad', 'iPhone'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.hotspot'],
        {
          id: 'device_information.hotspot',
          cell: (info) => {
            if (
              info.row.original.device__family === 'iPad' &&
              info.row.original['device_information.cellular_technology'] ===
                'None'
            ) {
              return NaCell();
            }
            return getSpecificDeviceFamilyCell(
              info,
              ['iPad', 'iPhone'],
              'boolean',
            );
          },
          header: 'Personal Hotspot',
          meta: {
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['iPad', 'iPhone'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_changed_at'],
        {
          id: 'device_information.last_changed_at',
          cell: DateCell,
          header: 'Last Changed',
          meta: {
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      /* this columns are always hidden but needs to be in column defs to enable */
      /* filtering for export purposes */
      deviceInfoColumnHelper.accessor((row) => row.device__user_id, {
        id: 'device__user_id',
        header: 'Device User ID',
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),
      deviceInfoColumnHelper.accessor((row) => row.blueprint_id, {
        id: 'blueprint_id',
        header: 'Bluepint ID',
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor((row) => row.device__family, {
        id: 'device__family',
        header: 'Device Family',
        meta: {
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),
    ],
    pinnedColumns: ['device__name'],
    alwaysHiddenColumns: ['device__user_id', 'device__family', 'blueprint_id'],
  };
};
