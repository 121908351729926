import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { constants } from '../../../common';
import type {
  PageSize,
  SortColumnDirection,
  SortColumnName,
  ThreatClassification,
  ThreatPosture,
  ThreatStatus,
} from '../../../threat.types';

type DefaultParams = {
  STATUS: ThreatStatus[] | undefined;
  DETECTION_DATE: DetectionDateFilterFields;
  MALWARE_POSTURE: ThreatPosture[] | undefined;
  PUP_POSTURE: ThreatPosture[] | undefined;
  CLASSIFICATION: ThreatClassification | undefined;
  PAGE: number;
  PAGE_SIZE: PageSize;
  SORT_BY: SortColumnName | undefined;
  SORT_ORDER: SortColumnDirection | undefined;
  SEARCH_QUERY: string | undefined;
};

const defaultParams: DefaultParams = {
  STATUS: undefined,
  DETECTION_DATE: {
    value: constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS[0].value,
  },
  CLASSIFICATION: undefined,
  PAGE_SIZE: 10,
  PAGE: 1,
  SORT_BY: undefined,
  SORT_ORDER: undefined,
  SEARCH_QUERY: undefined,
  MALWARE_POSTURE: undefined,
  PUP_POSTURE: undefined,
};

export default defaultParams;
