// istanbul ignore file
import {
  Box,
  Button,
  Flex,
  Heading,
  Loader,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import type { Column } from '@tanstack/react-table';
import { isEqual } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router';

import type { SortState } from 'src/components';
import { DataTable } from 'src/components';
import { Pagination } from 'src/components/ui';
import { InterfaceContext } from 'src/contexts/interface';
import useAdjustSidebarChatBubble from 'src/features/integrations/hooks/use-adjust-sidebar-chat-bubble';
import {
  ColumnEditorButton,
  ColumnEditorWindow,
} from 'src/features/visibility/shared/components/column-editor';
import { useDebouncedState } from 'src/hooks/useDebouncedState';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import PrismSavedViewsActionMenu from '../prism/components/PrismTable/PrismSavedViewsActionMenu';
import PrismViewCreateDropdown from '../prism/components/PrismTable/components/PrismViewCreateDropdown';
import PrismViewRenameModal from '../prism/components/PrismTable/components/PrismViewRenameModal';
import PrismViewSaveDropdown from '../prism/components/PrismTable/components/PrismViewSaveDropdown';
import { validateViewName } from '../prism/components/PrismTable/utils/tableUtils';
import { usePagination, useSyncUrlWithTableState } from '../prism/hooks';
import { useViewDataQuery } from '../prism/hooks/use-view-data-query';
import {
  areColumnsInOrder,
  getColumnId,
  hasColumnVisibilityChanged,
} from '../shared/components/column-editor/utils';
import { DeviceBulkActions, DevicesExportButton, ViewsNav } from './components';
import PrismViewFilters from './components/PrismViewFilters';
import { usePrismViewsContext } from './contexts/PrismViewsContext';
import {
  useCreateDeviceView,
  useDeleteDeviceView,
  useDeviceViewsCountQuery,
  useDeviceViewsQuery,
  useUpdateDeviceView,
} from './hooks';

const DEFAULT_VIEWS = [
  {
    id: '25535baf-31e4-427c-9e00-21cf970b5b42',
    name: 'All Devices',
  },
  {
    id: 'abf605de-832f-4e85-878e-714cbc52e4f2',
    name: 'Offline Devices',
  },
  {
    id: '933c443b-0126-4ee8-b914-96bfc1f5e6fd',
    name: 'Devices With Alerts',
  },
] as const;

const isDefaultView = (viewId: string) => {
  return DEFAULT_VIEWS.some((view) => view.id === viewId);
};

export const removeEmptyValues = (obj: Record<string, unknown>) =>
  Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v != null && v !== ''),
  );

const PrismViews = () => {
  useWhiteBackground();
  useAdjustSidebarChatBubble();

  const history = useHistory();

  const { columns } = usePrismViewsContext();

  const {
    filter,
    sortBy,
    viewId,
    setFilter,
    resetFilter,
    replaceFilter,
    setViewId,
    removeViewId,
    setSortBy,
  } = useSyncUrlWithTableState('/devices');

  const {
    paginationState: { pageIndex, pageSize },
    setPagination,
    resetPagination,
  } = usePagination();

  const handleSort = React.useCallback(
    ({ col, direction }: SortState) => {
      if (direction === 'none') {
        setSortBy('');
        return;
      }
      const prefix = direction === 'asc' ? '' : '-';
      setSortBy(`${prefix}${col}`);
    },
    [setSortBy],
  );

  const sort = React.useMemo(() => {
    if (sortBy == null || sortBy === '') {
      return { col: '', direction: 'none' };
    }
    const [direction, col] = sortBy.startsWith('-')
      ? ['desc', sortBy.slice(1)]
      : ['asc', sortBy];
    return { col, direction };
  }, [sortBy]) as SortState;

  const [columnSizes, setColumnSizes] = React.useState<Record<string, number>>(
    {},
  );
  const [columnVisibility, setColumnVisibility] = React.useState<
    Record<string, boolean>
  >({});

  const [columnOrder, setColumnOrder] = React.useState<string[]>([]);
  const [selection, setSelection] = React.useState<string[]>([]);
  const [viewName, setViewName] = React.useState('');
  const [viewNameError, setViewNameError] = React.useState('');
  const [renameViewOpen, setRenameViewOpen] = React.useState(false);
  const [debouncedSearch, setSearch, search] = useDebouncedState<string>('');
  const [isColumnEditorOpen, setIsColumnEditorOpen] = React.useState(false);
  const handleToggleColumnEditor = () => {
    setIsColumnEditorOpen(!isColumnEditorOpen);
  };
  const { data: savedViews, isLoading: savedViewsLoading } =
    useDeviceViewsQuery();

  const view = React.useMemo(
    () =>
      savedViews?.find((v) =>
        viewId != null ? v.id === viewId : DEFAULT_VIEWS[0].id === v.id,
      ),
    [savedViews, viewId],
  );

  const resetTableViewState = React.useCallback(
    (view) => {
      if (!view) {
        return;
      }
      const columnSizes = view.columns?.reduce((sizes, col) => {
        if (col.size != null && col.name != null) {
          sizes[getColumnId(col)] = col.size;
        }
        return sizes;
      }, {}) as Record<string, number>;
      setColumnSizes(columnSizes);

      const { sort_by, filters } = view;
      setSortBy(sort_by || '');

      if (filters) {
        const newFilter = Object.entries(filters).reduce(
          (acc, [key, value]) => {
            acc[key || ''] = value;
            return acc;
          },
          {},
        );
        replaceFilter(newFilter);
      }

      setColumnOrder(view.columns?.map((col) => getColumnId(col)) || []);

      setColumnVisibility(
        view.columns?.reduce((visibility, col) => {
          visibility[getColumnId(col)] = col.visible;
          return visibility;
        }, {}),
      );
    },
    [replaceFilter, setSortBy],
  );

  React.useEffect(() => {
    if (!['/devices/', '/devices', '/'].includes(window.location.pathname)) {
      return;
    }
    resetPagination();
    resetTableViewState(view);
    setSearch('');
    setSelection([]);
    if (view && !viewId) {
      setViewId(view.id);
    }
  }, [view, viewId]);

  React.useEffect(() => {
    if (window.location.pathname !== '/devices') {
      return;
    }
    resetPagination();
  }, [debouncedSearch]);

  const { data, isPending, refetch } = useViewDataQuery({
    params: {
      sort_by: sortBy,
      limit: pageSize,
      offset: pageIndex * pageSize,
    },
    filterBody: removeEmptyValues(filter),
    columns: (columns?.columnDefs || []).map((col) => {
      let [category, name] = col.id.split('.');
      if (name === undefined) {
        name = category;
        category = '';
      }
      return { name, category } as { name: string; category: string };
    }),
    search: debouncedSearch,
  });

  const { toast } = useToast();
  const { bannerTopOffset, sidebarOpened } = React.useContext(InterfaceContext);
  const toastOffset = React.useMemo(
    () => (sidebarOpened ? '276px' : '98px'),
    [sidebarOpened],
  );

  const getOrderedColumns = React.useCallback(
    () =>
      columnOrder.map((colId) => {
        let [category, name] = colId.split('.');
        if (name === undefined) {
          name = category;
          category = '';
        }
        return {
          category,
          name,
          visible:
            columnVisibility[colId] !== false &&
            !columns.alwaysHiddenColumns?.includes(colId),
          size: columnSizes[colId],
        };
      }),
    [columnOrder, columns.alwaysHiddenColumns, columnSizes, columnVisibility],
  );

  const { data: countData, refetch: refetchCount } = useDeviceViewsCountQuery({
    params: {
      columns: getOrderedColumns(),
      search: debouncedSearch,
    },
    filterBody: removeEmptyValues(filter),
  });

  const createDeviceView = useCreateDeviceView({
    onSuccess: (newView) => {
      toast({
        title: 'Success!',
        content: `View ${newView.name} is now available as a saved view.`,
        variant: 'success',
        style: {
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
      setViewName('');
      if (newView.id) {
        setViewId(newView.id);
      }
    },
  });

  const handleViewCreate = React.useCallback(
    (viewName) => {
      const existingNames = savedViews?.map((view) => view.name || '') || [];
      const viewNameError = validateViewName(viewName, existingNames);
      setViewNameError(viewNameError);
      if (viewNameError !== '') {
        return;
      }
      createDeviceView.mutate({
        name: viewName,
        sort_by: sortBy,
        columns: getOrderedColumns(),
        filters: filter,
      });
    },
    [savedViews, createDeviceView, filter, sortBy, getOrderedColumns],
  );

  const updatePrismView = useUpdateDeviceView({
    onSuccess: (view) => {
      toast({
        title: 'Success!',
        content: `${view.name} has been updated.`,
        variant: 'success',
        style: {
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
      setViewName('');
      if (view.id) {
        setViewId(view.id);
      }
    },
  });

  const handleViewUpdate = React.useCallback(() => {
    if (!view) {
      return;
    }
    updatePrismView.mutate({
      id: view.id,
      name: viewName || view.name,
      sort_by: sortBy,
      columns: getOrderedColumns(),
      filters: filter,
    });
  }, [view, viewName, getOrderedColumns, updatePrismView, filter, sortBy]);

  const renamePrismView = useUpdateDeviceView({
    onSuccess: (view) => {
      toast({
        title: 'Success!',
        content: 'Display name has been updated.',
        variant: 'success',
        style: {
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
      setViewName('');
      setRenameViewOpen(false);
      if (view.id) {
        setViewId(view.id);
      }
    },
  });

  const handleViewRename = React.useCallback(
    (newName: string) => {
      if (!view?.id) {
        return;
      }
      const existingNames = savedViews?.map((view) => view.name || '') || [];
      const viewNameError = validateViewName(newName, existingNames);
      setViewNameError(viewNameError);
      if (viewNameError !== '') {
        return;
      }
      renamePrismView.mutate({
        id: view?.id,
        name: newName,
        sort_by: view?.sort_by,
        columns: view?.columns,
        filters: view?.filters,
      });
    },
    [view, renamePrismView, savedViews],
  );

  const deleteSavedView = useDeleteDeviceView({
    view,
    onSuccess: () => {
      resetFilter();
      removeViewId();
      history.push(history.location.pathname);
      toast({
        title: `${view?.name} has been removed`,
        content: 'The saved view will no longer be available.',
        variant: 'default',
        style: {
          // istanbul ignore next
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
    },
  });

  // istanbul ignore next
  const bulkActions = React.useMemo(() => {
    if (selection.length === 0) {
      return undefined;
    }

    return (
      <DeviceBulkActions
        devices={selection}
        onClear={() => setSelection([])}
        onChangedBlueprint={() => {
          setSelection([]);
          refetch();
        }}
        onDeleted={() => {
          setSelection([]);
          refetch();
          refetchCount();
        }}
      />
    );
  }, [selection, refetch, refetchCount]);

  const viewChanged = React.useMemo(() => {
    if (!view) {
      return false;
    }
    if (sortBy !== '' && view.sort_by !== sortBy) {
      return true;
    }
    if (isEqual(view.filters, filter) === false) {
      return true;
    }
    if (!areColumnsInOrder(view.columns || [], columnOrder)) {
      return true;
    }
    if (hasColumnVisibilityChanged(view.columns || [], columnVisibility)) {
      return true;
    }
    return (
      view.columns?.find((col) => {
        const colId = col.category ? `${col.category}.${col.name}` : col.name;
        return columnSizes[colId] != null && col.size !== columnSizes[colId];
      }) != null
    );
  }, [view, sortBy, filter, columnSizes, columnOrder, columnVisibility]);

  const viewActions = React.useMemo(() => {
    if (!view || (!viewChanged && isDefaultView(view.id))) {
      return null;
    }
    return (
      <Flex
        flow="row"
        flex="none"
        gap="sm"
        css={{ ml: 'auto', alignSelf: 'baseline' }}
      >
        {viewChanged && (
          <Button
            variant="subtle"
            compact
            onClick={() => resetTableViewState(view)}
          >
            Reset table
          </Button>
        )}
        {viewChanged && isDefaultView(view.id) && (
          <PrismViewCreateDropdown
            viewName={viewName}
            viewNameError={viewNameError}
            handleViewCreate={handleViewCreate}
            setViewNameError={setViewNameError}
          />
        )}
        {!isDefaultView(view.id) && (
          <PrismViewSaveDropdown
            viewName={viewName}
            viewNameError={viewNameError}
            handleViewCreate={handleViewCreate}
            handleViewUpdate={handleViewUpdate}
            setViewNameError={setViewNameError}
          />
        )}
      </Flex>
    );
  }, [
    view,
    viewChanged,
    viewName,
    viewNameError,
    handleViewCreate,
    handleViewUpdate,
    resetTableViewState,
  ]);

  const heightModifier = React.useMemo(
    () => `${128 + bannerTopOffset}px`,
    [bannerTopOffset],
  );

  const dataTable = React.useMemo(() => {
    const cols = columnOrder.reduce(
      (memo, colId) => {
        const columnDef = columns.columnDefs?.find((c) => c.id === colId);
        if (
          columnDef &&
          columnVisibility[colId] !== false &&
          !columns.alwaysHiddenColumns?.includes(colId)
        ) {
          memo.push(columnDef);
        }
        return memo;
      },
      [] as Column<{}, unknown>[],
    );

    const getColumnMenu = (columnDef: Column<any, unknown>) => {
      if (!columnDef.id) {
        return [];
      }
      const columnPinned = columns.pinnedColumns?.includes(columnDef.id);
      const visibleOrder = columnOrder.filter(
        (columnId) => columnVisibility[columnId] !== false,
      );
      const visibleIndex = visibleOrder.indexOf(columnDef.id);
      const orderIndex = columnOrder.indexOf(columnDef.id);
      return [
        {
          icon: 'fa-arrow-left-to-line-control',
          label: 'Move column 1 to the left',
          disabled:
            columnPinned ||
            visibleIndex === (columns.pinnedColumns?.length || 1),
          onClick: () => {
            const prevId = visibleOrder[visibleIndex - 1];
            if (!prevId) {
              return;
            }
            const prevIndex = columnOrder.indexOf(prevId);
            const newOrder = [...columnOrder];
            newOrder[prevIndex] = columnDef.id;
            newOrder[orderIndex] = prevId;
            setColumnOrder(newOrder);
          },
        },
        {
          icon: 'fa-arrow-right-to-line-control',
          label: 'Move column 1 to the right',
          disabled: columnPinned || visibleIndex === visibleOrder.length - 1,
          onClick: () => {
            const nextId = visibleOrder[visibleIndex + 1];
            if (!nextId) {
              return;
            }
            const nextIndex = columnOrder.indexOf(nextId);
            const newOrder = [...columnOrder];
            newOrder[nextIndex] = columnDef.id;
            newOrder[orderIndex] = nextId;
            setColumnOrder(newOrder);
          },
        },
        {
          icon: 'eye-slash',
          label: 'Hide column',
          disabled:
            columns.pinnedColumns?.includes(columnDef.id) ||
            columnVisibility[columnDef.id] === false,
          onClick: () => {
            // microtask to prevent column removal before menu closes
            queueMicrotask(() =>
              setColumnVisibility({
                ...columnVisibility,
                [columnDef.id]: false,
              }),
            );
          },
        },
        {
          icon: 'filter',
          label: `Filter table by ${columnDef.header}`,
          disabled: filter && filter[columnDef.id] !== undefined,
          onClick: () => {
            setFilter({ ...filter, [columnDef.id]: '' });
          },
        },
      ];
    };

    return (
      <DataTable
        data={data?.data || []}
        columnSizing={{
          sizes: columnSizes,
          setSizes: setColumnSizes,
        }}
        pinnedColumns={columns.pinnedColumns || []}
        columns={cols || []}
        getColumnMenu={getColumnMenu}
        offsets={{ content: 304 }}
        selectionModel={{ selection, setSelection, maxCount: 300 }}
        rowId="device_information.device_id"
        searchTerm={debouncedSearch}
        sort={{ sortState: sort, setSortState: handleSort }}
        css={{ flex: 1, borderBottom: 'none', maxHeight: '100%' }}
      />
    );
  }, [
    data,
    columns,
    columnSizes,
    columnVisibility,
    columnOrder,
    debouncedSearch,
    filter,
    handleSort,
    selection,
    setFilter,
    sort,
  ]);

  return (
    <Flex
      gap="xl"
      flex="1"
      css={{
        pt: '$5',
        height: `calc(100vh - ${heightModifier})`,
        overflow: 'hidden',
        paddingLeft: '16px',
        paddingRight: '24px',
      }}
    >
      {savedViewsLoading && (
        <Flex flex="1" alignItems="center" justifyContent="center">
          <Loader size="lg" />
        </Flex>
      )}
      {!savedViewsLoading && (
        <>
          <ViewsNav
            views={savedViews}
            viewId={viewId}
            collapsed={isColumnEditorOpen}
            onSelectView={(view) => {
              setViewId(view.id);
            }}
          />
          <Flex flow="column" css={{ minWidth: '550px' }} flex="1">
            <Flex
              flow="row"
              alignItems="center"
              flex="none"
              css={{ height: '36px' }}
            >
              <Heading size="3" css={{ fontWeight: '$medium' }}>
                {view?.id ? view.name : 'All devices'}
              </Heading>

              <Flex justifyContent="end" flex="1">
                <ColumnEditorButton
                  onToggleColumnEditor={handleToggleColumnEditor}
                />
                <DevicesExportButton
                  viewName={view?.name}
                  columns={columns.columnDefs?.map((col) => {
                    let [category, name] = col.id.split('.');
                    if (name === undefined) {
                      name = category;
                      category = '';
                    }
                    return {
                      category: category as string,
                      name: name as string,
                      visible: true,
                      size: columnSizes[col.id],
                    };
                  })}
                  filters={view?.filters || filter}
                  blueprintIds={filter.blueprint_ids || []}
                  deviceFamilies={filter.device_families || []}
                />
                {!isDefaultView(view?.id) && (
                  <PrismSavedViewsActionMenu
                    appliedSavedView={view}
                    setRenameViewOpen={setRenameViewOpen}
                    deleteView={deleteSavedView}
                  />
                )}
              </Flex>
            </Flex>
            <Flex flex="none" flow="row" pt3>
              <PrismViewFilters search={search} setSearch={setSearch} />
              <Flex
                flow="row"
                gap="sm"
                css={{ ml: 'auto', alignSelf: 'flex-end' }}
              >
                {viewActions}
              </Flex>
            </Flex>
            <Flex flow="column" flex="1" pt3 css={{ minHeight: 0 }}>
              {(columns.columnDefs?.length ?? 0) > 0 ? dataTable : null}
              {isPending && (
                <Flex
                  flex="1"
                  alignItems="start"
                  justifyContent="center"
                  css={{ height: '100%' }}
                >
                  <Loader size="lg" />
                </Flex>
              )}
            </Flex>
            <Box
              css={{
                padding: '$3 $5',
                borderTop: '1px solid $neutral20',
                width: '100%',
                zIndex: 3,
              }}
            >
              <Pagination
                bulkActions={bulkActions}
                currentPage={pageIndex + 1}
                totalItems={countData?.count || 0}
                itemsPerPage={pageSize}
                onPageChange={(page) =>
                  setPagination({ pageIndex: page - 1, pageSize })
                }
                onItemsPerPageChange={(itemsPerPage) =>
                  setPagination({ pageIndex: 0, pageSize: itemsPerPage })
                }
              />
            </Box>
          </Flex>
        </>
      )}
      {isColumnEditorOpen && (
        <ColumnEditorWindow
          columnVisibility={columnVisibility}
          columnOrder={columnOrder}
          columns={columns}
          onColumnVisibilityChange={setColumnVisibility}
          onColumnOrderChange={setColumnOrder}
          onClose={() => setIsColumnEditorOpen(false)}
          viewId={viewId}
        />
      )}
      <PrismViewRenameModal
        isOpen={renameViewOpen}
        viewName={view?.name || ''}
        viewNameError={viewNameError}
        onClose={() => {
          setRenameViewOpen(false);
        }}
        onOpenChange={setRenameViewOpen}
        handleViewRename={handleViewRename}
        setViewNameError={setViewNameError}
      />
    </Flex>
  );
};

export default PrismViews;
