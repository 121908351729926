import { Icon } from '@kandji-inc/bumblebee';
import {
  annotateDEPComputer,
  assignDEPComputersUser,
  changeDEPComputersAssetTag,
  changeDEPComputersBlueprint,
  exportDEPComputers,
  queryDEPComputers,
  setDEPComputers,
} from 'app/_actions/DEPComputer';
import { updateBlueprint } from 'app/_actions/blueprint';
import {
  clearGSuiteUsers,
  getUserIntegration,
  getUsers,
} from 'app/_actions/gSuite';
import { setModal, setSnackbar } from 'app/_actions/ui';
import { updateUser } from 'app/_actions/users';
import {
  AwaitingEnrollment,
  DEPViews,
  colors,
  columnOptions,
  links,
  noUserAssigned,
  omitVulnColumns,
} from 'app/common/constants';
import SelectAsyncPagination from 'components/common/select-async-pagination';
import { withPermissions } from 'contexts/account';
import { usePermissions } from 'contexts/account';
import { useIntegrations } from 'features/integrations/hooks';
import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { arrayPush, initialize } from 'redux-form';
import { ComputersDEPContext } from 'src/app/components/common/hooks/use-computersdep';
import { paths } from 'src/features/blueprints/common';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import { LineLoader } from '../interface/LineLoader';
import './DEPComputersTableMain.css';

import AwesomeCheckbox from '../interface/AwesomeCheckbox';
import { BootstrapTable, TableHeaderColumn } from './BootstrapTable';
import {
  formatTime,
  getDEPAssignHelperText,
  getDEPAssignHelperTitle,
  getFiltersFromUrl,
  getHelperText,
  getStatusComputersCircleIconClass,
  getStatusComputersColorClass,
  parseDateFromFilter,
  setFiltersToUrl,
  sortFuncDate,
} from './helpers';

import history from '../../router/history';
import { getDepIntegration, syncDevices } from '../integrations/Apple/api';
import { HoveredSpan, TableHeader } from '../interface/Base';
import HollowDropdown from '../interface/HollowDropdown';
import SearchString from '../interface/SearchString';
import CircleButton from '../interface/buttons/CircleButton';
import { Tooltip } from '../interface/tooltips/Tooltip';
import AddDevicesSelect from './AddDevicesSelect';
import AssetTagInput from './AssetTagInput';
import Table from './Table';
import { renderTableActions } from './utils';

const classNames = require('classnames');
const queryString = require('query-string');

const InfoIcon = styled(Icon)`
  margin: 0 5px 0 3px;
  font-size: 16px;
  color: #4d5a79;
  cursor: pointer;
`;

const FakeShadow = styled('div')`
  height: 20px;
  width: calc(100% + 20px);
  margin-bottom: -10px;
  position: sticky;
  top: ${(props) => props.top}px;
  margin-left: -10px;
  margin-right: -10px;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-left: calc(272px + 50px);
`;

const DropdownStyledToggle = styled(CircleButton)`
  background: white;
  border: 1px solid ${colors['grey-200']};
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  z-index: 3;
  position: absolute;
`;

const SetupAutoEnroll = styled.div`
  padding-bottom: 3px;
  border-bottom: 5px solid ${colors['marengo-100']};
  &:hover {
    cursor: pointer;
  }
`;

const UserSelectWrapper = styled.div`
  margin-top: 10px;

  .kandji-select-container.ade-user-select {
    .kandji-select__control {
      height: 36px;
    }
  }
`;

const StyledCheckbox = styled(AwesomeCheckbox)`
  margin: 16px 20px;
`;

const TablePanelWrapper = styled.section`
  background-color: var(--color-neutral-10);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -10px;
  padding: 5px 10px 13px 10px;
  color: ${colors['grey-500']};
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
`;

const CurrentViewWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const CurrentViewTitle = styled.div`
  font-weight: 500;
`;

const CurrentViewCaretIcon = styled.i`
  padding-bottom: 2px;
  margin: 0 10px;
  color: ${colors['marengo-700']};
`;

const SyncButton = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: var(--color-neutral-100);
  font-family: var(--font-family-primary);
  outline: none;
  cursor: ${(props) => (props.disabled ? 'defalut' : 'pointer')};
  font-size: 11px;
  border-bottom: 5px solid ${colors['marengo-100']};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const TextWithOverflow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
`;

const defaultView = {
  selectFeature: [
    'id',
    'status',
    'computerName',
    'serial',
    'model',
    'description',
    'blueprint',
    'assetUser',
    'DEPProfileAssignmentStatus',
  ], // DON'T REMOVE ID AND STATUS!!!
  widths: {},
  percentWidths: {},
  sortName: 'computerName',
  sortOrder: 'asc',
  showFilters: false,
};

const SyncInfo = styled('div')`
  font-family: var(--font-body-s-family);
  font-weight: var(--font-body-s-weight);
  font-size: var(--font-body-s-size);
  line-height: var(--font-body-s-line-height);
  color: var(--color-neutral-100);
  letter-spacing: var(--font-body-s-letter-spacing);
  font-style: normal;
  display: flex;
  align-items: center;
  text-transform: none;
  margin-right: 15px;
`;

const SyncTitle = styled('div')`
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 14px;
  margin-top: -4px;
`;

const PhotoWrapper = styled.div`
  position: relative;
  width: 25px;
  height: 0;
  display: inline-block;
  margin-right: 4px;
`;
const NoPhotoIcon = styled.i`
  position: absolute;
  top: -4px;
  padding-left: 1px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid ${colors['grey-150']};
  font-size: 20px;
`;

const UserAva = React.memo(({ data }) => (
  <PhotoWrapper>
    {!data.imgSrc && <NoPhotoIcon className="ss-icon ss-user" />}
    {data.imgSrc && (
      <PhotoWrapper>
        <img
          src={data.imgSrc}
          alt={`${data.label}`}
          style={{
            maxWidth: 25,
            maxHeight: 25,
          }}
        />
      </PhotoWrapper>
    )}
  </PhotoWrapper>
));

const UserSelectSingleValue = ({ children, data, ...rest }) => (
  <components.SingleValue data={data} {...rest}>
    <UserAva data={data} />
    {children}
  </components.SingleValue>
);
const UserSelectOption = ({ children, data, ...rest }) => (
  <components.Option data={data} {...rest}>
    <UserAva data={data} />
    {children}
  </components.Option>
);
const UserSelectMenu = ({ children, ...rest }) => (
  <components.Menu {...rest} className="ade-user-select__menu">
    {children}
  </components.Menu>
);

const FormatAssetUser = ({ row, props }) => {
  const { hasUserDirectoryIntegration } = useIntegrations();
  const { canAssignADEUser } = usePermissions();

  const [user, setUser] = React.useState(
    row.user && {
      value: row.user.id,
      label: row.user.name,
      imgSrc: row.user.photo,
    },
  );
  const {
    getUsers: callGetUsers,
    assignDEPComputersUser: callAssignDEPComputersUser,
  } = props;
  const title = get(row, 'user.name', null);
  const representationTitle = title === 'Not Assigned' ? null : title;

  if (
    canAssignADEUser &&
    hasUserDirectoryIntegration &&
    (row.MDMDevice.is_removed === true ||
      row.MDMDevice.is_removed === undefined)
  ) {
    return (
      <UserSelectWrapper onClick={(e) => e.stopPropagation()}>
        <SelectAsyncPagination
          value={user}
          onChange={(newUser) => {
            if (newUser === user) {
              // unassign user if current user is re-selected
              setUser({ value: noUserAssigned.id, label: noUserAssigned.name });
              callAssignDEPComputersUser([row.id], null);
            } else {
              // assign a new user to a device
              setUser(newUser);
              callAssignDEPComputersUser([row.id], newUser.value);
            }
          }}
          load={({ page, sizePerPage, search }) =>
            callGetUsers({
              is_archived: false,
              sizePerPage,
              page,
              search,
            })
          }
          sizePerPage={10}
          handleResponse={(response) => ({
            hasMore: !!response.next,
            options: response.results.map((theUser) => ({
              label: theUser.name,
              value: theUser.id,
              imgSrc: theUser.photo,
            })),
          })}
          components={{
            SingleValue: UserSelectSingleValue,
            Menu: UserSelectMenu,
            Option: UserSelectOption,
          }}
          menuPortalTarget={document.body}
          className="kandji-select--fit ade-user-select"
          debounceTimeout={300}
        />
      </UserSelectWrapper>
    );
  }
  return (
    <TextWithOverflow title={representationTitle}>
      {representationTitle}
    </TextWithOverflow>
  );
};
FormatAssetUser.propTypes = {
  row: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};

let isMounted = false;

class DEPComputersTableMain extends Table {
  static contextType = ComputersDEPContext;

  constructor(props) {
    super(props);
    this.serverSideSorting = true;
    this.selectRowId = 'id';
    this.serverSidePagination = true; // Don't remove! It's used on parent
    this.state = {
      ...this.state,
      trigger: 0,
      selectedRows: new Set(),
      currPage: 1,
      isSyncing: false,
      blueprintDropdownOpen: false,
      userDropdownOpen: false,
      integrationData: {},
      featuresOptions: [
        columnOptions.serial,
        columnOptions.description,
        columnOptions.model,
        columnOptions.color,
        columnOptions.deviceFamily,
        columnOptions.blueprint,
        columnOptions.assetUser,
        columnOptions.DEPProfileAssignmentStatus,
        columnOptions.assetTag,
        columnOptions.device_assigned_date,
        columnOptions.deviceAssignedBy,
        columnOptions.enrollmentStatus,
        columnOptions.lastEnrollment,
      ],
      ...defaultView,
    };
    this.dragged = null;
    this.fetchFunc = this.fetchComputers.bind(this);
    this.fetchComputers = this.fetchComputers.bind(this);
    this.renderColumnChooser = this.renderColumnChooser.bind(this);
    this.renderExportData = this.renderExportData.bind(this);
    this.resizeRow = this.resizeRow.bind(this);
    this.getInitSizeOfColumns = this.getInitSizeOfColumns.bind(this);
    this.toDefaultView = this.toDefaultView.bind(this);
  }

  componentDidMount() {
    isMounted = true;
    const { getUserIntegration: callGetUserIntegration } = this.props;
    this.props.onRef(this);
    this.fetchData(true);
    setTimeout(() => this.getInitSizeOfColumns(), 1);
    this.mountEvents();
    this.reloadIntegrationData();
  }

  componentDidUpdate(nextProps, nextState, _nextContext) {
    this.mountEvents();
    if (
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps.location, this.props.location)
    ) {
      this.props.needUpdate();
    }
  }

  componentWillUnmount() {
    isMounted = false;
    const { clearGSuiteUsers: getClearGSuiteUsers } = this.props;
    this.props.onRef(undefined);
    getClearGSuiteUsers();
  }

  getInitSizeOfColumns() {
    const headers = Array.prototype.slice.call(
      document.querySelectorAll('.resizible-header'),
    );
    const widths = {};
    let totalWidths = 0;
    headers.forEach((header) => {
      widths[header.getAttribute('data-field')] = header.offsetWidth;
      totalWidths += header.offsetWidth;
    });

    const percentWidths = {};
    Object.entries(widths).map((width) => {
      percentWidths[width[0]] = Math.round((width[1] / totalWidths) * 100);
    });

    // Just a tiny cleanup to address React warning, does not change functionality
    /* istanbul ignore next */
    if (isMounted) {
      this.setState({ widths, percentWidths });
    }
  }

  toDefaultView(callback) {
    this.setState({ ...defaultView }, () => {
      setFiltersToUrl([]);
      this.getInitSizeOfColumns();
      if (callback) {
        callback();
      }
    });
  }

  getCurrentViewState = () => ({
    filters: getFiltersFromUrl(this.props.location),
    selectFeature: this.state.selectFeature,
    widths: this.state.widths,
    percentWidths: this.state.percentWidths,
    sortName: this.state.sortName,
    sortOrder: this.state.sortOrder,
    showFilters: this.state.showFilters,
  });

  updateViewState = (viewState) => {
    this.setState(viewState);
  };

  onChangeBlueprint(e) {
    const { blueprintNames, DEPComputers } = this.props;
    e.preventDefault();
    const { selectedRows } = this.state;
    if (!selectedRows.size) {
      this.props.setSnackbar('Select at least one row!');
    } else {
      const blackListOfBlueprint = [...selectedRows].map((computerId) => {
        const computer = DEPComputers.find((comp) => comp.id === computerId);
        if (
          computer &&
          Object.keys(blueprintNames).includes(computer.blueprint_id)
        ) {
          return computer.blueprint_id;
        }
      });

      this.props.setModal('DEP_COMPUTER_CHANGE_BLUEPRINT', {
        computers: [...selectedRows],
        blackListOfBlueprint, // it needs for excluding from select
        fetchComputers: () => this.fetchData(true),
      });
    }
  }

  onAssignUser(e) {
    e.preventDefault();
    const { selectedRows } = this.state;

    if (!selectedRows.size) {
      this.props.setSnackbar('Select at least one row!');
    } else {
      this.props.setModal('COMPUTER_CHANGE_USER', {
        computers: [...selectedRows],
        fetchComputers: () => this.fetchData(true),
      });
    }
  }

  onChangeAssetTag(e) {
    e.preventDefault();
    const { selectedRows } = this.state;

    if (!selectedRows.size) {
      this.props.setSnackbar('Select at least one row!');
    } else {
      this.props.setModal('COMPUTER_CHANGE_ASSET_TAG', {
        computers: [...selectedRows],
        fetchComputers: () => this.fetchData(true),
      });
    }
  }

  getRequestQuery(blueprintId) {
    const { location } = this.props;
    const filters = getFiltersFromUrl(location);
    const options = [];

    if (blueprintId) {
      options.push({
        name: 'blueprint',
        value: [blueprintId],
        operator: 'equals',
      });
    } // For blueprint page

    // Ignoring as filters are not available in the DEP view
    /* istanbul ignore next */
    if (filters) {
      filters.map((filter) => {
        if (!Object.keys(filter).length) {
          return;
        }

        switch (filter.name) {
          case 'blueprint':
          case 'computerName':
          case 'model':
          case 'serial':
          case 'assetUser':
          case 'assetTag':
          case 'description':
          case 'color':
          case 'deviceFamily':
          case 'deviceAssignedBy':
          case 'enrollmentStatus': {
            const option = { ...filter };
            options.push(option);
            break;
          }
          case 'status': {
            const option = { ...filter };
            const alertStatus = option.value
              ? option.value.indexOf('WARNING,ERROR')
              : -1;
            if (alertStatus > -1) {
              option.value = [
                ...option.value.filter((i) => i !== 'WARNING,ERROR'),
                'WARNING',
                'ERROR',
              ];
            }
            const passStatus = option.value
              ? option.value.indexOf('PASS,REMEDIATED')
              : -1;
            if (passStatus > -1) {
              option.value = [
                ...option.value.filter((i) => i !== 'PASS,REMEDIATED'),
                'PASS',
                'REMEDIATED',
              ];
            }
            options.push(option);
            break;
          }
          case 'deviceAssignedDate':
          case 'lastEnrollment': {
            const option = {
              ...{ name: filter.name, operator: filter.operator },
            };
            if (filter.operator === 'between' && filter.value) {
              const splittedValues = filter.value.split('@');
              const from = parseDateFromFilter(splittedValues[0]).toISOString();
              const to = parseDateFromFilter(splittedValues[1]).toISOString();
              option.value = `${from}@${to}`;
            } else if (filter.operator !== 'between' && filter.value) {
              option.value = parseDateFromFilter(filter.value).toISOString();
            } else if (filter.operator === 'isBlank') {
              option.value = filter.value;
            } else {
              option.value = null;
            }
            options.push(option);
            break;
          }
        }
      });
    }
    return options;
  }

  makeQueryParams = () => {
    // Don't remove! It's used on parent
    const { blueprintId, location } = this.props;
    const filters = this.getRequestQuery(blueprintId);
    const queryParams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    const { ordering, sizePerPage, page, search } = queryParams;
    return {
      filters,
      ordering,
      sizePerPage,
      page,
      search,
    };
  };

  fetchComputers(queryParams) {
    const {
      setDEPComputers: callSetDEPComputers,
      queryDEPComputers: callQueryDEPComputers,
    } = this.props;

    return callQueryDEPComputers(queryParams)
      .then((res) => {
        const DEPComputers = res.results;
        const { blueprintNames } = this.props;
        const result = DEPComputers.map((computer) =>
          annotateDEPComputer(computer, blueprintNames),
        );
        callSetDEPComputers(result);
        return Promise.resolve(res);
      })
      .catch((err) => console.log(err));
  }

  renderBulkActions() {
    const actions = [
      ['Assign Blueprint', this.onChangeBlueprint.bind(this), 'circle-plus'],
      ['Assign User', this.onAssignUser.bind(this), 'user-group'],
      ['Assign Asset Tag', this.onChangeAssetTag.bind(this), 'note-sticky'],
    ];

    return (
      <HollowDropdown
        buttonTooltipMessage="Bulk Actions"
        buttonOpen={<DropdownStyledToggle icon="ellipsis" fontSize="17px" />}
        options={actions.map((action) => {
          const [actionName, actionFunction, iconClass] = action;
          return (
            <section
              key={camelCase(actionName)}
              className="cursor-pointer new-action-item-design"
              onClick={actionFunction}
            >
              {iconClass && (
                <Icon
                  name={iconClass}
                  style={{ marginRight: '15px' }}
                  aria-hidden="true"
                  size="sm"
                />
              )}
              {actionName}
            </section>
          );
        })}
      >
        <CircleButton icon="ellipsis" fontSize="17px" />
      </HollowDropdown>
    );
  }

  selectFeatures(value) {
    const { selectFeature, widths } = this.state;

    const localSelectFeature = selectFeature.filter(
      (selected) => selected !== 'status' && selected !== 'id',
    );
    const totalWidth = localSelectFeature
      .map((selected) => widths[selected])
      .reduce((a, b) => a + b);

    const newWidths = {};
    const newPercentWidths = {};

    const pxToPercent = (a) => Math.round((a / totalWidth) * 100);

    if (selectFeature.includes(value)) {
      // if include
      const width = widths[value];
      localSelectFeature.map((selected) => {
        newWidths[selected] =
          widths[selected] + width / (localSelectFeature.length - 1);
        newPercentWidths[selected] = pxToPercent(
          widths[selected] + width / (localSelectFeature.length - 1),
        );
      });
      this.setState({
        selectFeature: selectFeature.filter((v) => v !== value),
        widths: { ...newWidths },
        percentWidths: { ...newPercentWidths },
        sortName: this.state.sortName === value ? 'id' : this.state.sortName,
      });
    } else {
      // if not include
      const avgWidth = Math.floor(totalWidth / (localSelectFeature.length + 1));
      localSelectFeature.map((selected) => {
        const width = widths[selected];
        newWidths[selected] = width - avgWidth / localSelectFeature.length;
        newPercentWidths[selected] = pxToPercent(
          width - avgWidth / localSelectFeature.length,
        );
      });
      this.setState({
        selectFeature: [...selectFeature, value],
        widths: { ...newWidths, [value]: avgWidth },
        percentWidths: { ...newPercentWidths, [value]: pxToPercent(avgWidth) },
      });
    }
  }

  featuresCheckbox(option) {
    const { selectFeature } = this.state;
    const { name, value } = option;
    const disabled = value === 'Computer';
    return (
      <StyledCheckbox
        key={name}
        disabled={disabled}
        keyId={name}
        label={value}
        columnChooser
        checked={selectFeature.includes(name)}
        onCheck={() => this.selectFeatures(name)}
      />
    );
  }

  renderColumnChooser() {
    const { featuresOptions } = this.state;

    return (
      <HollowDropdown
        buttonTooltipMessage="Column Chooser"
        buttonOpen={
          <DropdownStyledToggle icon="table-columns" fontSize="13px" />
        }
        options={featuresOptions.map((option) => this.featuresCheckbox(option))}
      >
        <CircleButton icon="table-columns" fontSize="13px" />
      </HollowDropdown>
    );
  }

  renderExportData() {
    const { isExporting } = this.state;
    const { exportDEPComputers: callExportDEPComputers } = this.props;
    const exportData = () => {
      const filters = this.getRequestQuery();
      this.setState({ isExporting: true }, () => {
        callExportDEPComputers(filters, 'csv').then(() =>
          this.setState({ isExporting: false }),
        );
      });
    };

    return (
      <Tooltip tooltipMessage="Export CSV">
        <CircleButton
          icon="arrow-down-to-line"
          onClick={exportData}
          isProcessing={isExporting}
          fontSize="13px"
        />
      </Tooltip>
    );
  }

  onFetchClick = () => {
    this.setState({ isSyncing: true });
    syncDevices().finally(() => {
      this.reloadIntegrationData();
    });
  };

  getLastDeviceSync() {
    const { integrationData } = this.state;
    const lastDeviceSync = get(integrationData, 'last_device_sync', null);
    const lastDeviceSyncFormatted =
      lastDeviceSync == null
        ? 'Invalid timestamp format'
        : formatTime(lastDeviceSync, null, null, null, false);
    if (lastDeviceSyncFormatted === 'Invalid timestamp format') {
      return 'Not synced yet';
    }
    return `Last fetch ${lastDeviceSyncFormatted}`;
  }

  reloadIntegrationData = () => {
    const { setCounts } = this.context;
    getDepIntegration()
      .then((integrationData) => {
        const integrationDataForState =
          integrationData.days_left === null ? {} : integrationData;
        this.setState({ integrationData: integrationDataForState });
        setCounts(integrationData?.device_counts);
      })
      .catch(() => this.setState({ integrationData: {} }))
      .finally(() => this.setState({ isLoading: false, isSyncing: false }));
    this.fetchData();
  };

  renderActionButtons = () => null;

  isAwaitingEnrollment = (row) => row.computerName === AwaitingEnrollment;

  formatStatusRow = (cell, row) => {
    if (!['ERROR', 'WARNING'].includes(row.status) && !row.is_missing) {
      return null;
    }
    const title = getHelperText(
      row.status,
      row.is_missing,
      row.deferred_install,
    );
    const iconClass = getStatusComputersCircleIconClass(
      row.status,
      row.is_missing,
      row.deferred_install,
    );
    const iconColorClass = getStatusComputersColorClass(
      row.status,
      null,
      row.deferred_install,
      row.is_missing,
    );
    return (
      <div className="d-flex align-items-center justify-content-center height-100">
        <i
          className={`table-row-status-icon ${iconClass} ${iconColorClass}`}
          id={`statusRow${row.id}`}
        />
        <UncontrolledTooltip
          placement="top"
          delay={{ show: 400, hide: 0 }}
          innerClassName="custom-helper"
          target={`statusRow${row.id}`}
        >
          {title}
        </UncontrolledTooltip>
      </div>
    );
  };

  formatComputerNameRow = (cell, row) => {
    let color;
    if (this.isAwaitingEnrollment(row)) {
      color = colors['grey-300'];
    }
    return (
      <span style={{ color }} title={row.computerName || 'No info found'}>
        {row.computerName || 'No info found'}
      </span>
    );
  };

  formatModelRow = (cell, row) => (
    <span title={row.model || 'No info found'}>
      {row.model || 'No info found'}
    </span>
  );

  formatBlueprintRow = (cell, row) => {
    const {
      blueprints,
      changeDEPComputersBlueprint: callChangeDEPComputersBlueprint,
    } = this.props;
    const rowBlueprint = this.props.blueprints.find(
      (blueprint) => blueprint.id === row.blueprint_id,
    );
    return row.MDMDevice.is_removed === true ||
      row.MDMDevice.is_removed === undefined ? (
      <AddDevicesSelect
        title="Select"
        selected={row.blueprint_info}
        verticalCentered
        isRightAlign
        minWidth="280px"
        list={blueprints}
        onDropClick={(open) => {
          this.setState({ blueprintDropdownOpen: !open });
        }}
        resetThenSet={(blueprint) => {
          callChangeDEPComputersBlueprint([row.id], blueprint.id);
        }}
      />
    ) : (
      <TextWithOverflow>
        <span
          title={row.blueprint || 'No info found'}
          className="underline-cell"
          onClick={() =>
            history.push(paths.getBlueprintRouteByType(rowBlueprint))
          }
        >
          {row.blueprint || 'No info found'}
        </span>
      </TextWithOverflow>
    );
  };

  formatAgentVersionRow = (cell, row) => (
    <span title={row.agentVersion || 'No info found'}>
      {row.agentVersion || 'No info found'}
    </span>
  );

  formatOSRow = (cell, row) => (
    <span title={row.OS || 'No info found'}>{row.OS || 'No info found'}</span>
  );

  formatLastEnrollRow = (cell, row) => (
    <HoveredSpan
      hoveredText={(row.mdm_device && row.lastEnrollmentNotFormatted) || ''}
      text={(row.mdm_device && row.lastEnrollmentNotFormatted) || ''}
    />
  );

  formatFirstEnrollRow = (cell, row) => (
    <HoveredSpan
      hoveredText={row.firstEnrollmentNotFormatted}
      text={row.firstEnrollmentNotFormatted}
    />
  );

  formatLastCheckInRow = (cell, row) => (
    <HoveredSpan
      hoveredText={
        row.lastCheckIn === 'No info'
          ? 'No info'
          : formatTime(row.lastCheckIn, null, null, null, true)
      }
      text={
        row.lastCheckIn === 'No info'
          ? 'No info'
          : formatTime(row.lastCheckIn, null, null, null, false)
      }
    />
  );

  formatLongLengthRow = (cell) => (
    <span title={cell || 'No info found'}>{cell || 'No info found'}</span>
  );

  formatDescriptionRow = (cell, row) => (
    <span title={row.description || 'No info found'}>
      {row.description || 'No info found'}
    </span>
  );

  formatAssignedDateRow = (cell, row) => (
    <span title={row.deviceAssignedDate || 'No info found'}>
      {row.deviceAssignedDate || 'No info found'}
    </span>
  );

  formatDEPProfileAssignmentStatus = (cell, row) => {
    const {
      last_assignment_status: status,
      assignment_status_received_at: receivedAt,
      failed_assignment_attempts: failedAttempts,
      serial: target,
    } = row;
    const title = getDEPAssignHelperTitle(status, receivedAt, failedAttempts);
    const text = getDEPAssignHelperText(status);
    const validTarget = `serial-${target}`;
    return (
      <>
        <span id={validTarget}>{text}</span>
        <UncontrolledTooltip
          placement="top"
          delay={{ show: 400, hide: 0 }}
          target={validTarget}
          modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
        >
          {title}
        </UncontrolledTooltip>
      </>
    );
  };

  formatEnrollmentStatusRow = (cell, row) => (
    <span title={row.enrollmentStatus || 'No info found'}>
      {row.enrollmentStatus || 'No info found'}
    </span>
  );

  formatColorRow = (cell, row) => (
    <span title={row.color || 'No info found'}>
      {row.color || 'No info found'}
    </span>
  );

  formatDeviceFamilyRow = (cell, row) => (
    <span title={row.deviceFamily || 'No info found'}>
      {row.deviceFamily || 'No info found'}
    </span>
  );

  formatDeviceAssignedByRow = (cell, row) => (
    <span title={row.deviceAssignedBy || 'No info found'}>
      {row.deviceAssignedBy || 'No info found'}
    </span>
  );

  formatAssetUser = (cell, row) => (
    <FormatAssetUser row={row} props={this.props} />
  );

  formatAssetTag = (cell, row) => {
    const { changeDEPComputersAssetTag: callChangeDEPComputersAssetTag } =
      this.props;
    if (
      /* istanbul ignore next */
      this.hasUserDirectoryIntegration &&
      (row.MDMDevice.is_removed === true ||
        row.MDMDevice.is_removed === undefined)
    ) {
      return (
        <AssetTagInput
          initialTag={cell === 'No info' ? '' : cell}
          onBlur={(tag) => {
            callChangeDEPComputersAssetTag([row.id], tag);
          }}
        />
      );
    }
    return (
      <span title={row.assetTag === 'No info' ? null : row.assetTag}>
        {row.assetTag === 'No info' ? null : row.assetTag}
      </span>
    );
  };

  formatDuplicatesRow = (cell, row) => {
    if (row.duplicates && row.duplicates.length > 0) {
      let title = 'Duplicates:\n';
      row.duplicates.map((el) => (title += `${el.name} - ${el.id}\n`));
      return (
        <div>
          <Icon
            id={`duplicatesRow${row.id}`}
            name="octagon-exclamation"
            className="c-dark-red"
          />
          <UncontrolledTooltip
            placement="top"
            delay={{ show: 400, hide: 0 }}
            innerClassName="custom-helper"
            target={`duplicatesRow${row.id}`}
          >
            {title}
          </UncontrolledTooltip>
        </div>
      );
    }
  };

  renderLineLoader = () => <LineLoader />;

  // Ignoring since testing drag and drop only works in a real browser
  // See: https://testing-library.com/docs/example-drag/
  /* istanbul ignore next */
  mountEvents() {
    const headers = Array.prototype.slice.call(
      document.querySelectorAll('.draggable-header'),
    );

    if (this.state.resizing) {
      headers.forEach((header) => {
        header.setAttribute('draggable', false);
      });
    } else {
      headers.forEach((header, i) => {
        header.setAttribute('draggable', true);

        // the dragged header
        header.ondragstart = (e) => {
          e.dataTransfer.setData('Text', this.id);
          e.stopPropagation();
          this.dragged = i + 2; // + 2 for `status` and `computerName` BOOKMARK
        };

        header.ondrag = (e) => {
          e.stopPropagation();
        };

        header.ondragend = (e) => {
          e.stopPropagation();
        };

        // the dropped header
        header.ondragover = (e) => {
          e.preventDefault();
        };

        header.ondrop = (e) => {
          e.preventDefault();

          const newSelectFeature = [...this.state.selectFeature];
          newSelectFeature.splice(
            i + 2,
            0,
            newSelectFeature.splice(this.dragged, 1)[0],
          );
          this.setState({ selectFeature: newSelectFeature });
        };
      });
    }
  }

  // Ignoring since testing drag and drop only works in a real browser
  // See: https://testing-library.com/docs/example-drag/
  /* istanbul ignore next */
  resizeRow(lastX, deltaX, dataField, nextDataField) {
    const minWidth = 50;
    this.setState((prevState) => {
      const prevWidths = prevState.widths;
      const prevPercentWidths = prevState.percentWidths;
      const { selectFeature } = prevState;

      const dataFieldWidth = prevWidths[dataField];
      const nextDataFieldWidth = prevWidths[nextDataField];

      const totalWidth = selectFeature
        .filter((item) => item !== 'status' && item !== 'id')
        .map((item) => prevWidths[item])
        .reduce((a, b) => a + b);

      const pxToPercent = (a) => Math.round((a / totalWidth) * 100);

      const columnWidths = () => {
        if (!(deltaX + dataFieldWidth > minWidth)) {
          return [prevWidths[dataField], prevWidths[nextDataField]];
        }
        if (!(nextDataFieldWidth - deltaX > minWidth)) {
          return [prevWidths[dataField], prevWidths[nextDataField]];
        }
        return [dataFieldWidth + deltaX, nextDataFieldWidth - deltaX];
      };

      return {
        widths: {
          ...prevWidths,
          [dataField]: columnWidths()[0],
          [nextDataField]: columnWidths()[1],
        },
        percentWidths: {
          ...prevPercentWidths,
          [dataField]: pxToPercent(columnWidths()[0]),
          [nextDataField]: pxToPercent(columnWidths()[1]),
        },
      };
    });
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState({ currPage: 1 });
    this.changeFilterInUrl({
      ordering: this.getOrdering(sortName, sortOrder),
      page: 1,
    });
  };

  getOrdering = (sortName, sortOrder) =>
    sortOrder === 'desc' ? `-${sortName}` : sortName;

  render() {
    const { bannerTopOffset, setCounts } = this.context;
    const {
      DEPComputers,
      showPaginationOnTop,
      activeViewId,
      location,
      isDepAccount,
      permissions,
    } = this.props;
    const {
      selectFeature: selectFeatureState,
      sortName,
      sortOrder,
      percentWidths,
      currPage,
      resizing,
      isLoading,
      isSyncing,
      showFilters,
      blueprintDropdownOpen,
      userDropdownOpen,
      integrationData,
    } = this.state;

    const selectFeature = omitVulnColumns(selectFeatureState);

    const openComputer = (row) => {
      let deviceId = row.id;
      if (row.MDMDevice) {
        deviceId = row.MDMDevice.id;
      }
      if (
        row.MDMDevice.is_removed === true ||
        row.MDMDevice.is_removed === undefined
      ) {
        return;
      }
      history.push(`${links.devices}/${deviceId}`);
    };
    const defaultSortFieldName = () =>
      selectFeature.includes('name') ? 'name' : 'id';
    const allViews = [...DEPViews];
    const activeView = allViews.find((view) => view.id === activeViewId);
    const activeViewName = activeView ? activeView.name : 'Auto-Enroll Devices';
    const options = {
      ...this.defaultOptions,
      onRowClick: (row) => openComputer(row),
      sortName: sortName || defaultSortFieldName(),
      defaultSortName: sortName || defaultSortFieldName(),
      sortOrder: sortOrder || 'asc',
      onSortChange: this.onSortChange.bind(this),
      page: currPage,
      resultsString: 'Devices',
      showPaginationOnTop,
      noDataView: isLoading ? this.renderLineLoader() : this.props.noDataView,
      toolBar: this.renderActionButtons,
    };

    delete options.toolBar;
    delete options.btnGroup;

    const columns = {
      id: {
        dataField: 'id',
        isKey: true,
        hidden: true,
        searchable: false,
      },
      status: {
        dataField: 'status',
        dataAlign: 'right',
        dataFormat: this.formatStatusRow.bind(this),
        columnClassName: 'td-icon td-status-icon',
        className: 'td-icon',
        width: '15px',
        searchable: false,
      },
      computerName: {
        dataField: 'computerName',
        dataSort: true,
        sortName: 'mdm_device__computer__name',
        dataFormat: this.formatComputerNameRow,
        children: 'Device Name',
      },
      model: {
        dataField: 'model',
        dataSort: true,
        sortName: 'model',
        dataFormat: this.formatModelRow,
        children: 'Model',
        searchable: false,
      },
      lastCheckIn: {
        dataField: 'lastCheckIn',
        dataSort: true,
        sortName: 'last_check_in',
        dataFormat: this.formatLastCheckInRow,
        children: 'Checked In',
        searchable: false,
        sortFunc: sortFuncDate,
      },
      serial: {
        dataField: 'serial',
        dataSort: true,
        sortName: 'serial_number',
        dataFormat: this.formatLongLengthRow,
        children: 'Serial',
      },
      blueprint: {
        dataField: 'blueprint',
        dataSort: true,
        sortName: 'blueprint',
        dataFormat: this.formatBlueprintRow,
        children: 'Blueprint',
        searchable: false,
        thStyle: { minWidth: 150 },
        tdStyle: { overflow: 'visible', minWidth: 150 },
      },
      firstEnrollment: {
        dataField: 'firstEnrollment',
        dataSort: true,
        sortName: 'first_enrolled_at',
        dataFormat: this.formatFirstEnrollRow,
        children: 'First Enrollment',
        searchable: false,
        sortFunc: sortFuncDate,
      },
      lastEnrollment: {
        dataField: 'lastEnrollment',
        dataSort: true,
        sortName: 'mdm_device__computer__enrolled_at',
        dataFormat: this.formatLastEnrollRow,
        children: 'Last Enrollment',
        searchable: false,
        sortFunc: sortFuncDate,
      },
      assetUser: {
        dataField: 'assetUser',
        dataFormat: this.formatAssetUser,
        dataSort: true,
        sortName: 'user__name',
        children: 'User',
        searchable: false,
        thStyle: { minWidth: 150 },
        tdStyle: { overflow: 'visible', minWidth: 150 },
      },
      assetTag: {
        dataField: 'assetTag',
        dataFormat: this.formatAssetTag,
        dataSort: true,
        sortName: 'asset_tag',
        children: 'Asset Tag',
        searchable: false,
        thStyle: { minWidth: 150 },
        tdStyle: { overflow: 'visible', minWidth: 150 },
      },
      OS: {
        dataField: 'OS',
        dataFormat: this.formatOSRow,
        dataSort: true,
        sortName: 'os',
        children: 'OS',
        searchable: false,
      },
      agentVersion: {
        dataField: 'agentVersion',
        dataFormat: this.formatAgentVersionRow,
        dataSort: true,
        sortName: 'agent_version',
        children: 'Agent',
        searchable: false,
      },
      duplicates: {
        dataField: 'duplicates',
        dataFormat: this.formatDuplicatesRow,
        columnClassName: 'td-icon',
        className: 'td-icon',
        searchable: false,
      },
      description: {
        dataField: 'description',
        dataFormat: this.formatDescriptionRow,
        dataSort: true,
        sortName: 'description',
        children: 'Description',
        searchable: false,
      },
      device_assigned_date: {
        dataField: 'device_assigned_date',
        dataFormat: this.formatAssignedDateRow,
        dataSort: true,
        sortName: 'device_assign_date',
        children: 'Assigned to Kandji',
        searchable: false,
      },
      DEPProfileAssignmentStatus: {
        dataField: 'DEPProfileAssignmentStatus',
        dataFormat: this.formatDEPProfileAssignmentStatus,
        dataSort: true,
        sortName: 'profile_status',
        children: 'Profile',
        searchable: false,
      },
      enrollmentStatus: {
        dataField: 'enrollmentStatus',
        dataFormat: this.formatEnrollmentStatusRow,
        dataSort: true,
        children: 'Enrollment Status',
      },
      color: {
        dataField: 'color',
        dataFormat: this.formatColorRow,
        dataSort: true,
        sortName: 'color',
        children: 'Color',
      },
      deviceFamily: {
        dataField: 'deviceFamily',
        dataFormat: this.formatDeviceFamilyRow,
        dataSort: true,
        sortName: 'device_family',
        children: 'Device Family',
      },
      deviceAssignedBy: {
        dataField: 'deviceAssignedBy',
        dataFormat: this.formatDeviceAssignedByRow,
        dataSort: true,
        sortName: 'device_assigned_by',
        children: 'Assigned By',
      },
    };

    const colTotal = selectFeature.map((item, index) => {
      const col = columns[item];
      if (col) {
        if (
          index === selectFeature.length - 1 ||
          col.dataField === 'status' ||
          col.dataField === 'computerName'
        ) {
          return {
            ...col,
            className: classNames(col.className, {
              'header-column-border-right': col.dataField === 'computerName',
              'resizible-header': col.dataField !== 'status',
              'draggable-header':
                col.dataField !== 'status' && col.dataField !== 'computerName',
            }),
            columnClassName: classNames(col.columnClassName, {
              'column-border-right': col.dataField === 'computerName',
            }),
            children: (
              <>
                <div className="header-column-content">
                  {col.dataField !== 'computerName' &&
                    col.dataField !== 'status' && (
                      <div className="drag-icon">
                        <Icon
                          name="grip-dots-vertical"
                          className="drag-icon mr-1"
                        />
                      </div>
                    )}
                  {col.children}
                  {col.dataField === 'DEPProfileAssignmentStatus' && (
                    <InfoIcon
                      size="xs"
                      name="circle-info"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          'https://support.kandji.io/support/solutions/articles/72000559781',
                        );
                      }}
                    />
                  )}
                </div>
                {
                  // Ignoring since testing drag and drop only works in a real browser
                  // See: https://testing-library.com/docs/example-drag/
                  /* istanbul ignore next */
                  col.dataField !== 'status' &&
                    index !== selectFeature.length - 1 && (
                      <Draggable
                        axis="x"
                        defaultClassName="DragHandle"
                        defaultClassNameDragging="DragHandleActive"
                        onDrag={(event, { lastX, deltaX }) => {
                          this.setState({ resizing: true });
                          this.resizeRow(
                            lastX,
                            deltaX,
                            col.dataField,
                            selectFeature[index + 1],
                          );
                        }}
                        onStop={() =>
                          setTimeout(
                            () => this.setState({ resizing: false }),
                            1,
                          )
                        }
                        position={{ x: 0 }}
                        zIndex={9999}
                      >
                        <div className="resizer-wrapper">
                          {col.dataField !== 'status' && (
                            <span
                              className="resizer"
                              style={{
                                marginRight:
                                  col.dataField === 'computerName'
                                    ? '-1px'
                                    : null,
                              }}
                            />
                          )}
                        </div>
                      </Draggable>
                    )
                }
              </>
            ),
            thStyle: {
              ...col.thStyle,
              position: 'relative',
              color: '#666',
            },
            tdStyle: {
              ...col.tdStyle,
            },
            width:
              percentWidths && percentWidths[col.dataField]
                ? `${percentWidths[col.dataField]}%`
                : col.width,
            resizing,
          };
        }
        return {
          ...col,
          className: classNames(col.className, 'computers-table-column', {
            'first-after-line-column': index === 3,
            'resizible-header': col.dataField !== 'status',
            'draggable-header':
              col.dataField !== 'computerName' && col.dataField !== 'status',
          }),
          columnClassName: classNames(col.columnClassName, {
            'first-after-line-column': index === 3,
          }),
          children: (
            <>
              <div className="header-column-content">
                {col.dataField !== 'computerName' &&
                  col.dataField !== 'status' &&
                  index !== selectFeature.length - 1 && (
                    <div className="drag-icon">
                      <Icon
                        name="grip-dots-vertical"
                        className="drag-icon mr-1"
                      />
                    </div>
                  )}
                {col.dataField === 'DEPProfileAssignmentStatus' && (
                  <InfoIcon
                    name="circle-info"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        'https://support.kandji.io/support/solutions/articles/72000559781',
                      );
                    }}
                  />
                )}
                {col.children}
              </div>
              <Draggable
                axis="x"
                defaultClassName="DragHandle"
                defaultClassNameDragging="DragHandleActive"
                onDrag={(event, { lastX, deltaX }) => {
                  this.setState({ resizing: true });
                  this.resizeRow(
                    lastX,
                    deltaX,
                    col.dataField,
                    selectFeature[index + 1],
                  );
                }}
                onStop={() =>
                  setTimeout(() => this.setState({ resizing: false }), 1)
                }
                position={{ x: 0 }}
                zIndex={9999}
              >
                <div className="resizer-wrapper">
                  {col.dataField !== 'status' && <span className="resizer" />}
                </div>
              </Draggable>
            </>
          ),
          thStyle: {
            ...col.thStyle,
            position: 'relative',
          },
          tdStyle: {
            ...col.tdStyle,
            position: 'relative',
          },
          width:
            percentWidths && percentWidths[col.dataField]
              ? `${percentWidths[col.dataField]}%`
              : col.width,
          resizing,
        };
      }
    });

    const filtersOffset = showFilters
      ? get(this.tableFiltersBlock, 'clientHeight', 0)
      : 0;
    const viewPanelOffset = get(this.viewPanelBlock, 'clientHeight', 0);
    const URLparams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });

    return (
      <Wrapper>
        <TableHeader title="Devices" paddingBottom="14px" sticky>
          <SearchString
            defaultValue={URLparams.search}
            label="Search Devices..."
            searchFunc={debounce(
              (searchString) =>
                this.changeFilterInUrl({ search: searchString, page: 1 }),
              500,
            )}
            iconPosition="right"
          />
          {renderTableActions([
            this.renderColumnChooser(),
            this.renderExportData(),
            permissions.canManageDevices && this.renderBulkActions(),
          ])}
        </TableHeader>
        <TablePanelWrapper
          className="kandji-position-sticky"
          style={{
            top: bannerTopOffset + 80 + filtersOffset,
            zIndex: 3,
          }}
          ref={(node) => {
            this.viewPanelBlock = node;
          }}
        >
          <CurrentViewWrapper>
            Current View
            <CurrentViewCaretIcon className="fas fa-caret-right" />
            <CurrentViewTitle>{activeViewName}</CurrentViewTitle>
          </CurrentViewWrapper>
          {isDepAccount ? (
            <SyncInfo>
              <SyncTitle>{this.getLastDeviceSync()}</SyncTitle>
              <SyncButton onClick={this.onFetchClick} disabled={isSyncing}>
                {isSyncing ? 'Fetching' : 'Fetch now'}
              </SyncButton>
            </SyncInfo>
          ) : (
            <SetupAutoEnroll
              onClick={() => history.push('/my-company/integrations')}
            >
              Setup Automated Device Enrollment
            </SetupAutoEnroll>
          )}
        </TablePanelWrapper>
        <FakeShadow
          top={bannerTopOffset + 80 + viewPanelOffset + filtersOffset}
        />
        <BootstrapTable
          ref={(node) => (this.table = node)}
          data={DEPComputers}
          version="4"
          // search
          remote={() => ({
            pagination: true,
          })}
          searchPlaceholder="Quick Search..."
          options={options}
          pagination
          selectRow={{
            ...this.selectRowProp,
            // only computers awaiting enrollment can be selected
            unselectable: DEPComputers.filter(
              (computer) => !this.isAwaitingEnrollment(computer),
            ).map((computer) => computer.id),
          }}
          trClassName="table-row-clickable"
          tableContainerClass="bst-borderless h-auto"
          containerClass="bst-borderless old-table"
          bodyStyle={{ borderBottom: '1px solid #dee2e6' }}
          headerContainerClass="kandji-position-sticky"
          containerStyle={{
            marginBottom: '110px',
          }}
          headerStyle={{
            zIndex: 2,
          }}
          tableStyle={{
            width: `${Math.max((colTotal.length - 2) * 15, 100)}%`,
          }}
          scrollable={colTotal.length - 2 > 5}
          dropdown={blueprintDropdownOpen || userDropdownOpen}
        >
          {colTotal.map((column) => (
            <TableHeaderColumn key={uuidv4()} {...column} />
          ))}
        </BootstrapTable>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.account.user.id,
  userSettings: state.account.user.settings,
  isDepAccount: state.account.company.is_dep,
  DEPComputers: state.data.DEPComputers,
  blueprints: state.data.blueprints,
  blueprintNames: state.data.blueprintNames,
  tableFilters: state.form.TableFilters,
  allComputers: state.filters.DEPComputers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal,
      setSnackbar,
      setDEPComputers,
      updateBlueprint,
      queryDEPComputers,
      initialize,
      exportDEPComputers,
      updateUser,
      getUsers,
      getUserIntegration,
      clearGSuiteUsers,
      arrayPush,
      assignDEPComputersUser,
      changeDEPComputersBlueprint,
      changeDEPComputersAssetTag,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPermissions(DEPComputersTableMain));
