import { Flex, Heading, Icon, Text } from '@kandji-inc/nectar-ui';

import type { Vulnerability } from '../../vulnerability.types';

const Header = (props: { vulnerability: Vulnerability }) => {
  const { vulnerability } = props;
  const { cve_id, app_name, version } = vulnerability;

  return (
    <Flex
      justifyContent="start"
      gap="sm"
      alignItems="center"
      css={{ flexGrow: 1 }}
    >
      <Flex
        p2
        css={{
          backgroundColor: '$red10',
          borderRadius: '$round',
          height: '40px',
          width: '40px',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Icon name="malware" color="var(--colors-red60)" />
      </Flex>
      <Flex flow="column">
        <Flex gap="sm">
          <Heading size="3" css={{ fontWeight: '$medium' }}>
            {cve_id}
          </Heading>
        </Flex>
        <Flex gap="xs">
          <Text css={{ color: '$neutral70' }}>{app_name}</Text>
          <Text css={{ color: '$neutral70' }}>|</Text>
          <Text css={{ color: '$neutral70' }}>{version}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Header };
