import { Flex, Loader, styled } from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import EDRDropdownButton from 'src/features/edr/common/components/EDRDropdownButton';
import { NoResultsFound } from 'src/features/edr/vulnerability/common/table/no-results-found';
import { DEFAULT_DETECTION_DATE_FILTER } from 'src/features/edr/vulnerability/constants';
import { useGetVulnerabilityDevices } from 'src/features/edr/vulnerability/hooks/use-get-vulnerability-devices';
import useVulnerability from 'src/features/edr/vulnerability/store';
import { DeviceCard } from './device-card';

type SortTypes = 'name' | '-name';

const StickySeparator = styled(Flex, {
  height: 1,
  bg: '$neutral20',
  position: 'sticky',
  top: 111,
  zIndex: 1,
});

const DEFAULT_FILTER = {
  sort: 'name' as SortTypes,
  term: '',
  detectionDate: DEFAULT_DETECTION_DATE_FILTER,
  blueprint: [],
};

const SORT_LABELS: Record<SortTypes, string> = {
  name: 'Device name A-Z',
  '-name': 'Device name Z-A',
};

const DeviceList = () => {
  const selectedVulnerability = useVulnerability(
    (state) => state.selectedVulnerability,
  );

  const [expandedDeviceCards, setExpandedDeviceCards] = useState<string[]>([]);

  const [filter, setFilter] = useState<{
    sort: SortTypes;
    term: string;
    detectionDate: DetectionDateFilterFields;
    blueprint: string[];
  }>(DEFAULT_FILTER);

  const { data, isLoading } = useGetVulnerabilityDevices(
    selectedVulnerability.cveInfo.cve_id,
    [filter],
    { sort_by: filter.sort },
  );

  const hasFilterApplied =
    filter.term || filter.detectionDate || filter.blueprint.length > 0;
  const hasDevices = data?.devices?.length > 0;

  return (
    <Flex hFull flow="column" pb3 css={{ gap: '10px' }}>
      <StickySeparator />
      <Flex
        flow="column"
        gap="sm"
        alignItems="end"
        css={{
          mt: -11,
          pt: 10,
          pb: 10,
          bg: '$neutral0',
          position: 'sticky',
          top: 27,
          zIndex: 2,
          marginInline: -20,
          paddingInline: 20,
        }}
      >
        <EDRDropdownButton
          value={filter.sort}
          triggerLabel={SORT_LABELS[filter.sort]}
          options={Object.entries(SORT_LABELS).map(([value, label]) => ({
            label,
            value,
          }))}
          onChange={(value) =>
            setFilter((prev) => ({ ...prev, sort: value as SortTypes }))
          }
          css={{ dropdownCss: { zIndex: 2 }, buttonCss: { height: '28px' } }}
        />
      </Flex>

      {isLoading && (
        <Flex
          wFull
          hFull
          flow="column"
          alignItems="center"
          justifyContent="center"
          gap="sm"
          data-testid="loading-devices"
        >
          <Loader size="lg" />
        </Flex>
      )}

      {!isLoading && hasDevices && (
        <Flex flow="column" css={{ gap: '10px', mt: -10 }}>
          {data?.devices.map((device) => (
            <DeviceCard
              device={device}
              isExpanded={expandedDeviceCards.includes(device.device_id)}
              setIsExpanded={(value) =>
                setExpandedDeviceCards(
                  value
                    ? [...expandedDeviceCards, device.device_id]
                    : expandedDeviceCards.filter(
                        (id) => id !== device.device_id,
                      ),
                )
              }
            />
          ))}
        </Flex>
      )}

      {!isLoading && !hasDevices && hasFilterApplied && <NoResultsFound />}
    </Flex>
  );
};

export { DeviceList };
