/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { Vulnerability } from '../vulnerability.types';

const useGetVulnerability = (
  vulnerabilityId: string,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerability', vulnerabilityId, ...keys],
    queryFn: () =>
      vulnerabilityApi(`vulnerabilities/${vulnerabilityId}`).get(params),
  });

  const data: Vulnerability = apiRes?.data;

  return { data, isLoading };
};

export { useGetVulnerability };
