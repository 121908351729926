/* istanbul ignore file */
// this gets tested in ManualEnrollmentSingle.tsx
import {
  DropdownMenuPrimitives,
  Icon,
  SelectV2Primitives as Select,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useFocusInput } from 'src/hooks/useFocusInput';
import {
  ScrollArea,
  SelectItemsContainer,
} from '../ADEListView/ADEListViewTable/ADECellSelect/ADECellSelect.styles';
import type { AuthBlueprint } from './ManualEnrollment.types';

const Label = styled('div', DropdownMenuPrimitives.Label, {
  textTransform: 'unset',
  padding: '6px $3',
});

interface MEBlueprintDropdownProps {
  blueprints: AuthBlueprint[];
  onValueChange: (blueprintId: string) => Promise<void> | void;
}

export function MEBlueprintDropdown({
  blueprints,
  onValueChange,
}: MEBlueprintDropdownProps) {
  const [filteredBlueprints, setFilteredBlueprints] = React.useState([
    ...blueprints,
  ]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const inputRef = useFocusInput([filteredBlueprints, isOpen]);

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const searchTerm = e.target.value;
    setFilteredBlueprints(
      blueprints.filter((blueprint) =>
        blueprint.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }

  return (
    <div style={{ marginLeft: 'auto' }}>
      <Select.Root
        value="Select Blueprint"
        onOpenChange={(open) => setIsOpen(open)}
        onValueChange={onValueChange}
      >
        <Select.Trigger variant="default" style={{ paddingRight: 8 }}>
          <Select.Value>Select Blueprint</Select.Value>
          <Icon
            name="fa-angle-down-small"
            style={{ color: '$neutral90', width: 20, height: 20 }}
          />
        </Select.Trigger>
        <Select.Portal>
          <Select.Content
            side="bottom"
            sideOffset={5}
            align="end"
            position="popper"
            style={{ overflow: 'hidden', maxHeight: 320, width: 460 }}
          >
            <Label>
              Select a Blueprint to download a one-time use enrollment profile.
            </Label>
            <div style={{ padding: '6px 12px' }}>
              <TextField
                ref={inputRef}
                autoComplete="off"
                icon="magnifying-glass"
                placeholder="Search Blueprints"
                compact
                onChange={handleSearchChange}
              />
            </div>
            <SelectItemsContainer>
              <ScrollArea.Root type="auto">
                <Select.Viewport>
                  <ScrollArea.Viewport>
                    {filteredBlueprints.map((blueprint) => (
                      <Select.Item value={blueprint.id}>
                        {blueprint.name}
                      </Select.Item>
                    ))}
                  </ScrollArea.Viewport>
                </Select.Viewport>
                <ScrollArea.Scrollbar orientation="vertical">
                  <ScrollArea.Thumb />
                </ScrollArea.Scrollbar>
              </ScrollArea.Root>
            </SelectItemsContainer>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
}
