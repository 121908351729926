import { checkSubDomainExists } from 'app/api/utils';
import { setAuthToken } from 'app/auth';
import 'core-js/features/promise';
import get from 'lodash/get';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './app/common/polyfills';
import './assets/fonts/fonts.css';
import { setI18nLocale, setI18nProvider } from '@kandji-inc/nectar-i18n';
import App from './components/App';
import Maintenance from './components/Maintenance';
import { DEMO_KEY_NAME, hasSubdomainMatch, ignoredDomains } from './config';
import datadogRum from './config/datadog-rum';
import FeatureFlags from './config/feature-flags';
import * as constants from './constants';

const queryString = require('query-string');

const UNDER_MAINTENANCE = false;

const DEFAULT_I18N_PROVIDER = 'noop';
const DEFAULT_I18N_LOCALE = 'en';

const renderApp = async () => {
  await FeatureFlags.initializeClient(); // @todo catch failures and retry initializing LD client
  datadogRum.startSessionReplayRecording();

  // internationalization
  const i18nProvider = FeatureFlags.getFlag(
    'i18n-provider',
    DEFAULT_I18N_PROVIDER,
  );
  const i18nLocale = FeatureFlags.getFlag('i18n-locale', DEFAULT_I18N_LOCALE);
  setI18nProvider(i18nProvider);
  await setI18nLocale(i18nLocale);

  // maintenance
  if (UNDER_MAINTENANCE) {
    createRoot(document.getElementById('app')).render(<Maintenance />);
    return;
  }

  // ignore certain subdomains
  if (hasSubdomainMatch(ignoredDomains)) {
    window.location.replace('https://www.kandji.io/');
    return;
  }

  // check sub-domain existing
  checkSubDomainExists(window.location)
    .then(() => {
      const parsed = queryString.parse(window.location.search);

      const authToken = get(parsed, 'authToken');
      const companyAccessToken = get(parsed, 'cat');
      if (authToken || companyAccessToken) {
        let token;
        if (authToken) {
          token = authToken;
        } else if (companyAccessToken) {
          token = `cat-${companyAccessToken}`;
        }
        setAuthToken(token);
        window.history.replaceState({}, '', '/');
      }

      const demoMode = get(parsed, 'demo');
      if (demoMode) {
        if (demoMode === '1') {
          localStorage.setItem(DEMO_KEY_NAME, 'demo');
        } else {
          localStorage.removeItem(DEMO_KEY_NAME);
        }
        window.history.replaceState({}, '', '/');
      }

      createRoot(document.getElementById('app')).render(<App />);
    })
    .catch((e) => {
      if (
        !constants.REACT_APP_DEBUG &&
        e.response.data.error === 'tenantNotFound'
      ) {
        let page404;
        // all production environments use default domain 404
        if (['PRD', 'PRD-EU'].includes(constants.REACT_APP_ENV_NAME)) {
          page404 = 'https://kandji.io/404';
        } else {
          page404 = `${window.location.protocol}//${constants.REACT_APP_BASE_DOMAIN}/404`;
        }
        window.location.replace(page404);
      } else if (e.response.data.error === 'tenantMaintenanceMode') {
        createRoot(document.getElementById('app')).render(<Maintenance />);
      }
      console.error('Could not ping remote URL', e);
    });
};

renderApp();
