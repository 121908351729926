import {
  Chip,
  Icon,
  Menu,
  MultiSelectSearch,
  TextInput,
  Toggle,
  onEmptyBlurValidator,
  setClass,
} from '@kandji-inc/bumblebee';
import {
  Badge,
  Box,
  Flex,
  Heading,
  Chip as NectarChip,
  Icon as NectarIcon,
  Text,
} from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { BlueprintConflictsModal } from 'features/library-items/common/blueprint-conflicts';
import { ConfirmNavigationModal } from 'features/library-items/common/library-item-page/confirm-navigation-modal';
import { KandjiSetupCtx } from 'features/library-items/items/kandji-setup/kandji-setup.context';
import { MENU_THEME_CLASSES } from 'features/library-items/template/summary/constants';
import { Ellipsis } from 'features/library-items/template/summary/icons';

import kandjiSetupIcon from './ks-library.png';

const Summary = (props) => {
  const {
    style,
    className,
    model,
    setModel,
    blueprintOptions,
    blueprintConflicts,
    quickActions,
  } = props;

  const { isEditingPage, isDisabled, setGlobalValidations } =
    useContext(KandjiSetupCtx);

  const history = useHistory();

  const [isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen] =
    useState(false);

  const onConfirmNavModalClose = useCallback(
    () => setIsConfirmNavigationModalOpen(false),
    [],
  );
  const onConfirmNavModalLeave = useCallback(
    () => history.push('/blueprints'),
    [],
  );

  const onSelectBlueprint = (selectedBlueprints) => {
    setModel((p) => ({
      ...p,
      blueprints: selectedBlueprints,
    }));
  };

  const setName = (name) => setModel((p) => ({ ...p, name }));
  const setIsActive = () => setModel((p) => ({ ...p, isActive: !p.isActive }));

  const validator = (v) => {
    const r1 = {
      message: 'Title is too long',
      invalid: () => v.length > 40,
    };
    const r2 = onEmptyBlurValidator(v, { message: 'A title is required' });
    return [r1, r2];
  };

  const onInvalidate = (invalid) => {
    setGlobalValidations((p) => ({ ...p, libraryTitle: invalid }));
  };

  const menuList = quickActions?.map((action) => (
    <button
      type="button"
      key={action.name}
      className={setClass(
        action.theme ? `${MENU_THEME_CLASSES[action.theme]}` : '',
      )}
      onClick={action.onClick}
      disabled={action.isDisabled}
    >
      <Icon name={action.icon} className="b-mr-tiny" />
      {action.name}
    </button>
  ));

  const allBlueprintOptions = blueprintOptions || [];
  const { form: classicBlueprints, flow: flowBlueprints } =
    allBlueprintOptions.reduce(
      (a, c) => {
        if (c.type === 'form') {
          return {
            ...a,
            form: [...a.form, c],
          };
        }

        return {
          ...a,
          flow: [...a.flow, c],
        };
      },
      {
        form: [],
        flow: [],
      },
    );
  const modelClassicBlueprints = model.blueprints.filter(
    ({ type }) => type === 'form',
  );
  const selectedAssignmentMaps = model.blueprints.filter(
    ({ type }) => type === 'flow',
  );

  return (
    <div style={style} className={setClass(['b-library-summary', className])}>
      <div className="b-library-summary__header">
        <img src={kandjiSetupIcon} alt="Liftoff" />
        <div className="b-library-summary__input">
          {isEditingPage ? (
            <TextInput
              kind="library"
              id="liftoff-title"
              validator={validator}
              onInvalidate={onInvalidate}
              placeholder="Add a title"
              disabled={isDisabled}
              value={model.name}
              onChange={(e) => setName(e.target.value)}
            />
          ) : (
            <div className="b-flex-row static-header">
              <h1 className="b-h1">{model.name}</h1>

              {/* The quick actions menu should only appear in the view state */}
              {quickActions?.length > 0 && (
                <Menu
                  className="b-ml"
                  positionStyle={{ bottom: { top: '30px' } }}
                >
                  <div>{menuList}</div>

                  <button aria-label="quick-actions-menu" type="button">
                    <Ellipsis />
                  </button>
                </Menu>
              )}
            </div>
          )}
        </div>

        <div className="b-library-summary__status">
          <Chip
            className={setClass([
              'b-txt-ctrl8',
              isDisabled ? 'b-chip-active-md--disabled' : '',
            ])}
            kind={model.isActive ? 'active' : 'inactive'}
            text={model.isActive ? 'Active' : 'Inactive'}
          />
          <Toggle
            disabled={isDisabled}
            checked={model.isActive}
            onToggle={setIsActive}
          />
        </div>
      </div>
      <div className="b-library-summary__body">
        <div className="b-library-summary__info">
          <div>
            <p className="b-txt-bold b-mb">Liftoff</p>
            <p className="b-txt">
              Create a customized onboarding experience for your users when they
              enroll in Kandji and their devices are initially configured. Users
              can optionally be locked into a full-screen experience.&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.kandji.io/support/solutions/articles/72000558712"
                className="b-alink"
              >
                Learn more...
              </a>
            </p>
          </div>
          <div className="b-library-summary__publisher">
            <div>
              <p className="b-txt-light">Publisher:</p>
              <p className="b-txt">Kandji, Inc.</p>
            </div>
            <div>
              <p className="b-txt-light">Device families:</p>
              <Chip kind="secondary" text="Mac" />
            </div>
            <div>
              <p className="b-txt-light">OS requirement:</p>
              <p className="b-txt">macOS 10.14+</p>
            </div>
          </div>
        </div>
        <div className="b-library-summary__assign">
          <h3 className="b-h3">Assignment</h3>
          <div className="b-library-summary__assign-body">
            <Flex flow="column">
              <Flex flow="column" gap="lg" css={{ marginBottom: '$2' }}>
                <Flex flow="row" alignItems="center">
                  <NectarIcon name="sitemap" size="sm" />
                  <Heading
                    size="5"
                    css={{
                      marginLeft: '$1',
                      marginRight: '$3',
                      fontWeight: '$medium',
                    }}
                  >
                    Assignment Maps
                  </Heading>

                  <Badge color="blue" icon="sparkles">
                    New
                  </Badge>
                </Flex>

                {!selectedAssignmentMaps.length && (
                  <Flex flow="row" alignItems="end" gap="sm">
                    <Text
                      css={{
                        color: '$neutral70',
                      }}
                    >
                      Not used in any Assignment Maps.
                    </Text>

                    <a
                      href="https://support.kandji.io/support/solutions/articles/72000627625"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Text
                        variant="primary"
                        size="1"
                        css={{
                          fontWeight: '$medium',
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        Learn more
                      </Text>
                    </a>
                  </Flex>
                )}

                {selectedAssignmentMaps.length > 0 && (
                  <Flex flow="row" alignItems="start">
                    <Text
                      css={{
                        minWidth: '105px',
                        marginRight: '$4',
                        paddingTop: '2px',
                      }}
                    >
                      Blueprint
                    </Text>

                    <Flex gap="sm" wrap="wrap">
                      {selectedAssignmentMaps.map(({ label }) => (
                        <NectarChip
                          label={label}
                          key={label}
                          css={{
                            minWidth: 0,
                          }}
                        />
                      ))}
                    </Flex>
                  </Flex>
                )}

                <Box
                  wFull
                  css={{
                    backgroundColor: '$neutral30',
                    height: '1px',
                  }}
                />

                <Flex flow="row" alignItems="center">
                  <Icon name="memo-pad" size="sm" />
                  <Heading
                    size="5"
                    css={{
                      marginLeft: '$1',
                      marginRight: '$3',
                      fontWeight: '$medium',
                    }}
                  >
                    Classic Blueprints
                  </Heading>
                </Flex>
              </Flex>

              <div className="b-library-summary__assign-row">
                <p className="b-txt">Blueprint</p>
                <MultiSelectSearch
                  addNewEnabled
                  auto
                  options={
                    classicBlueprints
                      ? classicBlueprints.sort((a, b) =>
                          a.label.localeCompare(b.label),
                        )
                      : []
                  }
                  onChange={onSelectBlueprint}
                  values={modelClassicBlueprints}
                  disabled={!classicBlueprints || isDisabled}
                  onAddNew={() => setIsConfirmNavigationModalOpen(true)}
                />
              </div>
            </Flex>
          </div>
        </div>
      </div>
      <BlueprintConflictsModal
        blueprintConflicts={blueprintConflicts}
        blueprints={model.blueprints}
        itemId={model.id}
        itemName={model.name}
      />
      {isConfirmNavigationModalOpen && (
        <ConfirmNavigationModal
          onClose={onConfirmNavModalClose}
          onLeave={onConfirmNavModalLeave}
        />
      )}
    </div>
  );
};

export default Summary;
