import { Box, Flex, Icon, Text, Tooltip, styled } from '@kandji-inc/nectar-ui';
import { Resizable } from 're-resizable';
// istanbul ignore file
import * as React from 'react';

const NavIcon = styled(Icon, {
  minHeight: 20,
  maxHeight: 20,
  minWidth: 20,
  maxWidth: 20,
});

const NavItem = styled(Flex, {
  alignItems: 'center',
  cursor: 'pointer',
  padding: '$2',
  gap: '$1',
  height: 40,
  borderRadius: '$1',
  '&:hover': {
    backgroundColor: '$neutral05',
  },
  [`& ${Text}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '&[data-selected=true]': {
    backgroundColor: '$blue05',
    color: '$blue50',
    [`& ${Text}`]: {
      fontWeight: '$medium',
    },
    [`& ${NavIcon}`]: {
      color: '$blue50',
    },
  },
});

const ExpandHandle = styled(Box, {
  position: 'absolute',
  top: '50%',
  right: 0,
  marginRight: '-14px',
  cursor: 'pointer',
  zIndex: 3,
  '&:hover': {
    backgroundColor: '$neutral20',
  },
  padding: '$1',
  borderRadius: '$1',
  boxShadow: '$elevation1',
  backgroundColor: '$neutral00',
  display: 'none',
});

export const PrismNavList = styled(Flex, {
  flexDirection: 'column',
  overflowX: 'hidden',
  gap: '$1',
  borderRight: '1px solid $neutral30',
  pr: '$5',
  height: 'calc(100vh - 128px)',
  maxHeight: '100%',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '$1',
  },
  '&:hover': {
    '&::-webkit-scrollbar-track': {
      background: 'rgba(243, 247, 250)',
      borderRadius: '$1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(80, 94, 113, 0.24)',
      borderRadius: '$1',
      height: '50px',
    },
  },
});

const INITIAL_WIDTH = 200;
const MIN_WIDTH = 60;
const MAX_WIDTH = 400;

export const ViewsNav = ({
  views,
  viewId,
  collapsed,
  onSelectView,
}: {
  views: { id: string; name: string }[];
  viewId: string | null;
  collapsed: boolean;
  onSelectView: (view: { id: string; name: string }) => void;
}) => {
  const expanderRef = React.useRef<HTMLDivElement>(null);
  const navListRef = React.useRef<HTMLDivElement>(null);
  const resizedWidth = React.useRef(collapsed ? MIN_WIDTH : INITIAL_WIDTH);

  const [width, setWidth] = React.useState(
    collapsed ? MIN_WIDTH : INITIAL_WIDTH,
  );
  const [userCollapsed, setUserCollapsed] = React.useState(collapsed);

  React.useEffect(() => {
    setUserCollapsed(collapsed);
  }, [collapsed]);

  React.useEffect(() => {
    setWidth(userCollapsed ? 60 : resizedWidth.current);
  }, [userCollapsed]);

  return (
    <Resizable
      maxWidth={MAX_WIDTH}
      minWidth={MIN_WIDTH}
      size={{ width }}
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onResize={(_e, _dir, ref, _d) => {
        const userCollapsed = ref.getBoundingClientRect().width === MIN_WIDTH;
        setWidth(ref.getBoundingClientRect().width);
        setUserCollapsed(userCollapsed);
        resizedWidth.current = userCollapsed
          ? INITIAL_WIDTH
          : ref.getBoundingClientRect().width;
      }}
      onResizeStart={() => {
        if (navListRef.current) {
          navListRef.current.style['border-right'] =
            '1px solid var(--colors-blue50)';
        }
      }}
      onResizeStop={() => {
        if (navListRef.current) {
          navListRef.current.style['border-right'] =
            '1px solid var(--colors-neutral30)';
        }
      }}
      onMouseOver={() => {
        if (expanderRef.current) {
          expanderRef.current.style.display = 'block';
        }
      }}
      onMouseLeave={() => {
        if (expanderRef.current) {
          expanderRef.current.style.display = 'none';
        }
      }}
    >
      <PrismNavList ref={navListRef}>
        {views.map((view, i) => {
          const viewName = view.id ? view.name : 'All devices';
          return (
            <NavItemContainer
              key={view.id}
              showTooltip={userCollapsed}
              tooltipContent={viewName}
            >
              <NavItem
                onClick={() => onSelectView(view)}
                data-selected={viewId === view.id}
              >
                <NavIcon name="devices" />
                {!userCollapsed && <Text>{viewName}</Text>}
              </NavItem>
            </NavItemContainer>
          );
        })}
      </PrismNavList>
      <ExpandHandle
        ref={expanderRef}
        onClick={() => {
          setUserCollapsed(!userCollapsed);
        }}
      >
        <NavIcon
          name={userCollapsed ? 'fa-angle-right-small' : 'fa-angle-left-small'}
        />
      </ExpandHandle>
    </Resizable>
  );
};

const NavItemContainer = ({
  children,
  showTooltip,
  tooltipContent,
}: {
  children: React.ReactNode;
  showTooltip: boolean;
  tooltipContent: string;
}) => {
  return showTooltip ? (
    <Tooltip content={tooltipContent} side="right" css={{ zIndex: 2 }}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
};
