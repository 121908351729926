import { Paragraph } from '@kandji-inc/nectar-ui';
import { useSidebarToast } from 'src/hooks/useSidebarToast';
import ActionDialog from '../../../../components/action-dialog';
import useDeleteDevicePlatform from '../../hooks/use-delete-device-platform';
import type { PlatformStatus } from '../details-integration/details-integration';
import { getPlatformLabel } from '../details-integration/details-integration';

type Props = {
  isDialogOpen: boolean;
  platform: PlatformStatus;
  integrationId: string;
  handleToggle: (args: any) => void;
};

const DeleteDevicePlatform = (props: Props) => {
  const { isDialogOpen, handleToggle, integrationId, platform } = props;

  const platformLabel = getPlatformLabel(platform);
  const { displayToast } = useSidebarToast();

  const deletePlatformMutation = useDeleteDevicePlatform(integrationId);
  const isDeleting = deletePlatformMutation.isPending;

  const handleDeletePlatform = () => {
    if (integrationId && platform) {
      deletePlatformMutation.mutate(platform, {
        onSuccess: () => {
          displayToast({
            title: `${platformLabel} device platform successfully deleted`,
            variant: 'success',
          });
        },
        onError: (error) => {
          const errorTitle =
            error?.message ||
            'Could not delete the device platform. Please try again.';
          displayToast({
            title: errorTitle,
            variant: 'error',
          });
        },
        onSettled: () => {
          handleToggle(null);
        },
      });
    }
  };

  const dialogContent = () => (
    <Paragraph>
      By removing this device platform from the integration, Microsoft Device
      Compliance cannot be deployed to {platformLabel} devices. This action
      cannot be undone.
    </Paragraph>
  );

  return (
    <ActionDialog
      isOpen={isDialogOpen}
      size="md"
      title={`Delete ${platformLabel} from Microsoft Device Compliance?`}
      content={dialogContent()}
      nextText="Delete"
      nextClick={handleDeletePlatform}
      backText="Cancel"
      backClick={() => handleToggle(null)}
      isProcessing={isDeleting}
    />
  );
};

export default DeleteDevicePlatform;
