import { useMutation, useQueryClient } from '@tanstack/react-query';
import MsDeviceComplianceService, {
  msDeviceComplianceService,
} from '../services';
import { msDeviceComplianceKeys } from './query-key-factory';

const useAddDevicePlatform = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ formInputs }) => {
      const platformGroupPolicies = {
        mac_os_devices_enabled:
          /* istanbul ignore next */ formInputs.macOsDevices || false,
        mac_os_group_ids: MsDeviceComplianceService.collectExternalIds(
          formInputs.macOsDevices_groups,
        ),
        ios_devices_enabled:
          /* istanbul ignore next */ formInputs.iosDevices || false,
        ios_group_ids: MsDeviceComplianceService.collectExternalIds(
          formInputs.iosDevices_groups,
        ),
      };

      await msDeviceComplianceService.setGroupPolicies(
        id,
        platformGroupPolicies,
      );
    },
    onSuccess: () => {
      // refetch the integrations from maint-backend whenever we delete this integration
      queryClient.invalidateQueries({
        queryKey: msDeviceComplianceKeys.integrationDetails(id),
      });
    },
  });
};

export default useAddDevicePlatform;
