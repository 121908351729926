import {
  Body,
  Button,
  Flex,
  Heading,
  Switch,
  styled,
} from '@kandji-inc/nectar-ui';
import type {
  QueryObserverResult,
  RefetchOptions,
} from '@tanstack/react-query';
import * as React from 'react';
import type { AuthBlueprint, AuthConnection } from './ManualEnrollment.types';
import { ManualEnrollmentBlueprintCard } from './ManualEnrollmentBlueprintCard';

const EnrollmentPortalContainer = styled('div', {
  border: '1px solid #D7E1ED',
  borderRadius: '$2',
  width: '100%',
});

const EnrollmentPortalHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '19px $5',
  borderBottom: '1px solid $neutral10',
});

const EnrollmentPortalDescription = styled('div', {
  padding: '$5',
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
});

const EnrollmentPortalLink = styled('div', {
  color: '$text_secondary',
});

const EnrollmentCodeAccess = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 10,
  padding: 24,
  borderTop: '1px solid $neutral10',
});

interface ManualEnrollmentPortalProps {
  enrollmentPortalActive: boolean;
  authBlueprints: AuthBlueprint[];
  authConnections: AuthConnection[];
  refetchBlueprints: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<AuthBlueprint[], Error>>;
  toggleEnrollmentPortalStatus: ({
    is_enrollment_portal_active,
  }: {
    is_enrollment_portal_active: boolean;
  }) => Promise<any>;
}

export function ManualEnrollmentPortal({
  enrollmentPortalActive,
  toggleEnrollmentPortalStatus,
  authBlueprints,
  authConnections,
  refetchBlueprints,
}: ManualEnrollmentPortalProps) {
  const enrollmentPortalLink = `${window.location.protocol}//${window.location.hostname}/enroll`;

  return (
    <EnrollmentPortalContainer>
      <EnrollmentPortalHeader>
        <Heading size={'4'}>Enrollment Portal</Heading>
        <Flex gap="sm" alignItems="center" css={{ marginLeft: 'auto' }}>
          {enrollmentPortalActive ? 'Active' : 'Inactive'}
          <Switch
            data-testid="toggle-portal-active"
            checked={enrollmentPortalActive}
            onClick={() =>
              toggleEnrollmentPortalStatus({
                is_enrollment_portal_active: !enrollmentPortalActive,
              })
            }
          />
        </Flex>
      </EnrollmentPortalHeader>
      <EnrollmentPortalDescription>
        <Body css={{ marginBottom: 'unset' }}>
          The Enrollment Portal allows your organization’s users to self-enroll
          their devices into Kandji. You may choose to allow use of the
          Enrollment Portal by turning it on or off.
        </Body>
        <EnrollmentPortalLink>
          Enrollment Portal link{' '}
          <Button
            variant={'link'}
            css={{ background: 'none' }}
            onClick={() => window.open(enrollmentPortalLink, '_blank')}
          >
            {enrollmentPortalLink}
          </Button>
        </EnrollmentPortalLink>
      </EnrollmentPortalDescription>
      {enrollmentPortalActive && (
        <EnrollmentCodeAccess>
          <Heading size={'5'} css={{ marginLeft: 0 }}>
            Enrollment code access
          </Heading>
          <Body css={{ marginBottom: 'unset' }}>
            The Enrollment Portal uses Blueprint-specific codes to determine
            which Blueprint will be used for enrolling a device. You may
            generate new codes, or choose to disable enrollment access for
            certain Blueprints.
          </Body>
          <Flex gap="lg" flow="column" css={{ marginTop: '$6', width: '100%' }}>
            {authBlueprints.map((blueprint) => (
              <ManualEnrollmentBlueprintCard
                key={blueprint.id}
                blueprint={blueprint}
                authConnections={authConnections}
                refetchBlueprints={refetchBlueprints}
              />
            ))}
          </Flex>
        </EnrollmentCodeAccess>
      )}
    </EnrollmentPortalContainer>
  );
}
