import {
  Chip as BumblebeeChip,
  MultiSelectSearch,
  Toggle,
  setClass,
  useOnChange,
} from '@kandji-inc/bumblebee';
import {
  array,
  bool,
  element,
  func,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import './scss/assign.scss';

import {
  Badge,
  Box,
  Chip,
  Flex,
  Heading,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';

import { blueprintTypes } from 'src/app/common/constants';
import HoverTippy from 'src/features/util/components/hover-tippy/hover-tippy';

import { filterArrayFromArray } from '../../common/library-item-page/helpers';
import CircleQuestion from './assets/circle-question.svg';

const Assign = (props: any) => {
  const { style, className, children, component: Component } = props;

  if (Component) {
    return <Component {...props} />;
  }

  return (
    <div
      style={style}
      className={setClass(className, 'b-library-summary__assign')}
    >
      <h3 className="b-h3 b-library-summary__assign-title">Assignment</h3>

      <div className="b-library-summary__assign-body">{children}</div>
    </div>
  );
};

Assign.propTypes = {
  style: object,
  className: string,
  children: oneOfType([node, element]),
  component: oneOfType([node, element]),
};

Assign.defaultProps = {
  style: {},
  className: null,
  children: null,
  component: null,
};

const AssignBlueprints = (props: any) => {
  const {
    style,
    className,
    blueprints,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints,
    onChange,
    isDisabled,
    isEditing,
    onClose,
    onAddNew,
  } = props;

  const [localModel, setLocalModel] = useState({
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints,
  });

  const filterOutAssignmentMaps = (bpList) =>
    bpList.filter(({ type }) => type === blueprintTypes.form);
  const filterOutClassicBlueprints = (bpList) =>
    bpList.filter(({ type }) => type === blueprintTypes.flow);

  const selectedAssignmentMaps = filterOutClassicBlueprints(
    localModel.selectedBlueprints,
  ).sort(
    /* istanbul ignore next */ (a, b) =>
      a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1,
  );
  const selectedClassicBlueprints = filterOutAssignmentMaps(
    localModel.selectedBlueprints,
  );
  const classicBlueprints = filterOutAssignmentMaps(blueprints);

  const [editedModelWithAllBlueprintsOn, setEditedModelWithAllBlueprintsOn] =
    useState(null);
  const [editedModelWithAllBlueprintsOff, setEditedModelWithAllBlueprintsOff] =
    useState(null);

  // Subscribe to new data passed from the parent component to update the `localModel`.
  // This updates the component upon save or cancel.
  useEffect(() => {
    setLocalModel({
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints,
    });
  }, [selectedBlueprints, excludedBlueprints, isAllBlueprints]);

  // Reset the edited models when the user leaves edit state
  useEffect(() => {
    if (!isEditing) {
      setEditedModelWithAllBlueprintsOn(null);
      setEditedModelWithAllBlueprintsOff(null);
    }
  }, [isEditing]);

  // If the "All Blueprints" toggle is ON, always show the "Exclude Blueprints" dropdown while
  // in EDIT mode, but only show it while in VIEW mode if there is at least one Blueprint selected
  // to be excluded.
  const showExcludeBlueprints =
    localModel.isAllBlueprints && (isEditing || excludedBlueprints.length > 0);

  const handleAllBlueprintsToggle = (toggleVal: boolean) => {
    if (toggleVal) {
      // Save the edited "off" state before switching the toggle
      setEditedModelWithAllBlueprintsOff(localModel);

      // If there was a previously edited "on" state, populate the dropdowns
      // with that data. Otherwise, manually select all blueprints.
      if (editedModelWithAllBlueprintsOn) {
        setLocalModel(editedModelWithAllBlueprintsOn);
      } else {
        setLocalModel({
          isAllBlueprints: true,
          selectedBlueprints: [...classicBlueprints, ...selectedAssignmentMaps], // Manually select all Classic Blueprints
          excludedBlueprints: [], // Reset excluded blueprints
        });
      }
    } else {
      // Save the edited "on" state before switching the toggle
      setEditedModelWithAllBlueprintsOn(localModel);

      // If there was a previously edited "off" state, populate the dropdowns
      // with that data. Otherwise, manually select all blueprints that were not excluded.
      if (editedModelWithAllBlueprintsOff) {
        setLocalModel(editedModelWithAllBlueprintsOff);
      } else {
        setLocalModel((prev) => ({
          ...prev,
          isAllBlueprints: false,
          selectedBlueprints: [
            ...filterArrayFromArray(classicBlueprints, excludedBlueprints),
            ...selectedAssignmentMaps,
          ],
          excludedBlueprints: [],
        }));
      }
    }
  };

  const handleSelectedBlueprintChange = (newSelectedBlueprints: any) => {
    const allSelectedBlueprints = [
      ...newSelectedBlueprints,
      ...selectedAssignmentMaps,
    ];
    setLocalModel((prev) => ({
      ...prev,
      selectedBlueprints: allSelectedBlueprints,
      // Update excludedBlueprints to include everything except what is now selected
      excludedBlueprints: filterArrayFromArray(
        blueprints,
        allSelectedBlueprints,
      ),
    }));
  };

  const handleExcludedBlueprintChange = (newExcludedBlueprints: any) =>
    setLocalModel((prev) => ({
      ...prev,
      // Update selectedBlueprints to include everything except what is now excluded
      selectedBlueprints: filterArrayFromArray(
        [...classicBlueprints, ...selectedAssignmentMaps],
        newExcludedBlueprints,
      ),
      excludedBlueprints: newExcludedBlueprints,
    }));

  const allBlueprintsChip = () => (
    <span className="b-library-summary__all-blueprints">
      <BumblebeeChip
        kind="info"
        text="All Classic Blueprints"
        iconLeft="kandji-blueprint"
      />
    </span>
  );

  const allBlueprintsToggle = (
    <div className="b-flex">
      <Toggle
        checked={localModel.isAllBlueprints}
        onToggle={handleAllBlueprintsToggle}
      />
      <p className="b-txt b-ml-tiny b-mr-tiny">All Classic Blueprints</p>
      <HoverTippy
        text="When a new Classic Blueprint is created, this Library Item will automatically be added."
        icon={CircleQuestion}
        maxWidth="none"
      />
    </div>
  );

  useOnChange(onChange, localModel);

  return (
    <Flex flow="column">
      <Flex flow="column" gap="lg" css={{ marginBottom: '$2' }}>
        <Flex flow="row" alignItems="center">
          <Icon name="sitemap" size="sm" />
          <Heading
            size="5"
            css={{
              marginLeft: '$1',
              marginRight: '$3',
              fontWeight: '$medium',
            }}
          >
            Assignment Maps
          </Heading>

          <Badge color="blue" icon="sparkles">
            New
          </Badge>
        </Flex>

        {!selectedAssignmentMaps.length && (
          <Flex flow="row" alignItems="end" gap="sm">
            <Text
              css={{
                color: '$neutral70',
              }}
            >
              Not used in any Assignment Maps.
            </Text>

            <a
              href="https://support.kandji.io/support/solutions/articles/72000627625"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Text
                variant="primary"
                size="1"
                css={{
                  fontWeight: '$medium',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Learn more
              </Text>
            </a>
          </Flex>
        )}

        {selectedAssignmentMaps.length > 0 && (
          <Flex flow="row" alignItems="start">
            <Text
              css={{
                minWidth: '105px',
                marginRight: '$4',
                paddingTop: '2px',
              }}
            >
              Blueprint
            </Text>

            <Flex gap="sm" wrap="wrap">
              {selectedAssignmentMaps.map(({ label }) => (
                <Chip
                  label={label}
                  key={label}
                  css={{
                    minWidth: 0,
                  }}
                />
              ))}
            </Flex>
          </Flex>
        )}

        <Box
          wFull
          css={{
            backgroundColor: '$neutral30',
            height: '1px',
          }}
        />

        <Flex flow="row" alignItems="center">
          <Icon name="memo-pad" size="sm" />
          <Heading
            size="5"
            css={{
              marginLeft: '$1',
              marginRight: '$3',
              fontWeight: '$medium',
            }}
          >
            Classic Blueprints
          </Heading>
        </Flex>
      </Flex>

      <div
        style={style}
        className={setClass('b-library-summary__assign-row', className)}
      >
        <p className="b-txt">Blueprint</p>

        <MultiSelectSearch
          placeholder="Select Blueprint"
          addNewEnabled
          auto
          options={classicBlueprints}
          onChange={handleSelectedBlueprintChange}
          onClose={onClose}
          values={selectedClassicBlueprints}
          disabled={isDisabled}
          onAddNew={onAddNew}
          hasSelectAll
          displayCustomValue={
            localModel.isAllBlueprints ? allBlueprintsChip : null
          }
          topControl={allBlueprintsToggle}
          isMenuListHidden={localModel.isAllBlueprints}
        />
      </div>

      {showExcludeBlueprints && (
        <div
          style={style}
          className={setClass(
            'b-library-summary__assign-row k-exclude-blueprints',
            className,
          )}
        >
          <div className="b-library-summary__assign-row-spacer" />

          <div className="b-library-summary__assign-row-wrapper b-mt">
            <p className="b-txt">Excluding (optional)</p>

            <MultiSelectSearch
              placeholder="Select Blueprints to exclude"
              auto
              options={classicBlueprints}
              hasSelectAll
              /* istanbul ignore next */
              onChange={handleExcludedBlueprintChange}
              onClose={onClose}
              values={localModel.excludedBlueprints}
              disabled={isDisabled}
            />
          </div>
        </div>
      )}
    </Flex>
  );
};

AssignBlueprints.propTypes = {
  style: object,
  className: string,
  selectedBlueprints: array,
  excludedBlueprints: array,
  isAllBlueprints: bool,
  blueprints: array,
  onChange: func,
  isDisabled: bool,
  isEditing: bool,
  onAddNew: func,
  onClose: func,
};

AssignBlueprints.defaultProps = {
  style: {},
  className: null,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  blueprints: null,
  onChange: () => {},
  isDisabled: false,
  isEditing: true,
  onAddNew: () => {},
  onClose: () => {},
};

const AssignDevices = (props: any) => {
  const {
    style,
    className,
    devices,
    selectedDevices,
    baseDeviceFamilies,
    onChange,
    isDisabled,
    onClose,
    // component: Component,
  } = props;

  // if (Component) {
  //   return <Component {...props} />;
  // }

  const updateSelectedModelsForBaseDeviceFamilies = (selectedValues: any[]) =>
    selectedValues.map((device: any) => ({
      value: device.value,
      label: !baseDeviceFamilies.includes(device.value)
        ? device.value
        : device.label,
    }));

  const [localModel, setLocalModel] = useState({
    selectedDevices,
  });

  // Subscribe to any new `selectedDevices` passed from the parent component to update the `localModel` with latest selected devices
  useEffect(() => {
    setLocalModel({
      selectedDevices,
    });
  }, [selectedDevices]);

  useOnChange(onChange, localModel);

  return (
    <div
      id="install_on_selector"
      style={style}
      className={setClass('b-library-summary__assign-row', className)}
    >
      <p className="b-txt">Install on</p>

      <MultiSelectSearch
        placeholder="Select device families"
        searchPlaceholder="Filter device families"
        auto
        options={devices}
        onChange={(v: any) =>
          setLocalModel({
            selectedDevices: updateSelectedModelsForBaseDeviceFamilies(v),
          })
        }
        onClose={onClose}
        values={localModel.selectedDevices}
        disabled={isDisabled}
      />
    </div>
  );
};

AssignDevices.propTypes = {
  style: object,
  className: string,
  devices: array,
  selectedDevices: array,
  baseDeviceFamilies: array,
  onChange: func,
  isDisabled: bool,
  onClose: func,
};

AssignDevices.defaultProps = {
  style: {},
  className: null,
  devices: null,
  selectedDevices: [],
  baseDeviceFamilies: [],
  onChange: () => {},
  isDisabled: false,
  onClose: () => {},
};

export { Assign, AssignBlueprints, AssignDevices };
