import {
  integrationsDeviceTrustAxios,
  newIntegrationsAxios,
} from 'app/api/base';
import { INT_TYPES } from 'src/features/integrations/constants';
import type { DEVICE_PLATFORMS } from '../../ms-device-compliance/components/details-integration/details-integration';
import {
  type CompliancePolicies,
  compliancePoliciesSchema,
  integrationDetailsSchema,
  integrationRedirectUrlSchema,
  integrationUserGroupListSchema,
} from '../types/schema';

/**
 * This class handles the connections to integrations main backend and
 * integrations device trust backend services for MS Device Compliance.
 *
 * @class MsDeviceComplianceService
 */
class MsDeviceComplianceService {
  private mainBackendIntegrationsService: NonNullable<
    typeof newIntegrationsAxios
  >;

  private deviceTrustService: NonNullable<typeof integrationsDeviceTrustAxios>;

  public static collectExternalIds = (groups: string[] = []) =>
    groups
      ?.map((v) => v.split('|')[1])
      .filter((id: string | undefined): id is string => !!id);

  constructor() {
    // istanbul ignore if
    if (!newIntegrationsAxios) {
      throw new Error('Integrations main backend service API not found');
    }
    this.mainBackendIntegrationsService = newIntegrationsAxios;

    // istanbul ignore if
    if (!integrationsDeviceTrustAxios) {
      throw new Error(
        'Integrations device trust backend service API not found',
      );
    }
    this.deviceTrustService = integrationsDeviceTrustAxios;
  }

  // <== mainBackendIntegrationsService ==>

  async getAdminConsentRedirectUrl(data: string) {
    const resource = 'microsoft-compliance/admin-consent-redirect-url';
    const response = await this.mainBackendIntegrationsService(resource).post({
      ms_primary_domain: data,
    });
    return integrationRedirectUrlSchema.safeParse(response.data);
  }

  async deleteIntegration(id: string) {
    const resource = `microsoft-compliance/integration/${id}`;
    const response =
      await this.mainBackendIntegrationsService(resource).delete();
    return response;
  }

  async getIntegrationUserGroupList(searchTerm?: string, ids?: string[]) {
    // office365 is AZURE directory
    const filterByType = `integration_type__in=${INT_TYPES.office365},${INT_TYPES.scim}`;
    /* istanbul ignore next */
    const filterByExternalIds = ids?.length ? `&external_id__in=${ids}` : '';
    /* istanbul ignore next */
    const searchByName = searchTerm ? `&name=${searchTerm}` : '';

    const resource = `v1/user-integrations/groups?${filterByType}${filterByExternalIds}${searchByName}`;

    const response = await this.mainBackendIntegrationsService(resource).get();
    return integrationUserGroupListSchema.safeParse(response.data);
  }

  // <== deviceTrustService ==>

  async setGroupPolicies(id: string, data: CompliancePolicies) {
    const resource = `api/microsoft/v1/integrations/${id}/microsoft-group-policies`;
    const response = await this.deviceTrustService(resource).post({
      ...data,
    });
    return compliancePoliciesSchema.safeParse(response.data);
  }

  async deleteGroupPolicies(id: string, platform: DEVICE_PLATFORMS) {
    const resource = `api/microsoft/v1/integrations/${id}/microsoft-group-policies?device_type=${platform.toUpperCase()}`;
    const response = await this.deviceTrustService(resource).delete();
    return response;
  }

  async getIntegrationDetails(id: string) {
    const resource = `api/microsoft/v1/integrations/${id}`;
    const response = await this.deviceTrustService(resource).get();
    return integrationDetailsSchema.safeParse(response.data);
  }
}

const msDeviceComplianceService = new MsDeviceComplianceService();

export default MsDeviceComplianceService;
export { msDeviceComplianceService };
