/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { VulnerableDevice } from '../vulnerability.types';

const useGetVulnerabilityDevices = (
  vulnerabilityId: string,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerability', vulnerabilityId, ...keys],
    queryFn: () =>
      vulnerabilityApi(`vulnerabilities/${vulnerabilityId}/devices`).get(
        params,
      ),
  });

  const data: {
    devices: VulnerableDevice[];
    total: number;
    page: number;
    size: number;
  } = { ...apiRes?.data, devices: apiRes?.data.results };

  return { data, isLoading };
};

export { useGetVulnerabilityDevices };
