/* istanbul ignore file */
import { withCache } from 'components/common/hof/caching';
// Just a bunch of support functions for redux
/* istanbul ignore file */
import _cond from 'lodash/cond';
import _get from 'lodash/get';
import _stubTrue from 'lodash/stubTrue';
import { getAuth0Client } from 'src/auth0';
import { enrollmentAudience } from 'src/config';
import { metadata } from 'src/features/visibility/prism/types/prism.schema';
import {
  apiFetchCompany,
  apiFetchUser,
  isSAMLFederatedLogoutRequired,
} from '../api/account';
import {
  clearAuthToken,
  clearInactiveLogout,
  getTenantAuth0Credentials,
  hasAuthToken,
  setInactiveLogout,
} from '../auth';
import { adcsLinks, agentLinks, links } from '../common/constants';
import history from '../router/history';
import { account } from './action-types';

const fetchAuth0User = () => (dispatch) => {
  // kandji admins accessing company instances from thier admin session will not have an auth0 session
  if (hasAuthToken()) {
    return Promise.resolve();
  }
  return getAuth0Client()
    .getIdTokenClaims()
    .then((claims = { sub: null }) => {
      dispatch({
        type: account.ACCOUNT_AUTH0_USER,
        userId: claims.sub,
      });
    })
    .catch(() => Promise.resolve());
};

const fetchUserWithCheck = withCache(apiFetchUser);
const fetchCompanyWithCheck = withCache(apiFetchCompany);

const fetchUserState = () => (dispatch) =>
  fetchUserWithCheck
    .call()
    .then((data) =>
      dispatch({
        type: account.ACCOUNT_USER_FETCHED,
        user: data.user,
        tenantOverLicenseLimit: data.tenantOverLicenseLimit,
      }),
    )
    .catch(() => {
      history.push(links.signin);
      return Promise.reject(new Error('No user found'));
    });

export const fetchCompanyState = () => (dispatch) =>
  fetchCompanyWithCheck.call().then((company) =>
    dispatch({
      type: account.ACCOUNT_COMPANY_FETCHED,
      company,
    }),
  );

export const fetchAccount = () => (dispatch) => {
  dispatch({ type: account.ACCOUNT_FETCHING });
  return Promise.all([
    fetchUserState()(dispatch),
    fetchAuth0User()(dispatch),
    fetchCompanyState()(dispatch),
  ]).then(() => {
    fetchCompanyWithCheck.reset();
    fetchUserWithCheck.reset();
    dispatch({ type: account.ACCOUNT_FETCHED });
  });
};

export const updateUser = (updates) => ({
  type: account.ACCOUNT_USER_UPDATED,
  updates,
});

export const logout =
  (wasInactive = false) =>
  async (dispatch) => {
    dispatch({ type: account.ACCOUNT_LOGOUT });
    clearAuthToken();
    if (wasInactive) {
      setInactiveLogout();
    } else {
      clearInactiveLogout();
    }
    const claims = await getAuth0Client().getIdTokenClaims();
    const federated = await isSAMLFederatedLogoutRequired(claims);
    return getAuth0Client().logout({
      returnTo: `${window.location.origin}${links.signin}`,
      ...(federated && { federated }),
    });
  };

// Auth0 account actions

const decodeMetadata = (metadata = '') => {
  const decoded = atob(metadata);
  const fail = {};
  if (decoded) {
    try {
      return JSON.parse(decoded);
    } catch (_) {
      return fail;
    }
  }
  return fail;
};

const getAuth0ClientId = (o) =>
  _get(decodeMetadata(o), ['custom_web_view', 'sso', 'auth0_client_id'], '');

const getManualAuth0ClientId = (o) =>
  _get(decodeMetadata(o), ['auth0_configurations'], '');

const hasAuth0ClientId = ({ metadata = '' }) => !!getAuth0ClientId(metadata);

const isManualEnrollmentAuth = ({ metadata = '' }) =>
  !!getManualAuth0ClientId(metadata);

const isAgentRoute = ({ path }) => Object.values(agentLinks).includes(path);

const isADCSRoute = ({ path }) => Object.values(adcsLinks).includes(path);

const hasSubdomain = ({ subdomain }) => subdomain;

const isADCSApp = ({ subdomain, path }) =>
  hasSubdomain({ subdomain }) && isADCSRoute({ path });

const isWebapp = ({ subdomain, path }) =>
  hasSubdomain({ subdomain }) &&
  !isAgentRoute({ path }) &&
  !isADCSRoute({ path });

const setTenantAuth0Credentials = ({ subdomain, dispatch }) =>
  getTenantAuth0Credentials(subdomain)
    .then((data) =>
      dispatch({
        type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
        clientId: data.client_id,
        organizationId: data.organization_id,
        audience: data.audience,
        error: false,
      }),
    )
    .catch(() => {
      dispatch({
        type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
        clientId: null,
        organizationId: null,
        audience: null,
        error: true,
      });
    });

export const setADCSAuth0Credentials = ({
  subdomain,
  dispatch,
  metadata: encodedMetadata,
}) => {
  const metadata = decodeMetadata(encodedMetadata);
  return getTenantAuth0Credentials(subdomain)
    .then((data) =>
      dispatch({
        type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
        clientId: data.client_id,
        organizationId: data.organization_id,
        audience: data.audience,
        error: false,
        metadata,
      }),
    )
    .catch(() =>
      dispatch({
        type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
        clientId: null,
        organizationId: null,
        audience: null,
        error: true,
        metadata,
      }),
    );
};

const setEnrollmentAuth0Credentials = ({ dispatch, metadata }) => {
  const enrollmentData = decodeMetadata(metadata);
  const callbackUrl = _get(enrollmentData, 'callback_url', '');
  const tempDeviceId = callbackUrl?.substring(
    callbackUrl?.lastIndexOf('/') + 1,
  );

  dispatch({
    type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
    clientId: _get(enrollmentData, [
      'custom_web_view',
      'sso',
      'auth0_client_id',
    ]),
    clientDomain: _get(enrollmentData, ['client_domain']),
    connectionName: _get(enrollmentData, [
      'custom_web_view',
      'sso',
      'auth0_connection_name',
    ]),
    audience: enrollmentAudience,
    error: false,
    callbackUrl,
    configureUrl: _get(enrollmentData, 'configure_url', ''),
    enablePendo: _get(enrollmentData, 'enable_pendo'),
    tempDeviceId,
    metadata,
  });
};

const setManualEnrollmentAuth0Credentials = ({ dispatch, metadata }) => {
  const enrollmentData = decodeMetadata(metadata);
  const tempDeviceId = _get(enrollmentData, 'temp_computer');
  const auth0Config = _get(enrollmentData, 'auth0_configurations')[0];
  const code = _get(enrollmentData, 'enrollment_code');
  const callbackUrl = `${window.location.origin}/enroll/access-code/${code}`;
  const manualAuthData = {
    type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
    clientId: _get(auth0Config, 'auth0_client_id'),
    clientDomain: _get(enrollmentData, ['client_domain']),
    connectionName: _get(auth0Config, 'auth0_connection_name'),
    audience: enrollmentAudience,
    error: false,
    callbackUrl,
    tempDeviceId,
    enablePendo: _get(enrollmentData, 'enable_instrumentation'),
    isManualAuth: true,
    metadata,
  };
  dispatch(manualAuthData);
};

const setAuth0Error = ({ dispatch }) =>
  dispatch({
    type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
    clientId: null,
    audience: null,
    error: true,
  });

export const fetchAuth0Credentials =
  ({ subdomain, metadata, path }) =>
  (dispatch) =>
    _cond([
      [hasAuth0ClientId, setEnrollmentAuth0Credentials],
      [isManualEnrollmentAuth, setManualEnrollmentAuth0Credentials],
      [isADCSApp, setADCSAuth0Credentials],
      [isWebapp, setTenantAuth0Credentials],
      [_stubTrue, setAuth0Error],
    ])({
      subdomain,
      metadata,
      dispatch,
      path,
    });
