// src/index.tsx
import {
  Transform,
  Field,
  FieldArray,
  useFieldArray,
  FieldHTMLAttributes,
  Form,
  HoneyForm,
  useForm as useForm3,
  useField as useField2,
  useFieldValue,
  registerField as registerField9,
  Validation,
  ValidationTemplates
} from "react-honey-form";

// src/NFCheckbox.tsx
import {
  Checkbox,
  Flex,
  Hint,
  Label
} from "@kandji-inc/nectar-ui";
import { registerField } from "react-honey-form";
import { jsx, jsxs } from "react/jsx-runtime";
var NFCheckbox = ({
  name,
  title,
  value = "on",
  defaultChecked = false,
  touchOnChange = true,
  validateOnChange = true,
  fieldRef,
  validation,
  validationDebounce,
  monitorValidation,
  ...uiProps
}) => {
  const field = registerField(name, {
    defaultValue: defaultChecked ? value : "",
    fieldRef,
    validation,
    validationDebounce,
    monitorValidation
  });
  return /* @__PURE__ */ jsxs(Flex, {
    flow: "column",
    gap: "xs",
    children: [
      title && /* @__PURE__ */ jsx(Label, {
        above: true,
        children: title
      }),
      /* @__PURE__ */ jsx(Checkbox, {
        ...uiProps,
        ref: field.getRef(),
        checked: field.getValue() === value,
        onCheckedChange: (checked) => {
          touchOnChange && field.setTouched(true);
          field.setValue(checked === true ? value : "");
          validateOnChange && field.queueValidation();
        },
        "aria-invalid": field.hasError()
      }),
      field.hasError() && /* @__PURE__ */ jsx(Hint, {
        variant: "error",
        label: field.getErrorOrNull()
      })
    ]
  });
};

// src/NFMultiSelect.tsx
import { MultiSelect } from "@kandji-inc/nectar-ui";
import { registerField as registerField2 } from "react-honey-form";
import { jsx as jsx2 } from "react/jsx-runtime";
var NFMultiSelect = ({
  name,
  selectAllLabel,
  touchOnChange = true,
  validateOnChange = true,
  defaultValue,
  fieldRef,
  validation,
  validationDebounce,
  monitorValidation,
  ...uiProps
}) => {
  const field = registerField2(name, {
    defaultValue,
    fieldRef,
    validation,
    validationDebounce,
    monitorValidation
  });
  const stringValue = field.getValue() || "[]";
  const valueArray = JSON.parse(stringValue);
  return /* @__PURE__ */ jsx2(MultiSelect, {
    ...uiProps,
    ref: field.getRef(),
    "data-testid": "multi-select",
    searchable: true,
    value: valueArray,
    onChange: (newVals) => {
      touchOnChange && field.setTouched(true);
      field.setValue(JSON.stringify(newVals));
      validateOnChange && field.queueValidation();
    },
    selectAll: {
      valueLabel: selectAllLabel || "Select all"
    },
    footer: {
      showClear: true,
      clearLabel: "Clear",
      handleClear: () => field.setValue(JSON.stringify([]))
    },
    error: field.getErrorOrNull() || "",
    "aria-invalid": field.hasError(),
    componentCss: {
      trigger: {
        ...field.getErrorOrNull() ? {} : { border: "1px solid rgba(53, 65, 78, 0.24)" }
      }
    }
  });
};

// src/NFMultiTextField.tsx
import {
  Chip,
  Flex as Flex2,
  Hint as Hint2,
  Icon,
  Label as Label2,
  styled
} from "@kandji-inc/nectar-ui";

// ../nectar-utils/dist/index.mjs
import * as React from "react";
import * as React2 from "react";
import { useEffect as useEffect2 } from "react";
function useOnOutsideClick(ref, handler, ignoreRefs, mouseEvent = "mousedown") {
  useEffect2(() => {
    const f = (event) => {
      const el = ref == null ? void 0 : ref.current;
      if (!el || el.contains(event.target) || (ignoreRefs == null ? void 0 : ignoreRefs.some(
        (iRef) => {
          var _a;
          return (_a = iRef == null ? void 0 : iRef.current) == null ? void 0 : _a.contains(event.target);
        }
      ))) {
        return;
      }
      handler(event);
    };
    window.addEventListener(mouseEvent, f);
    return () => window.removeEventListener(mouseEvent, f);
  }, []);
}
var isProduction = process.env.NODE_ENV === "production";

// src/NFMultiTextField.tsx
import { useEffect as useEffect3, useRef as useRef2, useState } from "react";
import { registerField as registerField3 } from "react-honey-form";
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
var MultiTextContainer = styled("div", {
  appearance: "none",
  borderWidth: "0",
  boxSizing: "border-box",
  fontFamily: "inherit",
  margin: "0",
  outline: "none",
  display: "flex",
  alignItems: "center",
  flexGrow: "1",
  minWidth: "0",
  minHeight: "38px",
  gap: "8px",
  padding: "4px 8px",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  "&::before": {
    boxSizing: "border-box"
  },
  "&::after": {
    boxSizing: "border-box"
  },
  backgroundColor: "$input_background_enabled",
  color: "$input_default_text_enabled",
  fontVariantNumeric: "tabular-nums",
  border: "1px solid $colors$input_default_border_enabled",
  borderRadius: "$rounded",
  "&:focus-within": {
    boxShadow: "$primary_focus",
    borderColor: "$input_default_border_active"
  },
  "&::placeholder": {
    color: "$input_secondary_text_enabled"
  },
  "&:disabled": {
    pointerEvents: "none",
    backgroundColor: "$input_background_disabled",
    color: "$input_default_text_disabled",
    cursor: "not-allowed"
  },
  variants: {
    compact: {
      true: {
        height: "28px"
      }
    },
    state: {
      default: {},
      error: {
        borderColor: "$input_danger_border_enabled",
        "&:focus": {
          borderColor: "$input_danger_border_enabled",
          boxShadow: "$danger_focus"
        }
      },
      success: {
        borderColor: "$input_success_border_enabled",
        "&:focus": {
          borderColor: "$input_success_border_enabled",
          boxShadow: "$success_focus"
        }
      },
      warning: {
        borderColor: "$input_warning_border_enabled",
        "&:focus": {
          borderColor: "$input_warning_border_enabled",
          boxShadow: "$warning_focus"
        }
      }
    }
  },
  defaultVariants: {
    state: "default"
  }
});
var InputField = styled("input", {
  flexGrow: "1",
  border: "none",
  "&:focus": {
    outline: "none",
    border: "none"
  },
  variants: {
    showInput: {
      true: {
        display: "initial"
      },
      false: {
        display: "none"
      }
    }
  }
});
var ClearAllIconContainer = styled("span", {
  cursor: "pointer"
});
var NFMultiTextField = ({
  name,
  label,
  disabled = false,
  placeholder = "Type value and press enter or paste a list",
  touchOnChange = true,
  validateOnChange = true,
  defaultValue,
  fieldRef,
  validation,
  validationDebounce,
  monitorValidation
}) => {
  const field = registerField3(name, {
    defaultValue,
    fieldRef,
    validation,
    validationDebounce,
    monitorValidation
  });
  const stringValue = field.getValue() || "[]";
  const valueArray = JSON.parse(stringValue);
  const [showInput, setShowInput] = useState(!(valueArray.length > 0));
  const wrapperRef = useRef2(null);
  const inputRef = useRef2(null);
  const removeAllButtonRef = useRef2(null);
  useEffect3(() => {
    var _a;
    if (showInput && valueArray.length > 0) {
      (_a = inputRef.current) == null ? void 0 : _a.focus();
    }
  }, [showInput]);
  useOnOutsideClick(wrapperRef, () => setShowInput(false));
  const onChange = (newTags) => {
    touchOnChange && field.setTouched(true);
    field.setValue(JSON.stringify(newTags));
    validateOnChange && field.queueValidation();
  };
  const handleOnClick = () => {
    var _a;
    (_a = inputRef.current) == null ? void 0 : _a.focus();
    setShowInput(true);
  };
  const handleOnMouseDown = (e) => e.preventDefault();
  const resetInput = (e) => {
    e.target.value = "";
  };
  const addTag = (e) => {
    if (e.target.value !== "") {
      const sanitizedTag = e.target.value.trim();
      const newTags = [...valueArray, sanitizedTag];
      onChange(newTags);
      resetInput(e);
    }
  };
  const handleOnPaste = (e) => {
    var _a, _b;
    e.preventDefault();
    const pastedText = (_a = e.clipboardData) == null ? void 0 : _a.getData("Text");
    const sanitizedTags = (_b = pastedText == null ? void 0 : pastedText.split(/\r?\n|\r|\n/g)) == null ? void 0 : _b.filter((line) => line !== "");
    if ((sanitizedTags == null ? void 0 : sanitizedTags.length) && sanitizedTags.length > 1) {
      const newTags = [...valueArray, ...sanitizedTags];
      onChange(newTags);
      resetInput(e);
    } else {
      e.target.value = pastedText;
    }
  };
  const removeTag = (indexToRemove) => {
    const newTags = [
      ...valueArray.filter((_, index) => index !== indexToRemove)
    ];
    onChange(newTags);
  };
  const removeAllTags = () => {
    const newTags = [];
    onChange(newTags);
  };
  const handleOnKeyUp = (e) => {
    if (e.key === "Enter") {
      addTag(e);
    }
  };
  const handleIconClick = (e) => {
    var _a;
    e.stopPropagation();
    (_a = document.activeElement) == null ? void 0 : _a.blur();
    removeAllTags();
  };
  return /* @__PURE__ */ jsxs2(Flex2, {
    flow: "column",
    gap: "xs",
    children: [
      label && /* @__PURE__ */ jsx3(Label2, {
        disabled,
        above: true,
        children: label
      }),
      /* @__PURE__ */ jsx3("div", {
        onClick: handleOnClick,
        onMouseDown: handleOnMouseDown,
        ref: wrapperRef,
        children: /* @__PURE__ */ jsxs2(MultiTextContainer, {
          state: field.hasError() ? "error" : "default",
          children: [
            /* @__PURE__ */ jsxs2(Flex2, {
              gap: "sm",
              wrap: "wrap",
              css: { flexGrow: 1 },
              children: [
                valueArray.map((strVal, index) => /* @__PURE__ */ jsx3(Chip, {
                  className: "b-multi-text__tag",
                  label: strVal,
                  color: "neutral",
                  dismissible: !disabled ? {
                    onIconClick: () => removeTag(index)
                  } : {}
                }, index)),
                /* @__PURE__ */ jsx3(InputField, {
                  ref: inputRef,
                  type: "text",
                  showInput: showInput || valueArray.length < 1,
                  onKeyUp: handleOnKeyUp,
                  onPaste: handleOnPaste,
                  disabled,
                  onMouseDown: handleOnMouseDown,
                  placeholder: !valueArray.length ? placeholder : ""
                })
              ]
            }),
            !disabled && valueArray.length > 0 && /* @__PURE__ */ jsx3(ClearAllIconContainer, {
              ref: removeAllButtonRef,
              onClick: handleIconClick,
              children: /* @__PURE__ */ jsx3(Icon, {
                name: "circle-xmark"
              })
            })
          ]
        })
      }),
      field.hasError() && /* @__PURE__ */ jsx3(Hint2, {
        variant: "error",
        label: field.getErrorOrNull()
      })
    ]
  });
};

// src/NFRadio.tsx
import {
  Flex as Flex3,
  Hint as Hint3,
  Label as Label3,
  Radio,
  RadioGroup
} from "@kandji-inc/nectar-ui";
import {
  registerField as registerField4,
  useField
} from "react-honey-form";
import { jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var NFRadioGroup = ({
  name,
  title,
  children,
  touchOnChange = true,
  validateOnChange = true,
  defaultValue,
  fieldRef,
  validation,
  validationDebounce,
  monitorValidation,
  ...uiProps
}) => {
  const field = registerField4(name, {
    defaultValue,
    fieldRef,
    validation,
    validationDebounce,
    monitorValidation
  });
  return /* @__PURE__ */ jsxs3(Flex3, {
    flow: "column",
    gap: "xs",
    children: [
      title && /* @__PURE__ */ jsx4(Label3, {
        above: true,
        children: title
      }),
      /* @__PURE__ */ jsx4(RadioGroup, {
        ...uiProps,
        ref: field.getRef(),
        onValueChange: (value) => {
          touchOnChange && field.setTouched(true);
          field.setValue(value);
          validateOnChange && field.queueValidation();
        },
        "aria-invalid": field.hasError(),
        children
      }),
      field.hasError() && /* @__PURE__ */ jsx4(Hint3, {
        variant: "error",
        label: field.getErrorOrNull()
      })
    ]
  });
};
var NFRadio = ({
  name,
  value,
  ...uiProps
}) => {
  const field = useField(name);
  return /* @__PURE__ */ jsx4(Radio, {
    ...uiProps,
    value,
    checked: field.getValue() === value
  });
};

// src/NFReset.tsx
import { Button } from "@kandji-inc/nectar-ui";
import { useForm } from "react-honey-form";
import { jsx as jsx5 } from "react/jsx-runtime";
var NFReset = ({
  label = "Reset",
  variant = "subtle",
  onFormReset,
  ...uiProps
}) => {
  const form = useForm(false);
  const onClick = () => {
    form.reset();
    onFormReset !== void 0 && onFormReset(form);
  };
  return /* @__PURE__ */ jsx5(Button, {
    onClick,
    variant,
    ...uiProps,
    children: label
  });
};

// src/NFSelectV2.tsx
import {
  Flex as Flex4,
  Hint as Hint4,
  Label as Label4,
  SelectV2
} from "@kandji-inc/nectar-ui";
import { registerField as registerField5 } from "react-honey-form";
import { jsx as jsx6, jsxs as jsxs4 } from "react/jsx-runtime";
var parseOptions = (options) => {
  if (Array.isArray(options)) {
    return options;
  }
  return Object.entries(options).map(([value, label]) => ({
    label,
    value
  }));
};
var NFSelectV2 = ({
  name,
  title,
  options,
  touchOnChange = true,
  validateOnChange = true,
  defaultValue,
  fieldRef,
  validation,
  validationDebounce,
  monitorValidation,
  triggerVariant = "input",
  ...uiProps
}) => {
  const optionsArray = parseOptions(options);
  const field = registerField5(name, {
    defaultValue: defaultValue || optionsArray[0].value,
    fieldRef,
    validation,
    validationDebounce,
    monitorValidation
  });
  return /* @__PURE__ */ jsxs4(Flex4, {
    flow: "column",
    gap: "xs",
    children: [
      title && /* @__PURE__ */ jsx6(Label4, {
        above: true,
        children: title
      }),
      /* @__PURE__ */ jsx6(SelectV2, {
        triggerVariant,
        ...uiProps,
        ref: field.getRef(),
        options: optionsArray,
        value: field.getValue(),
        onValueChange: (value) => {
          touchOnChange && field.setTouched(true);
          field.setValue(value);
          validateOnChange && field.queueValidation();
        },
        onOpenChange: () => {
          field.setTouched(true);
        },
        "aria-invalid": field.hasError()
      }),
      field.hasError() && /* @__PURE__ */ jsx6(Hint4, {
        variant: "error",
        label: field.getErrorOrNull()
      })
    ]
  });
};

// src/NFSubmit.tsx
import { Button as Button2 } from "@kandji-inc/nectar-ui";
import { useForm as useForm2 } from "react-honey-form";
import { jsx as jsx7 } from "react/jsx-runtime";
var NFSubmit = ({
  label = "Submit",
  variant = "primary",
  ...uiProps
}) => {
  const form = useForm2();
  const disabled = form.hasErrors() || form.isValidating() || form.isSubmitting();
  const onClick = () => form.submit();
  return /* @__PURE__ */ jsx7(Button2, {
    type: "submit",
    variant,
    onClick,
    disabled: disabled || uiProps.disabled,
    ...uiProps,
    children: label
  });
};

// src/NFSwitch.tsx
import {
  Flex as Flex5,
  Hint as Hint5,
  Label as Label5,
  Switch
} from "@kandji-inc/nectar-ui";
import { registerField as registerField6 } from "react-honey-form";
import { jsx as jsx8, jsxs as jsxs5 } from "react/jsx-runtime";
var NFSwitch = ({
  name,
  value = "on",
  defaultChecked = false,
  title,
  touchOnChange = true,
  validateOnChange = true,
  fieldRef,
  validation,
  validationDebounce,
  monitorValidation,
  ...uiProps
}) => {
  const field = registerField6(name, {
    defaultValue: defaultChecked ? value : "",
    fieldRef,
    validation,
    validationDebounce,
    monitorValidation
  });
  return /* @__PURE__ */ jsxs5(Flex5, {
    flow: "column",
    gap: "xs",
    children: [
      title && /* @__PURE__ */ jsx8(Label5, {
        above: true,
        children: title
      }),
      /* @__PURE__ */ jsx8(Switch, {
        ...uiProps,
        ref: field.getRef(),
        checked: field.getValue() === value,
        onCheckedChange: (checked) => {
          touchOnChange && field.setTouched(true);
          field.setValue(checked ? value : "");
          validateOnChange && field.queueValidation();
        },
        "aria-invalid": field.hasError()
      }),
      field.hasError() && /* @__PURE__ */ jsx8(Hint5, {
        variant: "error",
        label: field.getErrorOrNull()
      })
    ]
  });
};

// src/NFTextArea.tsx
import {
  Flex as Flex6,
  Hint as Hint6,
  TextArea
} from "@kandji-inc/nectar-ui";
import { registerField as registerField7 } from "react-honey-form";
import { jsx as jsx9, jsxs as jsxs6 } from "react/jsx-runtime";
var NFTextArea = ({
  name,
  touchOnChange = true,
  validateOnChange = true,
  validateOnBlur = true,
  defaultValue,
  fieldRef,
  validation,
  validationDebounce,
  monitorValidation,
  ...uiProps
}) => {
  const field = registerField7(name, {
    defaultValue,
    fieldRef,
    validation,
    validationDebounce,
    monitorValidation
  });
  return /* @__PURE__ */ jsxs6(Flex6, {
    flow: "column",
    gap: "xs",
    children: [
      /* @__PURE__ */ jsx9(TextArea, {
        ...uiProps,
        state: field.hasError() ? "error" : "default",
        value: field.getValue(),
        onChange: (e) => {
          touchOnChange && field.setTouched(true);
          field.setValue(e.target.value);
          validateOnChange && field.queueValidation();
        },
        onBlur: () => {
          field.setTouched(true);
          validateOnBlur && field.queueValidation();
        },
        "aria-invalid": field.hasError()
      }),
      field.hasError() && /* @__PURE__ */ jsx9(Hint6, {
        variant: "error",
        label: field.getErrorOrNull()
      })
    ]
  });
};

// src/NFTextField.tsx
import {
  Flex as Flex7,
  Hint as Hint7,
  TextField
} from "@kandji-inc/nectar-ui";
import { registerField as registerField8 } from "react-honey-form";
import { jsx as jsx10, jsxs as jsxs7 } from "react/jsx-runtime";
var NFTextField = ({
  name,
  touchOnChange = true,
  validateOnChange = true,
  validateOnBlur = true,
  defaultValue,
  fieldRef,
  validation,
  validationDebounce,
  monitorValidation,
  ...uiProps
}) => {
  const field = registerField8(name, {
    defaultValue,
    fieldRef,
    validation,
    validationDebounce,
    monitorValidation
  });
  return /* @__PURE__ */ jsxs7(Flex7, {
    flow: "column",
    gap: "xs",
    children: [
      /* @__PURE__ */ jsx10(TextField, {
        ...uiProps,
        ref: field.getRef(),
        loading: field.isValidating(),
        state: field.hasError() ? "error" : "default",
        value: field.getValue(),
        onChange: (e) => {
          touchOnChange && field.setTouched(true);
          field.setValue(e.target.value);
          validateOnChange && field.queueValidation();
        },
        onBlur: () => {
          field.setTouched(true);
          validateOnBlur && field.queueValidation();
        },
        "aria-invalid": field.hasError()
      }),
      field.hasError() && /* @__PURE__ */ jsx10(Hint7, {
        variant: "error",
        label: field.getErrorOrNull()
      })
    ]
  });
};
export {
  Field,
  FieldArray,
  FieldHTMLAttributes,
  Form,
  NFCheckbox,
  NFMultiSelect,
  NFMultiTextField,
  NFRadio,
  NFRadioGroup,
  NFReset,
  NFSelectV2,
  NFSubmit,
  NFSwitch,
  NFTextArea,
  NFTextField,
  HoneyForm as NectarForm,
  Transform,
  Validation,
  ValidationTemplates,
  registerField9 as registerField,
  useField2 as useField,
  useFieldArray,
  useFieldValue,
  useForm3 as useForm
};
