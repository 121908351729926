import { links } from 'app/common/constants';
import { useMemo, useState } from 'react';
import RenderBreadcrumbs from '../../../../components/render-breadcrumbs';
import { INT_TYPES } from '../../../../constants';

import {
  Button,
  DropdownMenu,
  Flex,
  Icon,
  Loader,
  Tabs,
  Text,
  Toaster_UNSTABLE as Toaster,
  styled,
} from '@kandji-inc/nectar-ui';

import {
  StyledCard,
  StyledCardSectionFooter,
  StyledCardSectionHeader,
  StyledHeaderH2,
  StyledNav,
} from '../../../../components/styled-components/main';
import msDeviceComplianceLogo from '../../../../generic-cards-view/assets/microsoft-device-compliance.svg';
import useListIntegrations from '../../../hooks/use-list-integrations';
import useGetIntegrationDetails from '../../hooks/use-get-integration-details';
import AddDevicePlatform from '../add-device-platform';
import DeleteDevicePlatform from '../delete-device-platform';
import DeleteIntegration from '../delete-integration';
import ViewUserGroups from '../partials/view-user-groups';

const StyledAddDeviceButton = styled(Button, {
  marginLeft: 'auto',
});

const breadcrumbItems = [
  {
    title: 'Integrations',
    url: links.integrations.root,
  },
  { title: 'Microsoft Device Compliance' },
];

const tabs = [{ label: 'Overview', tabId: 'overview' }];

export enum DEVICE_PLATFORMS {
  macos = 'macos',
  ios = 'ios',
}
export type PlatformStatus =
  | DEVICE_PLATFORMS.macos
  | DEVICE_PLATFORMS.ios
  | null;

export const getPlatformLabel = (platform: PlatformStatus) => {
  return platform && platform.toLowerCase() === 'macos'
    ? 'macOS'
    : 'iOS/iPadOS';
};

export const DetailsIntegration = () => {
  const [isDeleteIntegrationDialogOpen, setIsDeleteIntegrationDialogOpen] =
    useState(false);
  const [isDeletePlatformDialogOpen, setIsDeletePlatformDialogOpen] =
    useState(false);
  const [isAddPlatformDialogOpen, setIsAddPlatformDialogOpen] = useState(false);
  const [platformActive, setPlatformActive] = useState<PlatformStatus>(null);

  // * get integration id by integration type from main-be service *
  const { data: listResult, isLoading: isListLoading } = useListIntegrations({
    integrationType: INT_TYPES.msDeviceCompliance,
  });
  const integrationId = listResult?.data?.results?.[0]?.uuid;

  // * get integration details by id from device-compliance service *
  const {
    data: detailsResult,
    isLoading: isDetailsLoading,
    // TODO: handle useful error UI message for API errors/missing data fields
    // error: detailsError,
  } = useGetIntegrationDetails(integrationId);

  const isPlatformConfigured = (platform: DEVICE_PLATFORMS) => {
    const value =
      detailsResult?.data?.[`${platform.toLowerCase()}_entra_group_ids`];
    return value && value.length > 0;
  };

  const isAllLoading: boolean = useMemo(
    () => isListLoading || isDetailsLoading,
    [isListLoading, isDetailsLoading],
  );

  const handleOpenAzureAdminPortal = () => {
    window.open('https://portal.azure.com', '_blank');
  };

  const toggleDeleteIntegrationDialog = () => {
    setIsDeleteIntegrationDialogOpen((prev) => !prev);
  };

  const toggleAddPlatformDialog = (platform: PlatformStatus) => {
    setIsAddPlatformDialogOpen((prev) => !prev);
    setPlatformActive(platform);
  };

  const toggleDeletePlatformDialog = (platform: PlatformStatus) => {
    setIsDeletePlatformDialogOpen((prev) => !prev);
    setPlatformActive(platform);
  };

  return (
    <>
      <Flex flow="column" gap="md">
        <StyledNav>
          <RenderBreadcrumbs items={breadcrumbItems} />
        </StyledNav>

        <Tabs.Container
          compact
          tabs={tabs}
          defaultTabId="overview"
          css={{
            gap: '$6',
          }}
        >
          {tabs.map((tab) => (
            <Tabs.Content tabId="overview" lazy key={tab}>
              {isAllLoading ? (
                <Flex justifyContent="center">
                  <Loader size="lg" />
                </Flex>
              ) : (
                <>
                  {detailsResult?.data && (
                    <Flex flow="column" gap="xl">
                      {/* General */}
                      <StyledCard>
                        <StyledCardSectionHeader>
                          <StyledHeaderH2 as="h2">General</StyledHeaderH2>
                          <DropdownMenu
                            options={[
                              {
                                testId: 'dropdown-menu:delete-integration',
                                label: 'Delete integration',
                                onClick: () => toggleDeleteIntegrationDialog(),
                              },
                            ]}
                          >
                            <Button
                              data-testid="dropdown-menu:ellipsis"
                              variant="default"
                              css={{
                                color: '$neutral70',
                                backgroundColor: '$neutral0',
                                position: 'absolute',
                                right: 32,
                              }}
                            >
                              <Icon name="ellipsis" />
                            </Button>
                          </DropdownMenu>
                        </StyledCardSectionHeader>

                        <Flex gap="lg" p5>
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            css={{
                              width: '72px',
                              height: '72px',
                              border: '1px solid rgba(120, 120, 128, 0.16)',
                              borderRadius: '15px',
                            }}
                          >
                            <img
                              src={msDeviceComplianceLogo}
                              width="52"
                              height="52"
                              alt="Microsoft Device Compliance icon"
                            />
                          </Flex>

                          <Flex flow="column" gap="xs">
                            <Text variant="description">
                              Microsoft Primary Domain
                            </Text>
                            <Text>{detailsResult.data?.ms_domain_name}</Text>
                          </Flex>
                        </Flex>

                        <StyledCardSectionFooter>
                          <Button
                            variant="link"
                            onClick={handleOpenAzureAdminPortal}
                            icon={{
                              name: 'arrow-up-right-from-square',
                              position: 'right',
                            }}
                            css={{
                              color: '$neutral70',
                              py: '$2',
                              px: '$3',
                            }}
                          >
                            Open Microsoft Entra ID admin portal
                          </Button>
                        </StyledCardSectionFooter>
                      </StyledCard>

                      {/* Platforms && User Groups */}
                      {Object.values(DEVICE_PLATFORMS).map((platform) => (
                        <StyledCard key={platform}>
                          <StyledCardSectionHeader>
                            <StyledHeaderH2 as="h2">
                              {getPlatformLabel(platform)} devices
                            </StyledHeaderH2>
                            {!isPlatformConfigured(platform) && (
                              <StyledAddDeviceButton
                                variant="subtle"
                                icon={{
                                  name: 'circle-plus',
                                  position: 'left',
                                }}
                                onClick={() =>
                                  toggleAddPlatformDialog(platform)
                                }
                              >
                                Add device platform
                              </StyledAddDeviceButton>
                            )}

                            {isPlatformConfigured(platform) && (
                              <DropdownMenu
                                options={[
                                  {
                                    testId:
                                      'dropdown-menu:delete-device-platform',
                                    label: 'Delete device platform',
                                    onClick: () =>
                                      toggleDeletePlatformDialog(platform),
                                  },
                                ]}
                              >
                                <Button
                                  data-testid="dropdown-menu:ellipsis-platform"
                                  variant="default"
                                  css={{
                                    color: '$neutral70',
                                    backgroundColor: '$neutral0',
                                    position: 'absolute',
                                    right: 32,
                                  }}
                                >
                                  <Icon name="ellipsis" />
                                </Button>
                              </DropdownMenu>
                            )}
                          </StyledCardSectionHeader>

                          {isPlatformConfigured(platform) && (
                            <ViewUserGroups
                              key={platform}
                              integrationDetails={detailsResult.data}
                              devicePlatform={platform}
                            />
                          )}
                        </StyledCard>
                      ))}
                    </Flex>
                  )}
                </>
              )}
            </Tabs.Content>
          ))}
        </Tabs.Container>
      </Flex>

      <DeleteIntegration
        isDialogOpen={isDeleteIntegrationDialogOpen}
        integrationId={integrationId}
        handleToggle={toggleDeleteIntegrationDialog}
      />

      <DeleteDevicePlatform
        isDialogOpen={isDeletePlatformDialogOpen}
        platform={platformActive}
        integrationId={integrationId}
        handleToggle={toggleDeletePlatformDialog}
      />

      <AddDevicePlatform
        isDialogOpen={isAddPlatformDialogOpen}
        platform={platformActive}
        integrationId={integrationId}
        integrationDetails={detailsResult?.data}
        handleToggle={toggleAddPlatformDialog}
      />

      <Toaster />
    </>
  );
};
