import React, { useState } from 'react';

import { array, bool, func, number, object } from 'prop-types';

import { Button, Flex } from '@kandji-inc/nectar-ui';

import { RulesContextProvider } from 'features/rules-modal/RulesContext';
import { RulesDeleteModal } from 'features/rules-modal/RulesDeleteModal';
import { RulesModal } from 'features/rules-modal/RulesModal';
import { useFlags } from 'src/config/feature-flags';
import { BuilderProvider } from '../builder/use-builder';
import BuilderModal from './builder-modal';
import BuilderDeleteModal from './delete-modal';
import RuleTagList, { EmptyRuleTagList } from './tags';

const AssignRules = ({
  facetMap,
  rules,
  setModel,
  isDisabled,
  supportedDeviceFamilies,
  installOnDeviceFamilies,
  countOfUserDirectoryIntegrations,
  hasUserDirectoryIntegration,
}) => {
  const [isBuilderModalOpen, setIsBuilderModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { 'DC-610-nectar-rules-modal': LD_nectarRulesModal } = useFlags([
    'DC-610-nectar-rules-modal',
  ]);

  const onBuilderClick = () => {
    setIsBuilderModalOpen(true);
  };

  const onDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  let Provider;
  let Modal;
  let DeleteModal;

  if (LD_nectarRulesModal) {
    Provider = RulesContextProvider;
    Modal = RulesModal;
    DeleteModal = RulesDeleteModal;
  } else {
    Provider = BuilderProvider;
    Modal = BuilderModal;
    DeleteModal = BuilderDeleteModal;
  }

  return (
    <Provider
      facetMap={facetMap}
      rules={rules}
      setModel={setModel}
      supportedDeviceFamilies={supportedDeviceFamilies}
      installOnDeviceFamilies={installOnDeviceFamilies}
      countOfUserDirectoryIntegrations={countOfUserDirectoryIntegrations}
      hasUserDirectoryIntegration={hasUserDirectoryIntegration}
      afterSubmit={() => setIsBuilderModalOpen(false)}
    >
      <div className="k-rules-library">
        <p className="b-txt label">Rules</p>

        <div className="k-rules-library-tags">
          {isDisabled && !rules ? (
            <EmptyRuleTagList />
          ) : (
            <RuleTagList
              facetMap={facetMap}
              rules={rules}
              installOnDeviceFamilies={installOnDeviceFamilies}
            />
          )}

          {!isDisabled && (
            <div className="k-rules-library-action">
              {rules ? (
                <Flex gap="xs">
                  <Button
                    variant="subtle"
                    icon={{ name: 'pencil' }}
                    onClick={onBuilderClick}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="subtleDanger"
                    icon={{ name: 'trash-can' }}
                    onClick={onDeleteClick}
                  >
                    Remove
                  </Button>
                </Flex>
              ) : (
                <Button
                  variant="subtle"
                  icon={{ name: 'circle-plus' }}
                  onClick={onBuilderClick}
                >
                  Add
                </Button>
              )}
              <Modal
                origin="library"
                isOpen={isBuilderModalOpen}
                setIsOpen={setIsBuilderModalOpen}
              />
              <DeleteModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
              />
            </div>
          )}
        </div>
      </div>
    </Provider>
  );
};

AssignRules.propTypes = {
  facetMap: object.isRequired,
  rules: object,
  setModel: func.isRequired,
  isDisabled: bool,
  supportedDeviceFamilies: array,
  installOnDeviceFamilies: array,
  countOfUserDirectoryIntegrations: number,
  hasUserDirectoryIntegration: bool,
};

AssignRules.defaultProps = {
  rules: null,
  isDisabled: true,
  supportedDeviceFamilies: [],
  installOnDeviceFamilies: [],
  countOfUserDirectoryIntegrations: 0,
  hasUserDirectoryIntegration: false,
};

export default AssignRules;
