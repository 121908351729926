import { intersection } from 'lodash';

import InstallContinuous from './library-list/assets/install_continuous.svg';
import InstallOnce from './library-list/assets/install_once.svg';

const libraryRoute = {
  library: '/library',
  addLibrary: '/library/add',
};

/* A list of device types */
const deviceTypes = {
  IPAD: 'iPad',
  IPHONE: 'iPhone',
  MAC: 'Mac',
  TV: 'Apple TV',
} as const;

/* Maps the api response for a device to a string. */
const apiToDevice = {
  runs_on_ipad: deviceTypes.IPAD,
  runs_on_iphone: deviceTypes.IPHONE,
  runs_on_mac: deviceTypes.MAC,
  runs_on_tv: deviceTypes.TV,
};

/* A list of OS Prefixes for support device types. */
const osPrefixes = {
  [deviceTypes.MAC]: 'macOS',
  [deviceTypes.IPHONE]: 'iOS',
  [deviceTypes.IPAD]: 'iPadOS',
  [deviceTypes.TV]: 'tvOS',
};

/* OS requirements for when defining library items that do not come from the
API. */
const osRequirements = {
  MAC_10_7: 'macOS 10.7+',
  MAC_10_9: 'macOS 10.9+',
  MAC_10_10: 'macOS 10.10+',
  MAC_10_11: 'macOS 10.11+',
  MAC_10_13: 'macOS 10.13+',
  MAC_10_13_2: 'macOS 10.13.2+',
  MAC_10_14: 'macOS 10.14+',
  MAC_10_14_4: 'macOS 10.14.4+',
  MAC_10_15: 'macOS 10.15+',
  MAC_11_0: 'macOS 11.0+',
  MAC_13_0: 'macOS 13+',
  MAC_15_0: 'macOS 15+',
  IOS_4_0: 'iOS 4.0+',
  IOS_6_0: 'iOS 6.0+',
  IOS_7_0: 'iOS 7.0+',
  IOS_8_0: 'iOS 8.0+',
  IOS_9_0: 'iOS 9.0+',
  IOS_9_3: 'iOS 9.3+',
  IOS_11_0: 'iOS 11.0+',
  IOS_12_0: 'iOS 12.0+',
  IOS_13_0: 'iOS 13.0+',
  IOS_13_1: 'iOS 13.1+',
  IOS_15_0: 'iOS 15.0+',
  IOS_17_0: 'iOS 17.0+',
  IOS_18_0: 'iOS 18.0+',
  IPAD_9_0: 'iPadOS 9.0+',
  IPAD_11_0: 'iPadOS 11.0+',
  IPAD_13_0: 'iPadOS 13.0+',
  IPAD_13_1: 'iPadOS 13.1+',
  IPAD_15_0: 'iPadOS 15.0+',
  IPAD_17_0: 'iPadOS 17.0+',
  IPAD_18_0: 'iPadOS 18.0+',
  TV_9_0: 'tvOS 9.0+',
  TV_10_2: 'tvOS 10.2+',
  TV_11_0: 'tvOS 11.0+',
  TV_17_0: 'tvOS 17.0+',
} as const;

/* A list of publishers for when defining configurations for library items that
do not come from the API. */
const publishers = {
  KANDJI: 'Kandji, Inc.',
  APPLE: 'Apple, Inc.',
} as const;

const installTypes = {
  CONTINUOUS: 'continuously_enforce',
  ONCE: 'install_once',
  NONE: 'no_enforcement',
};

const installTypeIcons = {
  [installTypes.ONCE]: InstallOnce,
  [installTypes.CONTINUOUS]: InstallContinuous,
};

const updateOnlyIconName = 'calendar-arrow-down';

const complianceTags = {
  CIS: 'CIS',
  NCSC: 'NCSC',
  SUPERVISED: 'Supervised',
  CMMC: 'CMMC',
  NIST: 'NIST',
};

const categories = {
  GENERAL: 'General',
  ENROLLMENT: 'Enrollment Configurations',
  AUTO_APPS: 'Auto Apps',
  OPERATING_SYSTEMS: 'Operating Systems',
  IOS_RELEASES: 'iOS Releases',
  IPADOS_RELEASES: 'iPadOS Releases',
  TVOS_RELEASES: 'tvOS Releases',
  MDM_MACOS_RELEASES: 'MDM Managed OS Releases',
  PROFILES: 'Profiles',
  CUSTOM_SCRIPTS: 'Custom Scripts',
  CUSTOM_APPS: 'Custom Apps',
  CUSTOM_PRINTERS: 'Custom Printers',
  APP_STORE: 'App Store Apps',
  IN_HOUSE_APPS: 'In-house Apps',
  SECURITY: 'Endpoint Security',
  BOOKMARKS: 'Bookmarks',
} as const;

const apiTypes = /** @type {const} */ ({
  BOOKMARK: 'bookmark',
  CUSTOM_APP: 'custom-app',
  CUSTOM_PRINTER: 'custom-printer',
  CUSTOM_SCRIPT: 'custom-script',
  VPP_APP: 'vpp-app',
  PROFILE: 'profile',
  AUTO_APP: 'automatic-app',
  IPA_APP: 'ipa-app',
  IOS_RELEASE: 'ios-release',
  IPADOS_RELEASE: 'ipados-release',
  MACOS_RELEASE: 'macos-release',
  MDM_MACOS_RELEASE: 'mdm-macos-release',
  TVOS_RELEASE: 'tvos-release',
  DEP_PROFILE: 'dep-profile',
  KANDJI_SETUP: 'kandji-setup',
  KANDJI_LOGIN: 'kandji-login',
  SSH: 'ssh',
  RECOVERY_PASSWORD: 'recovery-password',
  THREAT_SECURITY_POLICY: 'threat-security-policy',
  DEVICE_NAME: 'device-name',
  WALLPAPER: 'wallpaper',
  DEVICE_CONTROL: 'device-control',
}) as const;

const humanReadableTypes = {
  [apiTypes.AUTO_APP]: 'Auto App',
  [apiTypes.BOOKMARK]: 'Bookmarks',
  [apiTypes.CUSTOM_APP]: 'Custom Apps',
  [apiTypes.CUSTOM_PRINTER]: 'Custom Printers',
  [apiTypes.CUSTOM_SCRIPT]: 'Custom Scripts',
  [apiTypes.DEP_PROFILE]: 'Automated Device Enrollment',
  [apiTypes.DEVICE_CONTROL]: 'Accessory & Storage Access',
  [apiTypes.DEVICE_NAME]: 'Device Name',
  [apiTypes.IOS_RELEASE]: 'Operating System',
  [apiTypes.IPA_APP]: 'In-House App',
  [apiTypes.IPADOS_RELEASE]: 'Operating System',
  [apiTypes.KANDJI_LOGIN]: 'Passport',
  [apiTypes.KANDJI_SETUP]: 'Liftoff',
  [apiTypes.MACOS_RELEASE]: 'Operating System',
  [apiTypes.MDM_MACOS_RELEASE]: 'Operating System',
  [apiTypes.PROFILE]: 'Profile',
  [apiTypes.RECOVERY_PASSWORD]: 'Recovery Password',
  [apiTypes.SSH]: 'SSH',
  [apiTypes.THREAT_SECURITY_POLICY]: 'Avert',
  [apiTypes.TVOS_RELEASE]: 'Operating System',
  [apiTypes.VPP_APP]: 'App Store App',
  [apiTypes.WALLPAPER]: 'Wallpaper',
} as const;

/* Maps types from the API to the categories displayed on the library pages. */
export const typeToCategory = {
  [apiTypes.CUSTOM_SCRIPT]: categories.CUSTOM_SCRIPTS,
  [apiTypes.VPP_APP]: categories.APP_STORE,
  [apiTypes.PROFILE]: categories.PROFILES,
  [apiTypes.SSH]: categories.PROFILES,
  [apiTypes.RECOVERY_PASSWORD]: categories.PROFILES,
  [apiTypes.THREAT_SECURITY_POLICY]: categories.PROFILES,
  [apiTypes.AUTO_APP]: categories.AUTO_APPS,
  [apiTypes.CUSTOM_APP]: categories.CUSTOM_APPS,
  [apiTypes.CUSTOM_PRINTER]: categories.CUSTOM_PRINTERS,
  [apiTypes.IPA_APP]: categories.IN_HOUSE_APPS,
  [apiTypes.IOS_RELEASE]: categories.OPERATING_SYSTEMS,
  [apiTypes.IPADOS_RELEASE]: categories.OPERATING_SYSTEMS,
  [apiTypes.MACOS_RELEASE]: categories.OPERATING_SYSTEMS,
  [apiTypes.MDM_MACOS_RELEASE]: categories.MDM_MACOS_RELEASES,
  [apiTypes.TVOS_RELEASE]: categories.OPERATING_SYSTEMS,
  [apiTypes.KANDJI_SETUP]: categories.ENROLLMENT,
  [apiTypes.DEP_PROFILE]: categories.ENROLLMENT,
  [apiTypes.KANDJI_LOGIN]: categories.ENROLLMENT,
  [apiTypes.THREAT_SECURITY_POLICY]: categories.SECURITY,
  [apiTypes.BOOKMARK]: categories.BOOKMARKS,
  [apiTypes.DEVICE_NAME]: categories.PROFILES,
  [apiTypes.WALLPAPER]: categories.PROFILES,
  [apiTypes.DEVICE_CONTROL]: categories.SECURITY,
};

const getItemCategory = (type) => typeToCategory[type];

const getItemTypeDisplayName = (type) => humanReadableTypes[type];

const canAddLibraryTypes = {
  PREDEFINED_BY_BACKEND: 'predefined_by_backend',
  PREDEFINED_BY_FRONTEND: 'predefined_by_frontend',
  CANNOT_ADD: 'cannot_add',
} as const;

// This one can be used for both main Library page and Add Library Item page
/**
 * @typedef ItemData
 * @type {Object}
 * @property {string} name
 * @property {string} instance_name
 * @property {Array<string>} devices
 */
/**
 * @typedef CategoryData
 * @type {Object}
 * @property {string} category
 * @property {Array<ItemData>} data
 */
/**
 * @typedef Filters
 * @type {Object}
 * @property {string} category
 * @property {string} searchTerm
 * @property {Array<string>} devices
 */

/**
 * @param {Array<CategoryData>} allItemsByCategories
 * @param {Filters} filters
 * @param {Array<string>} order
 * @param {function(Object): boolean} isItemHidden
 * @returns {null|Array<CategoryData>}
 */
function getFilteredItems(allItemsByCategories, filters, order, isItemHidden) {
  if (!allItemsByCategories) {
    return null;
  }

  return allItemsByCategories
    .map((catData) => ({
      ...catData,
      data: catData.data.filter((item) => {
        const fitsByDevices = !!intersection(item.devices, filters.devices)
          .length;
        const fitsByNameSearch = item.name
          .toLowerCase()
          .includes(filters.searchTerm.toLowerCase());
        const fitsByInstanceNameSearch = item.instance_name
          ? item.instance_name
              .toLowerCase()
              .includes(filters.searchTerm.toLowerCase())
          : false;
        const notHidden = !isItemHidden(item);

        return (
          fitsByDevices &&
          (fitsByNameSearch || fitsByInstanceNameSearch) &&
          notHidden
        );
      }),
    }))
    .filter((catData) => {
      const fitsByCategory =
        !filters.category ||
        catData.category.toLowerCase() === filters.category.toLowerCase();
      const hasFittingItems = !!catData.data.length;

      return fitsByCategory && hasFittingItems;
    })
    .sort(
      (a, b) =>
        order.findIndex((orderedCat) => orderedCat === a.category) -
        order.findIndex((orderedCat) => orderedCat === b.category),
    );
}

const singularizeName = (name) => {
  const endings = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    s: '',
  };
  return name.replace(
    new RegExp(`(${Object.keys(endings).join('|')})$`),
    (r) => endings[r],
  );
};

export {
  libraryRoute,
  apiToDevice,
  deviceTypes,
  osPrefixes,
  osRequirements,
  publishers,
  categories,
  getItemCategory,
  getItemTypeDisplayName,
  canAddLibraryTypes,
  apiTypes,
  installTypes,
  installTypeIcons,
  getFilteredItems,
  complianceTags,
  singularizeName,
  updateOnlyIconName,
};
