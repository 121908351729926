import { Box, Icon, Tabs, styled } from '@kandji-inc/nectar-ui';
import type React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { links, tabsNames } from 'src/app/common/constants';
import ParameterTab from 'src/app/components/common/ParameterTab';
import StatusTab from 'src/app/components/computer/tabs/StatusTab';
import { useFlags } from 'src/config/feature-flags';
import useAccount from 'src/contexts/account';
import useParameters from 'src/features/blueprint-flow/services/use-parameters';
import DeviceStatusTab from 'src/features/device-status-tab';
import ThreatList from 'src/features/edr/threat';
import { VulnerabilitiesTable } from 'src/features/edr/vulnerability/single-device-record/vulnerabilities-table';
import { useGetLibrary } from 'src/features/library-items/data-service/library/useGetLibraryItems';
import {
  apiTypes,
  deviceTypes,
} from 'src/features/library-items/library/common';
import { getLostModeStatus } from 'src/hooks/useLostMode';

import ActivityTabNew from '../ActivityTabNew';
import ApplicationInventoryTab from '../ApplicationInventoryTab';
import { formatTime } from '../common/helpers';
import { Loader } from '../interface/Loader';
import NotesTab from '../notes/NotesTab';
import ComputerTechSpecsTab from './tabs/ComputerTechSpecsTab';
import LostModeTab from './tabs/LostModeTab';
import useComputer from './useComputer';
import useParamHistory from './useParamHistory';

const TabsWrapper = styled(Box, {
  "& [data-nectar-styled-name='group']": {
    position: 'sticky',
    top: '88px',
    backgroundColor: '$neutral0',
    zIndex: 10,
  },
  "& [role='tablist']": {
    padding: '0 $5',
    paddingTop: '$4',
  },
});

const SingleDeviceTabs = (props: any) => {
  const { setEditor } = props;
  const { id: computerId, tab } = useParams<{ id: string; tab: string }>();
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();
  const {
    'mead_06212023_device-status-tab-refresh': LDFF_status,
    'vm_06252024_vuln-poc': isVulnOn,
  } = useFlags();
  const {
    currentCompany: { feature_configuration },
  } = useAccount();
  const { data: computer } = useComputer(computerId);
  const { data: threatRes } = useGetLibrary({
    loc: 'list',
    apiParams: {
      blueprint__in: computer?.blueprint_id,
      type__in: apiTypes.THREAT_SECURITY_POLICY,
    },
    options: {
      enabled: !!computer?.blueprint_id,
    },
  });
  const { data: parametersConfig } = useParameters();
  const [selectedParameter, setSelectedParameter] = useState({
    selected: null,
    lastCheckIn: null,
  });
  const { refetch: fetchParameterHistory, isLoading: isLoadingParamHistory } =
    useParamHistory(selectedParameter?.selected, computerId, undefined, false);

  const { isLostModePending, isLostModeEnabled, isLostModeErrored } =
    getLostModeStatus(computer);
  const shouldShowLostMode =
    isLostModePending || isLostModeEnabled || isLostModeErrored;
  const prevIsLostModeShowing = useRef(null);

  const isThreatEnabled = feature_configuration?.edr?.enabled;
  const hasThreatInstalled = Boolean((threatRes as any)?.data?.count);

  /* istanbul ignore next */
  const createNote = (html) => {
    setEditor(html);
    goToTab('notes');
  };

  /* istanbul ignore next */
  const getLastCheckInForSelectedParameter = async (
    selectedParameter: string,
  ) => {
    if (!selectedParameter) {
      goToTab('status');
    }

    try {
      const { data } = await fetchParameterHistory();
      setSelectedParameter({
        selected: selectedParameter,
        lastCheckIn: data?.results[0]?.ended_at || null,
      });
    } catch (_) {
      setSelectedParameter({
        selected: null,
        lastCheckIn: null,
      });
      goToTab('status');
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (computer) {
      if (tab && tab.indexOf('paramHistory') >= 0) {
        const URLparams = new URLSearchParams(location.search);
        const paramID = URLparams.get('parameter');
        getLastCheckInForSelectedParameter(paramID);
      }
    }
  }, [computer, match]);

  /* istanbul ignore next */
  useEffect(() => {
    if (prevIsLostModeShowing.current === undefined) {
      prevIsLostModeShowing.current = shouldShowLostMode;
      return;
    }

    if (prevIsLostModeShowing.current && !shouldShowLostMode) {
      goToTab('status');
    } else if (!prevIsLostModeShowing.current && shouldShowLostMode) {
      goToTab('lost-mode');
    }
    prevIsLostModeShowing.current = shouldShowLostMode;
  }, [shouldShowLostMode]);

  const goToTab = (tab: string) =>
    history.push(`${links.devices}/${computerId}/${tab}`);

  const getTab = (url: string, defaultTab = 'status') => {
    const tab = tabs.find(
      (tab) => url.includes(tab.path) || url.includes(tab.altPath),
    );

    if (!tab) {
      return defaultTab;
    }

    if (tab.isHidden) {
      goToTab(defaultTab);
      return defaultTab;
    }

    return tab.path;
  };

  const routerProps = {
    location,
    match,
    history,
  };

  const TabLostMode = useMemo(() => {
    if (shouldShowLostMode) {
      return <LostModeTab computer={computer} />;
    }

    return null;
  }, [shouldShowLostMode, computer]);

  const TabStatus = useMemo(() => {
    if (tab === 'paramHistoryStatus') {
      return (
        <Box>
          <nav className="nav nav-secondary" style={{ borderBottom: 0 }}>
            <Link
              className="d-flex"
              role="presentation"
              style={{ marginTop: '15px' }}
              to={`${links.devices}/${computerId}/status`}
            >
              <div style={{ width: '4rem', marginTop: '.25rem' }}>
                <button
                  type="button"
                  className="btn btn-sm"
                  style={{ width: '34px', padding: 0, margin: '0 auto' }}
                >
                  <Icon name="angle-left" size="sm" />
                </button>
              </div>
              <div style={{ display: 'flex', flexFlow: 'wrap column' }}>
                <span
                  style={{
                    cursor: 'pointer',
                    fontSize: '1.6rem',
                    fontWeight: 700,
                  }}
                >
                  Parameter History
                </span>
                <span
                  className="text-grey"
                  style={{ cursor: 'pointer', fontSize: '1.5rem' }}
                >
                  {`${
                    (
                      parametersConfig?.parameters?.parametersById[
                        selectedParameter.selected
                      ] || {
                        name: 'Loading...',
                      }
                    ).name
                  }`}
                </span>
                <span
                  className="text-grey"
                  style={{
                    cursor: 'pointer',
                    fontSize: '1rem',
                    fontWeight: 300,
                  }}
                >
                  {`Checked In: ${
                    isLoadingParamHistory
                      ? 'Loading...'
                      : selectedParameter.lastCheckIn
                        ? formatTime(selectedParameter.lastCheckIn, true)
                        : 'Not Yet Run'
                  }`}
                </span>
              </div>
            </Link>
          </nav>
          <ParameterTab
            computerId={computerId}
            view
            expand
            hideComputer
            paramNameColWidth="56%"
            onCreateNote={createNote}
            recordActions={['note']}
            filters={['time', 'status', 'connectivity']}
            noEndedAt
            {...routerProps}
          />
        </Box>
      );
    }

    if (LDFF_status) {
      return <DeviceStatusTab />;
    }

    return (
      <StatusTab
        // @ts-ignore
        paramNameColWidth="60%"
        onCreateNote={createNote}
        onHistoryClick={
          /* istanbul ignore next */ (selectedParameter) => {
            history.push(
              `${links.devices}/${computerId}/paramHistoryStatus?parameter=${selectedParameter}&period=30`,
            );
          }
        }
      />
    );
  }, [
    computer,
    routerProps,
    selectedParameter,
    isLoadingParamHistory,
    parametersConfig,
  ]);

  const TabActivity = useMemo(
    () => (
      <ActivityTabNew
        computerId={computerId}
        activityTabType="computer"
        filters={['type', 'period']}
        {...routerProps}
      />
    ),
    [computer, routerProps],
  );

  const TabTechSpecs = useMemo(
    () => <ComputerTechSpecsTab />,
    [computer, routerProps],
  );

  const TabAppInventory = useMemo(
    () => (
      <ApplicationInventoryTab computer={{ ...computer }} {...routerProps} />
    ),
    [computer, routerProps],
  );

  const TabThreats = useMemo(() => {
    if (
      !isThreatEnabled ||
      computer?.device_family !== deviceTypes.MAC ||
      !hasThreatInstalled
    ) {
      return null;
    }

    return (
      <ThreatList
        computer={computer}
        // isSecurityPolicyAssigned is kept here as backwards compatibility for the ThreatList component
        // it can be removed when the feature flag and the old threats UI is removed
        isSecurityPolicyAssigned={hasThreatInstalled}
        blueprintId={computer?.blueprint_id}
        isOnDeviceRecord
        forceV2
      />
    );
  }, [computer, isThreatEnabled, hasThreatInstalled]);

  const TabNotes = useMemo(
    () => <NotesTab computerId={computerId} />,
    [computer, routerProps],
  );

  const TabVulnerabilities = useMemo(
    () => <VulnerabilitiesTable computerId={computerId} />,
    [computer, routerProps],
  );

  if (!computer) {
    return <Loader />;
  }

  const tabs = [
    {
      label: tabsNames.lostMode,
      tabId: tabsNames.lostMode,
      path: 'lost-mode',
      render: TabLostMode,
      isHidden: !shouldShowLostMode,
    },
    {
      label: tabsNames.status,
      tabId: tabsNames.status,
      path: 'status',
      altPath: 'paramHistoryStatus',
      render: TabStatus,
    },
    {
      label: tabsNames.activity,
      tabId: tabsNames.activity,
      path: 'activity',
      render: TabActivity,
    },
    {
      label: tabsNames.techSpecs,
      tabId: tabsNames.techSpecs,
      path: 'techSpecs',
      render: TabTechSpecs,
    },
    {
      label: tabsNames.notes,
      tabId: tabsNames.notes,
      path: 'notes',
      render: TabNotes,
    },
    {
      label:
        computer?.device_family === deviceTypes.MAC
          ? tabsNames.applicationInventoryTab
          : tabsNames.applicationInventoryTabForIOS,
      tabId: tabsNames.applicationInventoryTab,
      path: 'application-inventory',
      render: TabAppInventory,
    },
    {
      label: tabsNames.threats,
      tabId: tabsNames.threats,
      path: 'threats',
      render: TabThreats,
      isHidden: !(
        isThreatEnabled &&
        computer?.device_family === deviceTypes.MAC &&
        hasThreatInstalled
      ),
    },
    {
      label: tabsNames.vulnerabilities,
      tabId: tabsNames.vulnerabilities,
      path: 'vulnerabilities',
      render: TabVulnerabilities,
      css: { padding: 0 },
      isHidden: !(isVulnOn && computer?.device_family === deviceTypes.MAC),
    },
  ];

  const visibleTabs = tabs.filter((tab) => !tab.isHidden);

  return (
    <TabsWrapper>
      <Tabs.Container
        compact
        tabs={visibleTabs.map((tab) => ({
          ...tab,
          tabId: tab.path,
          onClick: () => {
            window.scrollTo(0, 0);
            goToTab(tab.path);
          },
        }))}
        defaultTabId={getTab(match.url)}
        tabId={getTab(match.url)}
      >
        {visibleTabs.map((tab) => {
          return (
            <Tabs.Content tabId={tab.path} key={tab.path}>
              <Box css={{ padding: '0 24px', ...tab.css }}>
                {tab.render as React.ReactNode}
              </Box>
            </Tabs.Content>
          );
        })}
      </Tabs.Container>
    </TabsWrapper>
  );
};

export default connect()(SingleDeviceTabs);
