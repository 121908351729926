import { Flex, Heading, Toast } from '@kandji-inc/nectar-ui';
import { InterfaceContext } from 'contexts/interface';
import useWhiteBg from 'features/util/hooks/use-white-bg';
import { useContext, useRef } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import featureFlags from 'src/config/feature-flags';
import ThreatListUnassignedEmptyState from '../common/components/ThreatListUnassignedEmptyState';
import useThreatsToast from '../common/hooks/use-threats-toast';
import { THREAT_PATHS } from '../route';
import ThreatListByDevices from './ThreatListByDevices/ThreatListByDevices';
import ThreatListByThreats from './ThreatListByThreats/ThreatListByThreats';

type ThreatListProps = Readonly<{
  isOnDeviceRecord?: boolean;
}>;

const ThreatList = (props: ThreatListProps) => {
  const { isOnDeviceRecord } = props;
  useWhiteBg(!isOnDeviceRecord);
  const { path, url } = useRouteMatch();
  const { sidebarOpened } = useContext(InterfaceContext);
  const toastRef = useRef(null);
  const { showToast } = useThreatsToast(toastRef);

  const isVulnFeatureFlagOn = featureFlags.getFlag('vm_06252024_vuln-poc');

  return (
    <>
      <Flex flow="column">
        {!isOnDeviceRecord && !isVulnFeatureFlagOn && (
          <Heading size="1" css={{ padding: '$6 $5 $2' }}>
            Threats
          </Heading>
        )}
        {!isOnDeviceRecord && <ThreatListUnassignedEmptyState />}
        <Switch>
          <Route exact path={`${path}/by-threats`}>
            <ThreatListByThreats showToast={showToast} />
          </Route>
          <Route exact path={`${path}/by-devices`}>
            <ThreatListByDevices showToast={showToast} />
          </Route>

          {/* 
            If the Vuln feature flag is on, redirect /root to /root/:threat-tab/by-threats.
            If the Vuln feature flag is off, redirect /root to /root/by-threats.
          */}
          {isVulnFeatureFlagOn && (
            <Route exact path={`${links.threat}/${THREAT_PATHS.threats.path}`}>
              <Redirect to={`${url}/by-threats`} />
            </Route>
          )}
          {!isVulnFeatureFlagOn && <Redirect to={`${path}/by-threats`} />}
        </Switch>
      </Flex>
      <Toast
        testId="threat-toaster"
        managerRef={toastRef}
        viewportCss={{
          left: sidebarOpened ? '256px' : '78px',
        }}
      />
    </>
  );
};

export default ThreatList;
