import type {
  AllowedSelectValue,
  SelectOptionMemberList,
} from '@kandji-inc/nectar-ui';
import { useEffect } from 'react';
import type { IntegrationGroupsResult } from '../types/schema';
import useGetIntegrationUserGroupList from './use-get-integration-user-group-list';

const useGetUserGroupList = ({
  setUserGroupOptions,
  setIsLoading,
  searchTerm,
}: {
  setUserGroupOptions: (
    arg: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>,
  ) => void;
  setIsLoading?: (a: boolean) => void;
  searchTerm?: string;
}) => {
  const { data: userGroupList, isLoading } =
    useGetIntegrationUserGroupList(searchTerm);

  useEffect(() => {
    setIsLoading ? setIsLoading(isLoading) : null;
    if (userGroupList) {
      /* istanbul ignore next */
      const results = userGroupList?.data?.results || [];

      setUserGroupOptions(
        results.map((r: IntegrationGroupsResult) => ({
          label: `${r.name} (${r.integration_name})`,
          value: `${r.id}|${r.external_id}`,
        })),
      );
    }
  }, [userGroupList, isLoading]);
};

export default useGetUserGroupList;
