import {
  Dialog,
  Flex,
  Paragraph,
  Toaster_UNSTABLE as Toaster,
  styled,
} from '@kandji-inc/nectar-ui';
import React, { useState, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { links } from 'app/common/constants';
import featureFlags from 'src/config/feature-flags';
import { useSidebarToast } from 'src/hooks/useSidebarToast';
import Carousel from '../../../util/components/carousel';
import ActionDialog from '../../components/action-dialog';
import FooterButtons from '../../components/footer-buttons';
import FooterStatus from '../../components/footer-status/footer-status';
import RenderBreadcrumbs from '../../components/render-breadcrumbs';
import { INT_TYPES } from '../../constants';
import configs from './configs';

import type {
  CallbackArgs,
  CommonState,
  IntegratorViewConfig,
} from './types/integrator.types';

import {
  ParagraphMedium,
  StyledNav,
} from '../../components/styled-components/main';

import CustomLayout from './layouts/custom-layout';
import OneColumnCheckboxFull from './layouts/one-column-checkbox-full';
// Layouts
import OneColumnInfo from './layouts/one-column-info';
import OneColumnInfoFull from './layouts/one-column-info-full';
import OneColumnInput from './layouts/one-column-input';
import OneColumnInputFull from './layouts/one-column-input-full';
import TwoColumnCheckbox from './layouts/two-column-checkbox';
import TwoColumnInput from './layouts/two-column-input';

const PAGE_WIDTH = 900;
const FOOTER_HEIGHT = 72;

const StyledIntegratorView = styled(Flex, {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledDialogContainer = styled(Flex, {
  height: '445px',
});

const StyledPage = styled('div', {
  width: `${PAGE_WIDTH}px`,
  paddingBottom: '$5',
});

const StyledFooter = styled('div', {
  height: `${FOOTER_HEIGHT}px`,
  width: `${PAGE_WIDTH}px`,
  backgroundColor: '$neutral0',
  borderTop: '1px solid $neutral30',
  position: 'fixed',
  padding: '$4 0',
  bottom: '0',
});

type Props<InputType, StateType, DataType> = {
  config: IntegratorViewConfig<InputType, StateType, DataType>;
};

export const IntegratorView = <
  InputType,
  StateType extends CommonState,
  DataType,
>({
  config,
}: Props<InputType, StateType, DataType>) => {
  let { pages } = config;
  const { logo, openDialog } = config;

  const [pageIdx, setPageIdx] = useState(0);
  const [state, setStateRaw] = useState({} as StateType);
  const [canceling, setCanceling] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(openDialog);
  // Skip first render to avoid page flickering on custom initialLoad
  // as React will run the effect after rendering and after performing the DOM updates.
  const [skipFirstRender, setSkipFirstRender] = useState(!!config.initialLoad);

  const history = useHistory();
  const methods = useForm({ mode: 'onChange' });
  const { clearErrors, trigger, setError } = methods;

  const { displayToast } = useSidebarToast();

  const breadcrumbItems = [
    { title: 'Integrations', url: links.integrations.root },
    { title: 'Marketplace', url: links.integrations.marketplace },
    { title: `Configure ${config.name}` },
  ];

  useEffect(() => {
    // Override background color
    const bgClass = 'white-bg'; // white-bg defined in _layout.scss
    if (!isDialogOpen) {
      document.body.classList.add(bgClass);
    }

    // Support custom initial load
    if (config.initialLoad) {
      config.initialLoad(callbackArgs());
    }
    setSkipFirstRender(false);

    return () => document.body.classList.remove(bgClass);
  }, []);

  const setState = useCallback(
    (input: StateType) => {
      setStateRaw({ ...state, ...input });
    },
    [state],
  );

  const deleteState = useCallback(
    (key: keyof StateType) => {
      delete state[key];
      setStateRaw({ ...state });
    },
    [state],
  );

  const clearFooterStatus = useCallback(() => {
    deleteState('footerStatus');
  }, [state]);

  // Close Confirm
  const closeConfirm = () => {
    history.push(links.integrations.marketplace);
  };

  // Close Page
  const closePage = () => {
    /* istanbul ignore next */
    const hasValues = Object.values(methods.getValues()).some((val) => val);
    if (hasValues) {
      setCanceling(true);
    } else {
      closeConfirm();
    }
  };

  // Next page
  const nextPage = useCallback(async () => {
    clearFooterStatus();
    setPageIdx((prev) => prev + 1);
  }, [pageIdx, clearFooterStatus]);

  // Prev page
  const prevPage = useCallback(() => {
    clearErrors();
    clearFooterStatus();
    setPageIdx((prev) => prev - 1);
  }, [pageIdx, clearErrors, clearFooterStatus]);

  const callbackArgs = (): CallbackArgs<InputType, StateType> => ({
    nextPage,
    prevPage,
    formInputs: methods.getValues() as InputType,
    setError,
    setState,
    setIsDialogOpen,
    state,
    history,
    displayToast,
  });

  // Condition page filtering
  pages = pages.filter((p) =>
    p.condition ? p.condition(callbackArgs()) : true,
  );
  const page = pages[pageIdx];

  if (!page) {
    throw new Error(`I can't find a page with index ${pageIdx}`);
  }

  // Support custom button actions
  const handleNext = async (args: CallbackArgs<InputType, StateType>) => {
    const validationResult = await trigger();

    // Skip action if there is an error in the form
    if (validationResult) {
      const next = page.handleNext || nextPage;
      next(args);
    }
  };
  const handleClose = page.handleClose || closePage;
  const nextButtonText = () =>
    typeof page.nextButtonText === 'function'
      ? page.nextButtonText(callbackArgs())
      : page.nextButtonText;
  const backButtonText = () =>
    typeof page.backButtonText === 'function'
      ? page.backButtonText(callbackArgs())
      : page.backButtonText;
  const showCancelButton = () =>
    typeof page.showCancelButton === 'function'
      ? page.showCancelButton(callbackArgs())
      : page.showCancelButton;
  const statusDownloading =
    state.downloadProgress !== undefined && state.downloadProgress >= 0;
  const statusPending = state.footerStatus && state.footerStatus === 'pending';
  const showBackButton = pageIdx > 0 && !statusDownloading;
  const isProcessing = statusPending || statusDownloading || state.isProcessing;

  const dialogContent = () => (
    <>
      <ParagraphMedium>Are you sure you want to cancel?</ParagraphMedium>
      <Paragraph>
        By canceling the integration setup, all work will be lost and you will
        need to start over to configure the integration.
      </Paragraph>
    </>
  );

  // Convert config into components
  const children = pages.map((p, i) => {
    if (i !== pageIdx) {
      return null;
    }

    switch (p?.layout) {
      case 'one-column-info':
        return <OneColumnInfo logo={logo} {...p} state={state} />;
      case 'one-column-info-full':
        return <OneColumnInfoFull logo={logo} {...p} />;
      case 'one-column-input':
        return <OneColumnInput logo={logo} {...p} />;
      case 'one-column-input-full':
        return (
          <OneColumnInputFull logo={logo} {...p} isProcessing={isProcessing} />
        );
      case 'one-column-checkbox-full':
        return (
          <OneColumnCheckboxFull
            logo={logo}
            {...p}
            isProcessing={isProcessing}
          />
        );
      case 'two-column-input':
        return <TwoColumnInput logo={logo} {...p} state={state} />;
      case 'two-column-checkbox':
        return <TwoColumnCheckbox logo={logo} {...p} />;
      case 'custom-layout':
        return <CustomLayout logo={logo} {...p} state={state} />;
      default:
        throw new Error('Unknown layout');
    }
  });

  const IntegratorNav = () => (
    <StyledNav>
      <RenderBreadcrumbs items={breadcrumbItems} />
    </StyledNav>
  );

  const IntegratorCancel = () => (
    <ActionDialog
      isOpen={canceling}
      title="Cancel integration setup"
      content={dialogContent()}
      nextText="Exit setup"
      nextClick={closeConfirm}
      backText="Back to setup"
      backClick={() => setCanceling(false)}
    />
  );

  const IntegratorBody = useCallback(
    () => (
      <>
        <Carousel
          width={PAGE_WIDTH}
          slides={children}
          currentSlideIdx={pageIdx}
        />
        <Toaster />
      </>
    ),
    [pageIdx],
  );

  const IntegratorFooter = () => (
    <FooterButtons
      textIcon={page.nextButtonIcon}
      nextButtonText={nextButtonText()}
      nextButtonDisabled={isProcessing}
      handleNext={() => handleNext(callbackArgs())}
      cancelButtonText={page.cancelButtonText || 'Cancel setup'}
      hasCancelButton={showCancelButton()}
      onClose={() => handleClose(callbackArgs())}
      showBackButton={showBackButton}
      backButtonText={backButtonText()}
      backOnClick={prevPage}
      showStepper={pages.length > 1}
      stepperIdx={pageIdx}
      stepsAmount={pages.length}
      isWorking={isProcessing}
      downloadProgress={state.downloadProgress}
      progressText={page.footerProgressText}
      customComponent={
        state.footerStatus && page.footerStatusOptions ? (
          <FooterStatus
            icon={logo}
            status={state.footerStatus}
            options={page.footerStatusOptions}
          />
        ) : undefined
      }
      classWrapper={false}
    />
  );

  const IntegratorPage = useCallback(
    () => (
      <>
        <IntegratorNav />
        <IntegratorCancel />

        <FormProvider {...methods}>
          <IntegratorBody />

          <StyledFooter>
            <IntegratorFooter />
          </StyledFooter>
        </FormProvider>
      </>
    ),
    [pageIdx, canceling, state],
  );

  return (
    <>
      {/* Skip first render to support custom initialload  */}
      {skipFirstRender ? null : (
        <StyledIntegratorView>
          <StyledPage>
            {!openDialog && <IntegratorPage />}

            {/* Support old design */}
            {openDialog && (
              <FormProvider {...methods}>
                <Dialog
                  isOpen={isDialogOpen}
                  onOpenChange={() => handleClose(callbackArgs())}
                  content={
                    <>
                      {canceling && <IntegratorCancel />}

                      <StyledDialogContainer flow="column">
                        <IntegratorBody />
                      </StyledDialogContainer>
                    </>
                  }
                  footer={<IntegratorFooter />}
                  css={{ maxWidth: '960px', minHeight: '640px' }}
                />
              </FormProvider>
            )}
          </StyledPage>
        </StyledIntegratorView>
      )}
    </>
  );
};

const IntegratorViewWrapper = () => {
  const params = new URLSearchParams(useLocation().search);
  const type = params.get('type') || '';
  const config = configs[type];

  const integrationVisibility = {
    [INT_TYPES.okta]: featureFlags.getFlag('integrator-flow-poc'),
    [INT_TYPES.servicenow]: featureFlags.getFlag('paint-servicenow'),
    [INT_TYPES.adcs]: featureFlags.getFlag('paint-adcs-integrator-flow'),
    [INT_TYPES.msDeviceCompliance]: featureFlags.getFlag(
      'paint-microsoft-device-compliance',
    ),
  };

  if (!config || !integrationVisibility[type]) {
    throw new Error('Unknown integration');
  }

  return <IntegratorView config={config} />;
};

export default IntegratorViewWrapper;
