import {
  type FieldArray,
  NFReset,
  NFSubmit,
  useForm,
} from '@kandji-inc/nectar-form';
import {
  Banner,
  Box,
  Button,
  DialogV2,
  Flex,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';
import { useRulesContext } from 'features/rules-modal/RulesContext';
import { Rule } from 'features/rules-modal/components/Rule';
import React from 'react';

export const RulesModal = ({
  isOpen,
  setIsOpen,
  origin,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  origin: 'flow' | 'library';
}) => {
  const {
    facetMap,
    andRulesArray,
    supportedDeviceFamilies,
    installOnDeviceFamilies,
    hasUserDirectoryIntegration,
  } = useRulesContext();
  const maxRules = Math.min(Object.keys(facetMap).length, 20);
  const ruleArray = andRulesArray as FieldArray; // only use "and" for now
  const form = useForm();

  const currentInputFields = ruleArray
    .getFieldIndexNames()
    .map((fieldIndexName) => `${fieldIndexName}.input`);

  const currentlySelectedInputs = currentInputFields
    .map((fieldName) =>
      form.hasField(fieldName) ? form.getField(fieldName).getValue() : '',
    )
    .filter((value) => value !== '');

  return (
    <DialogV2
      isOpen={isOpen}
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={
        /* istanbul ignore next */ () => {
          form.reset();
          setIsOpen(false);
        }
      }
      css={{
        width: '1050px',
      }}
      title="Assignment Rules"
      content={
        <>
          <Flex flow="column" gap="lg">
            <Text variant="secondary">
              {origin === 'flow'
                ? `Use rules to limit which devices assigned to the Blueprint will be assigned the Library Items in this assignment node. Library Items are assigned when all criteria below are met.`
                : `Use rules to limit which devices assigned to a Classic Blueprint will be assigned this Library Item. The Library Item is assigned when all criteria below are met.`}
            </Text>
            {origin === 'library' && (
              <Banner
                css={{
                  alignItems: 'center',
                  '& svg': {
                    width: '14px',
                    height: '14px',
                  },
                }}
                text={
                  <Text>
                    Rules configured here for the Library Item are independent
                    of any rules configured on an{' '}
                    <a
                      href="https://support.kandji.io/support/solutions/articles/72000627627"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="b-alink"
                    >
                      Assignment Map{' '}
                      <Icon name="arrow-up-right-from-square" size="sm" />
                    </a>
                    , and do not apply there.
                  </Text>
                }
              />
            )}
            <Box mt3 mb4 css={{ padding: '20px $3 0' }}>
              {ruleArray.getFieldIndexNames().map((fieldIndexName, index) => (
                <Rule
                  key={fieldIndexName}
                  facetMap={facetMap}
                  supportedDeviceFamilies={supportedDeviceFamilies}
                  installOnDeviceFamilies={installOnDeviceFamilies}
                  hasUserDirectoryIntegration={hasUserDirectoryIntegration}
                  index={index}
                  fieldPrefix={fieldIndexName}
                  currentlySelectedInputs={currentlySelectedInputs}
                  canRemoveRule={ruleArray.getLength() > 1}
                  removeRule={() => ruleArray.removeFieldIndex(fieldIndexName)}
                />
              ))}
            </Box>
          </Flex>
          <Button
            variant="subtle"
            icon={{ name: 'fa-plus-minus-small' }}
            onClick={() => ruleArray.addFieldIndex()}
            disabled={ruleArray.getLength() >= maxRules}
          >
            Add rule
          </Button>
        </>
      }
      footer={
        <Flex justifyContent="end" gap="sm">
          <NFReset
            variant="subtle"
            label="Cancel"
            onFormReset={() => setIsOpen(false)}
          />
          <NFSubmit
            label="Confirm"
            id="for-pendo__rules-confirm-btn"
            data-testid="rules-submit-btn"
          />
        </Flex>
      }
    />
  );
};
