import React, { useMemo } from 'react';

import {
  Box,
  Flex,
  Icon,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import { Summary } from 'features/library-items/template';
import AssignRules from 'features/rules/library';
import { useIntegrations } from 'src/features/integrations/hooks';

const Assignments = ({
  isEditing,
  isDisabled,
  blueprintOptions,
  devices,
  setModel,
  selectedBlueprints,
  excludedBlueprints,
  isAllBlueprints,
  setIsConfirmNavigationModalOpen,
  facetMap,
  rules,
  supportsRules,
  supportedDeviceFamilies,
  baseDeviceFamilies,
  supportsInstallOn,
}) => {
  const sortedBpOptions = useMemo(
    () => blueprintOptions?.sort((a, b) => a.label.localeCompare(b.label)),
    [blueprintOptions],
  );

  const { countOfUserDirectoryIntegrations, hasUserDirectoryIntegration } =
    useIntegrations();

  // dc-318; add support for iOS apps on iPad devices
  const updatedBaseDeviceFamilies =
    baseDeviceFamilies ?? supportedDeviceFamilies;

  const delta = supportedDeviceFamilies.filter(
    (df) => !updatedBaseDeviceFamilies.includes(df),
  );

  const deviceOptions = supportedDeviceFamilies.map((device) => ({
    label: delta.includes(device) ? (
      <Flex className="compatible-app-option" gap="xs" alignItems="center">
        {device}
        <TooltipProvider>
          <Tooltip
            css={{ zIndex: 1000 }}
            side="top"
            trigger="hover"
            content={`This app is not optimized for ${device}, but can be optionally installed.`}
          >
            <button type="button">
              <Icon name="circle-info" size={16} />
            </button>
          </Tooltip>
        </TooltipProvider>
      </Flex>
    ) : (
      device
    ),
    value: device,
  }));

  // When a library item supports the "Install on" dropdown, the device families to install on is a
  // potentially narrowed down list. However, if a library item does not support the "Install on"
  // dropdown, the device families to install on match the supported device families.
  const installOnDeviceFamilies = supportsInstallOn
    ? devices?.map((device) => device.value)
    : supportedDeviceFamilies;

  return (
    <Box mt5>
      {supportsInstallOn && (
        <Summary.AssignDevices
          devices={deviceOptions}
          selectedDevices={devices}
          baseDeviceFamilies={baseDeviceFamilies}
          isDisabled={isDisabled}
          onChange={({ selectedDevices }) =>
            setModel((p) => ({ ...p, devices: selectedDevices }))
          }
        />
      )}

      <Summary.Assign>
        <Summary.AssignBlueprints
          isDisabled={isDisabled || !blueprintOptions}
          onChange={({
            selectedBlueprints,
            excludedBlueprints,
            isAllBlueprints,
          }) =>
            setModel((p) => ({
              ...p,
              selectedBlueprints,
              excludedBlueprints,
              isAllBlueprints,
            }))
          }
          blueprints={sortedBpOptions || []}
          selectedBlueprints={selectedBlueprints}
          excludedBlueprints={excludedBlueprints}
          isAllBlueprints={isAllBlueprints}
          isEditing={isEditing}
          onAddNew={() => setIsConfirmNavigationModalOpen(true)}
        />

        {supportsRules && (
          <AssignRules
            facetMap={facetMap}
            rules={rules}
            setModel={setModel}
            isDisabled={isDisabled}
            supportedDeviceFamilies={supportedDeviceFamilies}
            installOnDeviceFamilies={installOnDeviceFamilies}
            countOfUserDirectoryIntegrations={countOfUserDirectoryIntegrations}
            hasUserDirectoryIntegration={hasUserDirectoryIntegration}
          />
        )}
      </Summary.Assign>
    </Box>
  );
};

export default React.memo(Assignments);
