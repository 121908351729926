import { Icon } from '@kandji-inc/bumblebee';
import { startGetBlueprint } from 'app/_actions/blueprint';
import { updateComputer } from 'app/_actions/computer';
import { setEditor } from 'app/_actions/editor';
import {
  deleteParam,
  queryParamHistory,
  setTabsScroll,
  setTabsStatus,
} from 'app/_actions/parameters';
import { setResultsFilters } from 'app/_actions/results';
import { setModal, setSnackbar } from 'app/_actions/ui';
import {
  activityPeriodList,
  defaultActivityFilters,
  defaultAlertsFilters,
  defaultFilters,
  links,
  statusList,
} from 'app/common/constants';
import classNames from 'classnames';
import { withPermissions } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import max from 'lodash/max';
import PropTypes from 'prop-types';
/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { bindActionCreators } from 'redux';
import NoDataToDisplay from '../../../assets/img/no_data_to_display.png';
import history from '../../router/history';
import AwesomeDropdown from '../interface/AwesomeDropdown';
import AwesomeMultiSelect from '../interface/AwesomeMultiSelect';
import AwesomeTableToolBar from '../interface/AwesomeTableToolBar';
import { HoveredSpan } from '../interface/Base';
import { LineLoader } from '../interface/LineLoader';
import ParamNameFilter from '../interface/filters/ParamNameFilter';
import { ParameterConnectivityFilter } from '../interface/filters/ParameterConnectivityFilter';
import CounterIndicator from '../interface/indicators/CounterIndicator';
import { profileLog } from '../library/log';
import { BootstrapTable, TableHeaderColumn } from './BootstrapTable';
import { Table } from './Table';
import TableNoDataHelper from './TableNoDataHelper';
import {
  formatTime,
  getHelperTextForResults,
  getParameterHtml,
  getStatusCircleIconClass,
  getStatusComputersColorClass,
} from './helpers';

const moment = require('moment');
const queryString = require('query-string');

export class ParameterTab extends Table {
  static contextType = InterfaceContext;

  constructor(props) {
    super(props);
    this.serverSidePagination = true; // Don't remove! It's used on parent
    this.fetchFunc = this.fetchParams;
    const activeTableFilters = queryString.parse(props.location.search, {
      arrayFormat: 'bracket',
    });

    const initPeriod = activeTableFilters.period || defaultFilters.period;
    const initParam = activeTableFilters.parameter || defaultFilters.parameter;
    const initConnectivity =
      activeTableFilters.connectivity || defaultFilters.connectivity;
    const initDisplayType =
      activeTableFilters.displayType || defaultFilters.displayType;
    const initStatus = activeTableFilters.status || defaultFilters.status;
    const initSizePerPage =
      activeTableFilters.sizePerPage || defaultFilters.sizePerPage;
    const initSortName = activeTableFilters.sortName || defaultFilters.sortName;
    const initSortOrder =
      activeTableFilters.sortOrder || defaultFilters.sortOrder;

    const currPage = activeTableFilters.page || defaultFilters.page;

    const initStartedAtName = props.startedAtName || 'Date';
    const initEndedAtName = props.endedAtName || 'Last Run';
    this.state = {
      ...this.state,
      isLoading: false,
      data: [],
      selectedPeriod: initPeriod,
      selectedStatus: initStatus,
      selectedParameter: initParam,
      selectedConnectivity: initConnectivity,
      selectedDisplayType: initDisplayType,
      sizePerPage: initSizePerPage,
      totalItems: 0,
      lastPage: 0,
      currPage,
      sortName: initSortName,
      sortOrder: initSortOrder,
      startedAtName: initStartedAtName,
      endedAtName: initEndedAtName,
    };
    this.next = null;
    this.onChange = props.onChange;
    this.createNote = this.createNote.bind(this);
    this.deleteResult = this.deleteResult.bind(this);
    this.annotateResults = this.annotateResults.bind(this);
    this.changeFilterInUrl = this.changeFilterInUrl.bind(this);
    this.expandComponent = this.expandComponent.bind(this);
    this.renderNoDataView = this.renderNoDataView.bind(this);
    this.formatParameterActions = this.formatParameterActions.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onConnectivityChange = this.onConnectivityChange.bind(this);
    this.onParameterChange = this.onParameterChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      sizePerPage,
      selectedStatus,
      selectedParameter,
      selectedPeriod,
      selectedConnectivity,
      selectedDisplayType,
      sortName,
      sortOrder,
      currPage,
      startedAtName,
      endedAtName,
    } = this.state;
    const nextSearch = queryString.parse(next.location.search, {
      arrayFormat: 'bracket',
    });

    if (
      (nextSearch.sizePerPage && nextSearch.sizePerPage !== sizePerPage) ||
      (nextSearch.status && !isEqual(nextSearch.status, selectedStatus)) ||
      (nextSearch.parameter &&
        !isEqual(nextSearch.parameter, selectedParameter)) ||
      (nextSearch.period && nextSearch.period !== selectedPeriod) ||
      (nextSearch.connectivity &&
        nextSearch.connectivity !== selectedConnectivity) ||
      (nextSearch.displayType &&
        nextSearch.displayType !== selectedDisplayType) ||
      (nextSearch.sortName && nextSearch.sortName !== sortName) ||
      (nextSearch.sortOrder && nextSearch.sortOrder !== sortOrder) ||
      (nextSearch.page && Number(nextSearch.page) !== Number(currPage))
    ) {
      this.setState(
        {
          sizePerPage: nextSearch.sizePerPage || defaultFilters.sizePerPage,
          selectedStatus: nextSearch.status || defaultFilters.status,
          selectedParameter: nextSearch.parameter || defaultFilters.parameter,
          selectedPeriod: nextSearch.period || defaultFilters.period,
          selectedDisplayType:
            nextSearch.displayType || defaultFilters.displayType,
          selectedConnectivity:
            nextSearch.connectivity || defaultFilters.connectivity,
          sortName: nextSearch.sortName || defaultFilters.sortName,
          sortOrder: nextSearch.sortOrder || defaultFilters.sortOrder,
          currPage: nextSearch.page || defaultFilters.page,
        },
        () => {
          this.fetchData(true);
        },
      );
    }

    if (next.startedAtName && next.startedAtName !== startedAtName) {
      this.setState({ startedAtName: next.startedAtName, currPage: 1 });
    }

    if (next.endedAtName && next.endedAtName !== endedAtName) {
      this.setState({ endedAtName: next.endedAtName, currPage: 1 });
    }
  }

  componentDidMount() {
    this.fetchData(true);
  }

  formQueryString = (arr) => {
    if (!arr || arr === 'ALL') {
      return null;
    }
    if (typeof arr === 'string') {
      return arr;
    }
    if (arr.indexOf('ALL') >= 0) {
      return null;
    }
    return arr.join(',');
  };

  formatSortingQueryString = (sortName, sortOrder) => {
    if (sortOrder === 'desc') {
      return `-${sortName}`;
    }
    if (sortOrder === 'asc') {
      return sortName;
    }
    return '';
  };

  fetchParams = () => {
    const requestQuery = this.getRequestQuery();
    setResultsFilters(requestQuery);
    return queryParamHistory(requestQuery);
  };

  getRequestQuery() {
    const options = {};
    const { noPeriod, blueprintId, computerId } = this.props;
    const {
      selectedStatus,
      selectedPeriod,
      selectedParameter,
      selectedConnectivity,
      selectedDisplayType,
      sizePerPage,
      currPage,
      sortName,
      sortOrder,
    } = this.state;
    const selectedStatusQuery = this.formQueryString(selectedStatus);
    const selectedBlueprintQuery = this.formQueryString(blueprintId);
    const selectedComputerQuery = this.formQueryString(computerId);
    const selectedParameterQuery = this.formQueryString(selectedParameter);
    const selectedConnectivityQuery =
      this.formQueryString(selectedConnectivity);
    const selectedDisplayTypeQuery = this.formQueryString(selectedDisplayType);
    if (selectedStatusQuery) {
      options.status = selectedStatusQuery;
    }
    if (selectedParameterQuery) {
      options.parameterid = selectedParameterQuery;
    }
    if (selectedBlueprintQuery) {
      options.blueprintid = selectedBlueprintQuery;
    }
    if (selectedComputerQuery) {
      options.computerid = selectedComputerQuery;
    }
    if (selectedConnectivityQuery) {
      options.connectivity = selectedConnectivityQuery;
    }
    if (selectedDisplayTypeQuery) {
      options.displayType = selectedDisplayTypeQuery;
    }
    if (!noPeriod && selectedPeriod && selectedPeriod !== 'ALL') {
      options.period = selectedPeriod;
    }
    if (sizePerPage) {
      options.sizePerPage = sizePerPage;
    }
    if (currPage) {
      options.page = currPage;
    }
    if (sortName && sortOrder) {
      options.ordering = this.formatSortingQueryString(sortName, sortOrder);
    }
    return options;
  }

  annotateResults = (results) => {
    const { parameterMeta, blueprintNames } = this.props;
    const totalResults = results || [];
    return totalResults.map((item) => {
      const { run, parameter_id, computer_name, first_for_enrollment } = item;
      const momentObj = moment(run);
      const paramObject = parameterMeta[parameter_id];
      return {
        ...item,
        runVerbose: run ? formatTime(run) : 'No yet run',
        runTimestamp: momentObj.unix(),
        nameVerbose: paramObject ? paramObject.name : 'No name',
        parameter__name: paramObject ? paramObject.name : 'No name',
        computerName: computer_name || 'No name',
        computer__name: computer_name || 'No name',
        blueprintName: blueprintNames[item.blueprint_id],
        blueprint__name: blueprintNames[item.blueprint_id],
        enrollmentDate: first_for_enrollment
          ? formatTime(
              first_for_enrollment.enrolled_at,
              false,
              null,
              null,
              true,
            )
          : null,
      };
    });
  };

  onPeriodChange(selectedPeriod) {
    if (this.state.selectedPeriod === selectedPeriod) {
      return null;
    }
    this.changeFilterInUrl({
      period: selectedPeriod || defaultFilters.period,
      page: defaultFilters.page,
    });
    return null;
  }

  onStatusChange(selectedStatus) {
    if (isEqual(this.state.selectedStatus, selectedStatus)) {
      return null;
    }
    this.changeFilterInUrl({
      status: selectedStatus || defaultFilters.status,
      page: defaultFilters.page,
    });
    return null;
  }

  onConnectivityChange(selectedConnectivity) {
    if (this.state.selectedConnectivity === selectedConnectivity) {
      return null;
    }
    this.changeFilterInUrl({
      connectivity: selectedConnectivity || defaultFilters.connectivity,
      page: defaultFilters.page,
    });
    return null;
  }

  onParameterChange(selectedParameters) {
    const { selectedParameters: currentSelectedParameters } = this.state;
    if (isEqual(currentSelectedParameters, selectedParameters)) {
      return null;
    }
    this.changeFilterInUrl({
      parameter: selectedParameters || defaultFilters.parameter,
      page: defaultFilters.page,
    });
    return null;
  }

  renderActionButtons = () => {
    const { filters, alertsStatusList } = this.props;
    const {
      selectedStatus,
      selectedParameter,
      selectedPeriod,
      selectedConnectivity,
      selectedDisplayType,
    } = this.state;

    return (
      <div className="parameter-table-filters">
        {filters.indexOf('time') >= 0 &&
          selectedDisplayType !== 'LAST_CHECKIN' && (
            <AwesomeMultiSelect
              title="All Time"
              hasCustomRange
              position="bottom left"
              selected={selectedPeriod}
              defaultValue={defaultActivityFilters.period}
              onChange={this.onPeriodChange}
              options={activityPeriodList}
              isActivityPeriod
              showCheck
            />
          )}
        {filters.indexOf('status') >= 0 && (
          <AwesomeMultiSelect
            title="All Statuses"
            position="bottom left"
            selected={selectedStatus}
            defaultValue={
              alertsStatusList
                ? defaultAlertsFilters.status
                : defaultFilters.status
            }
            options={alertsStatusList || statusList}
            onChange={this.onStatusChange}
            multiple={!alertsStatusList}
          />
        )}

        {filters.indexOf('parameter') >= 0 && (
          <ParamNameFilter
            onChange={this.onParameterChange}
            onFetch={() => this.fetchData(true)}
            parameterMeta={this.props.parameterMeta}
            selected={selectedParameter}
          />
        )}

        {filters.indexOf('connectivity') >= 0 &&
          selectedDisplayType !== 'STATUS_CHANGE' && (
            <ParameterConnectivityFilter
              onChange={this.onConnectivityChange}
              selected={selectedConnectivity}
            />
          )}
      </div>
    );
  };

  deleteResult = (item) => {
    const { id } = item;
    deleteParam({ id })
      .then(() => {
        if (this.props.onDelete) {
          this.props.onDelete(item);
        }
        if (this.state.data.length > 1) {
          this.fetchData(true, this.state.currPage);
        } else {
          this.setState(
            {
              currPage:
                this.state.currPage > 1
                  ? this.state.currPage - 1
                  : this.state.currPage,
            },
            () => {
              localStorage.setItem(
                'pagination.currentPage',
                this.state.currPage,
              );
              this.fetchData(true, this.state.currPage);
            },
          );
        }
        if (this.props.blueprintRecord.id) {
          this.props.startGetBlueprint(this.props.blueprintRecord.id);
        }
        this.props.setSnackbar('Parameter Record was deleted');
      })
      .catch((err) => {
        console.log(err);
        this.props.setSnackbar('An error occurred');
      });
  };

  createNote = (row) => {
    const { onCreateNote, parameterMeta } = this.props;
    const { name } = parameterMeta[row.parameter_id] || {};
    const html = getParameterHtml(name, row.run, row);
    if (onCreateNote) {
      onCreateNote(html);
    }
  };

  showHistory(parameterId) {
    const { onHistoryClick } = this.props;
    onHistoryClick(parameterId);
    this.setState({ selectedParameter: parameterId });
  }

  formatParameterActions(cell, row) {
    const {
      recordActions,
      onHistoryClick,
      updateComputer: updateComputerValue,
      permissions,
    } = this.props;
    const options = [];
    if (recordActions.indexOf('history') >= 0) {
      const historyHandler = () => {
        if (!onHistoryClick) {
          updateComputerValue({
            activeTab: 'activity',
            selectedParameter: row.parameter_id,
          });
        }
        localStorage.setItem('activeComputerParameter', row.parameter_id);
        return onHistoryClick
          ? this.showHistory(row.parameter_id)
          : history.push(
              `${links.devices}/${row.computer_id}/activity/paramHistory?parameter=${row.parameter_id}&period=30`,
            );
      };

      options.push({
        action: historyHandler,
        iconClass: 'circle-1',
        label: 'View Parameter History',
      });
    }
    if (recordActions.indexOf('note') >= 0) {
      options.push({
        action: () => this.createNote(row),
        iconClass: 'note-sticky',
        label: 'Create Note',
      });
    }
    if (
      recordActions.indexOf('delete') >= 0 &&
      permissions.canManageBlueprints
    ) {
      options.push({
        action: () => this.deleteResult(row),
        iconClass: 'trash-can',
        label: 'Delete Result',
      });
    }
    return <AwesomeDropdown horizontal="right" options={options} />;
  }

  formatStatusRow = (cell, row) => {
    const colorClass = getStatusComputersColorClass(
      row.status,
      null,
      row.deferred_install,
      row.is_missing,
    );
    const statusIconClass = getStatusCircleIconClass(
      row.status,
      row.is_missing,
      row.deferred_install,
    );
    return (
      <div>
        <i
          className={`${statusIconClass} table-row-status-icon ${colorClass}`}
          id={`statusRow${row.id}`}
        />
        <UncontrolledTooltip
          placement="top"
          delay={{
            show: 400,
            hide: 0,
          }}
          innerClassName="custom-helper"
          target={`statusRow${row.id}`}
        >
          {getHelperTextForResults(row.status)}
        </UncontrolledTooltip>
      </div>
    );
  };

  formatOfflineRow = (cell, row) => {
    if (!row.offline) {
      return null;
    }

    return (
      <div>
        <i className="fas fa-power-off text-grey" id={`offlineRow${row.id}`} />
        <UncontrolledTooltip
          placement="top"
          delay={{ show: 400, hide: 0 }}
          innerClassName="custom-helper"
          target={`offlineRow${row.id}`}
        >
          Offline Mode
        </UncontrolledTooltip>
      </div>
    );
  };

  formatFirstForEnrollmentRow = (cell, row) => {
    if (!(row.first_for_enrollment && row.status === 'REMEDIATED')) {
      return null;
    }

    return (
      <div>
        <Icon
          name="kandji-bee"
          className="text-grey"
          id={`firstForEnrollmentRow${row.id}`}
        />
        <UncontrolledTooltip
          placement="top"
          delay={{ show: 400, hide: 0 }}
          innerClassName="custom-helper"
          target={`firstForEnrollmentRow${row.id}`}
        >
          First run after enrollment
          <br />
          {row.enrollmentDate}
        </UncontrolledTooltip>
      </div>
    );
  };

  formatRemediatedRunsCountRow = (cell, row) =>
    row.status === 'REMEDIATED' && row.runs_count > 1 ? (
      <CounterIndicator
        id={row.id}
        tooltip={`Consecutive Remediations: ${row.runs_count}`}
        value={row.runs_count}
      />
    ) : null;

  formatNameRow = (cell, row) => {
    const { parameterMeta } = this.props;
    const { name } = parameterMeta[row.parameter_id] || {
      name: row.parameter_name || 'No name',
    };
    return <span title={name}>{name}</span>;
  };

  formatBlueprintRow = (cell, row) => {
    const { blueprintNames } = this.props;
    const blueprint = blueprintNames[row.blueprint_id] || 'Unknown Blueprint';
    const blueprintRecord = Object.keys(blueprintNames).includes(
      row.blueprint_id,
    );
    return (
      <span
        role="presentation"
        className={classNames({ 'table-link': blueprintRecord })}
        onClick={() => {
          if (row.blueprint_id && blueprintRecord) {
            history.push(`/blueprints/${row.blueprint_id}`);
          }
        }}
        title={blueprint}
      >
        {blueprint}
      </span>
    );
  };

  formatComputerId = (cell, row) => {
    const handleOnClick = () => {
      if (row.computer_id && !row.computer_is_removed) {
        history.push(`${links.devices}/${row.computer_id}`);
      }
    };
    return (
      <span
        role="presentation"
        title={row.computer_name || 'Click to open'}
        className={classNames('cursor-pointer', {
          'table-link': !row.computer_is_removed,
        })}
        onClick={handleOnClick}
      >
        {row.computer_name || 'Click to open'}
      </span>
    );
  };

  formatStartedAtRow = (cell, row) => (
    <HoveredSpan
      text={formatTime(row.started_at, true)}
      hoveredText={formatTime(row.started_at, false, false, false, true)}
    />
  );

  formatEndedAtRow = (cell, row) => (
    <HoveredSpan
      text={formatTime(row.run, true)}
      hoveredText={formatTime(row.run, false, false, false, true)}
    />
  );

  expandComponent = (row) => (
    <div className="parameter-details">
      {!row.extra_details && (
        <>
          {row.details && this.props.isAlertTab && (
            <p key="date_created_at">
              {`Alert Created: ${formatTime(
                row.started_at,
                null,
                null,
                null,
                true,
              )}`}
            </p>
          )}
          {row.details && (
            <p key="date_last_run">
              {row.status !== 'REMEDIATED'
                ? `Last Update: ${formatTime(row.run, null, null, null, true)}`
                : `Remediation on ${formatTime(
                    row.run,
                    null,
                    null,
                    null,
                    true,
                  )}`}
            </p>
          )}
          {(row.details || []).map((el, idx) => (
            <p key={idx}>{el}</p>
          ))}
        </>
      )}
      {row.extra_details &&
        row.library_item_type === 'profile' &&
        profileLog(row.extra_details, row.started_at)}
    </div>
  );

  onSortChange = (sortName, sortOrder) => {
    this.changeFilterInUrl({ sortName, sortOrder });
  };

  expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
    let content = '';

    if (isExpandableRow) {
      content = isExpanded ? (
        <i className="fas fa-caret-down c-grey-500" />
      ) : (
        <i className="fas fa-caret-right c-grey-999" />
      );
    } else {
      content = ' ';
    }
    return content;
  };

  paramsHasRemediationNotNullCount = (params) => {
    if (
      !(
        get(this.props, 'match.params.subtab') &&
        this.props.match.params.subtab.indexOf('paramHistory') > -1
      )
    ) {
      return false;
    }
    const isAnyRemediated = params.some(
      (param) => param.status === 'REMEDIATED' && param.runs_count > 1,
    );

    if (isAnyRemediated) {
      return true;
    }
    return false;
  };

  getParamsRemediationCountColumnWidth = (params) =>
    `${
      max(
        params
          .filter((param) => param.status === 'REMEDIATED')
          .map((param) => param.runs_count),
      ).toString().length *
        7 +
      35
    }px`;

  renderBlocker = () => (
    <div className="tab-parameters-blocker">
      <div className="wrapper">
        <h1 className="message">
          Results will become available after your subscription is updated.
        </h1>
        <p>
          Please contact your Account Executive or Customer Success Manager to
          update your subscription.
        </p>
      </div>
    </div>
  );

  changeFilterInUrl(filters) {
    const { location, match } = this.props;
    const activeFilters = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    if (isEqual(filters, activeFilters)) {
      return null;
    }
    const newFilters = queryString.stringify(
      { ...activeFilters, ...filters },
      { arrayFormat: 'bracket' },
    );
    history.push(`${match.url}?${newFilters}`);
    return null;
  }

  renderNoDataView() {
    const { isLoading } = this.state;
    const { noDataText, noDataImg } = this.props;
    if (isLoading) {
      return <LineLoader />;
    }
    return (
      <TableNoDataHelper
        borderless
        key="##table-empty##"
        message={noDataText || 'No data to display'}
        image={noDataImg || NoDataToDisplay}
      />
    );
  }

  isExpandableRow = (row) => {
    const { expand } = this.props;
    return !!(expand && !isEmpty(get(row, 'details')));
  };

  render() {
    const { bannerTopOffset } = this.context;
    const {
      blueprintNames,
      view,
      hideComputer,
      expand,
      hideOffline,
      hideBuildNumber,
      paramNameColWidth,
      recordActions,
      tenantOverLicenseLimit,
      noEndedAt,
      searchPlaceholder,
    } = this.props;

    const {
      sortOrder,
      sortName,
      data,
      selectedDisplayType,
      startedAtName,
      endedAtName,
      sizePerPage,
    } = this.state;

    const dataWithBlueprintNames = data.map((item) => ({
      ...item,
      blueprint_name: blueprintNames[item.blueprint_id] || 'Unknown blueprint',
    }));

    const options = {
      ...this.defaultOptions,
      sortName: sortName || defaultFilters.sortName,
      sortOrder: sortOrder || defaultFilters.sortOrder,
      sizePerPage,
      onSortChange: this.onSortChange,
      alwaysShowAllBtns: true, // Always show next and previous button
      expandBy: 'column',
      expandBodyClass: 'expand-hover',
      expandAll: true,
      doNotSortingData: true,
      noDataView: this.renderNoDataView(),
    };
    const nameColWidth = paramNameColWidth || '35%';

    if (tenantOverLicenseLimit) {
      return this.renderBlocker();
    }
    return (
      <>
        <AwesomeTableToolBar
          btnGroup={this.renderActionButtons()}
          searchLabel={searchPlaceholder || 'Search Parameters'}
          searchFunc={(e) => this.table.handleSearch(e)}
          top={bannerTopOffset}
        />
        <div>
          <BootstrapTable
            ref={(node) => {
              this.table = node;
            }}
            data={dataWithBlueprintNames}
            version="4"
            pagination
            remote={() => ({
              pagination: true,
            })}
            options={options}
            tableContainerClass="mini"
            containerClass="bst-borderless old-table"
            expandableRow={this.isExpandableRow}
            expandComponent={this.expandComponent}
            trClassName={expand ? 'table-row-clickable' : null}
            expandColumnOptions={
              expand
                ? {
                    expandColumnVisible: true,
                    expandColumnComponent: this.expandColumnComponent,
                    columnWidth: 60,
                  }
                : {}
            }
          >
            <TableHeaderColumn dataField="id" hidden isKey />

            <TableHeaderColumn
              dataField="status"
              dataAlign={expand ? 'left' : 'center'}
              columnClassName={
                expand
                  ? 'td-icon td-status-icon'
                  : 'td-icon-left td-status-icon'
              }
              className={
                expand
                  ? 'td-icon td-status-icon'
                  : 'td-icon-left td-status-icon'
              }
              dataFormat={this.formatStatusRow}
              width="15px"
            />

            <TableHeaderColumn
              // dataSort={(activeTab !== 'paramHistory')} // TODO https://kandji.atlassian.net/browse/OD-1473
              dataField="parameter_name"
              thStyle={{ width: nameColWidth }}
              tdStyle={{ width: nameColWidth }}
              dataFormat={this.formatNameRow}
              columnClassName="column-border-right"
              className="header-column-border-right"
            >
              Name
            </TableHeaderColumn>

            {!hideComputer && (
              <TableHeaderColumn
                dataSort
                dataField="computer_name"
                dataFormat={this.formatComputerId}
                columnClassName="first-after-line-column"
                className="first-after-line-column"
              >
                Device
              </TableHeaderColumn>
            )}

            {view && (
              <TableHeaderColumn
                dataSort
                dataField="blueprint_name"
                dataFormat={this.formatBlueprintRow}
              >
                Blueprint
              </TableHeaderColumn>
            )}

            {!hideBuildNumber && selectedDisplayType !== 'STATUS_CHANGE' && (
              <TableHeaderColumn
                dataSort
                dataField="version"
                thStyle={{ width: '70px' }}
                tdStyle={{ width: '70px' }}
              >
                Build
              </TableHeaderColumn>
            )}

            <TableHeaderColumn
              dataSort
              dataField="started_at"
              tdStyle={{ color: '#666' }}
              dataFormat={this.formatStartedAtRow}
            >
              {startedAtName}
            </TableHeaderColumn>

            {!noEndedAt && (
              <TableHeaderColumn
                dataSort
                dataField="ended_at"
                tdStyle={{ color: '#666' }}
                dataFormat={this.formatEndedAtRow}
              >
                {endedAtName}
              </TableHeaderColumn>
            )}

            {!hideOffline &&
              data.filter((param) => param.offline).length > 0 && (
                <TableHeaderColumn
                  dataField="offline"
                  columnClassName="td-icon"
                  className="td-icon"
                  dataFormat={this.formatOfflineRow}
                />
              )}

            {data.filter(
              (param) =>
                param.first_for_enrollment && param.status === 'REMEDIATED',
            ).length > 0 && (
              <TableHeaderColumn
                dataField="first_for_enrollment"
                columnClassName="td-icon"
                className="td-icon"
                dataFormat={this.formatFirstForEnrollmentRow}
              />
            )}

            {this.paramsHasRemediationNotNullCount(data) && (
              <TableHeaderColumn
                dataField="runs_count"
                tdStyle={{
                  width: this.getParamsRemediationCountColumnWidth(data),
                  textAlign: 'right',
                }}
                thStyle={{
                  width: this.getParamsRemediationCountColumnWidth(data),
                }}
                dataFormat={this.formatRemediatedRunsCountRow}
              />
            )}

            {recordActions.length > 0 && (
              <TableHeaderColumn
                dataField="id"
                tdStyle={{
                  overflow: 'visible',
                  width: '64px',
                  textAlign: 'right',
                }}
                thStyle={{ width: '64px' }}
                dataFormat={this.formatParameterActions}
                expandable={false}
              />
            )}
          </BootstrapTable>
        </div>
      </>
    );
  }
}

ParameterTab.defaultProps = {
  recordActions: ['history', 'delete', 'note'],
  filters: ['time', 'status', 'parameter', 'connectivity'],
};

ParameterTab.propTypes = {
  recordActions: PropTypes.array,
  filters: PropTypes.array,
};

const mapStateToProps = (state) => ({
  tenantOverLicenseLimit: state.account.tenantOverLicenseLimit,

  parameterMeta: state.data.parameterMeta,
  blueprints: state.data.blueprints,
  blueprintRecord: state.blueprintRecord,
  blueprintNames: state.data.blueprintNames,
  blueprintParams: state.data.blueprintParams,
  activeTab: state.computerRecord.activeTab,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal,
      setSnackbar,
      setEditor,
      startGetBlueprint,
      updateComputer,
      setTabsStatus,
      setTabsScroll,
      setResultsFilters,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPermissions(ParameterTab));
