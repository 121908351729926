import {
  Box,
  Card,
  Flex,
  Heading,
  Icon,
  type IconNames,
  Text,
  styled,
  theme,
} from '@kandji-inc/nectar-ui';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  apiTypes,
  deviceTypes,
  installTypes,
  updateOnlyIconName,
} from 'src/features/library-items/library/common';

import type { LibraryItem as TLibraryItem } from '../../blueprint-flow.types';
import { deviceKinds, deviceToIcon } from '../../constants';
import { getDeviceRunsOnCamelCase, highlightedText } from '../../helpers';
import { FlowTippy, TippyContainer } from '../../theme';

type LibraryItemProps = {
  item: TLibraryItem;
  searchTerm: string;
};

const LICard = styled(Card, {
  boxShadow: '0px 0px 0px 0px rgba(15, 19, 23, 0.00)',

  '&:hover': {
    cursor: 'pointer',
    background:
      'linear-gradient(0deg, var(--Card-card-surface-overlay-hover, rgba(80, 94, 113, 0.08)) 0%, var(--Card-card-surface-overlay-hover, rgba(80, 94, 113, 0.08)) 100%), var(--Card-card-surface, #FFF)',
  },
  '&:active': {
    background:
      'linear-gradient(0deg, var(--Card-card-surface-overlay-active, rgba(80, 94, 113, 0.16)) 0%, var(--Card-card-surface-overlay-active, rgba(80, 94, 113, 0.16)) 100%), var(--Card-card-surface, #FFF)',
  },
});

const LIIcon = styled('img', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$7',
  height: '$7',
});

const LIStatus = styled(Icon, {
  width: '16px',
  height: '16px',
});

const sortIsVisible = (a, b) => {
  if (a.isVisible === b.isVisible) {
    return 0;
  }

  return a.isVisible ? -1 : 1;
};

const LibraryItem = (props: LibraryItemProps) => {
  const { item, searchTerm } = props;
  const icon = item.icon || item.defaultConfiguration.icon;
  const deviceRunsOn = deviceKinds
    .map((device) => ({
      device,
      isVisible: item[getDeviceRunsOnCamelCase(device)],
    }))
    .sort(sortIsVisible);

  const statusKinds = [
    {
      key: 'successCount',
      icon: 'circle-check',
      color: theme.colors.green50.value,
      label: 'Success',
    },
    {
      key: 'failingCount',
      icon: 'octagon-exclamation',
      color: theme.colors.red50.value,
      label: 'Error',
    },
    {
      key: 'pendingCount',
      label: 'Other',
      icon: 'octagon-minus',
      theme: theme.colors.neutral60.value,
    },
  ];

  const modifiers = [
    {
      label: 'Self Service',
      icon: 'kandji-logo',
      isVisible: item.isSelfService,
    },
    {
      label: 'Update Only',
      icon: updateOnlyIconName,
      isVisible:
        item.installEnforcement === installTypes.CONTINUOUS &&
        item.isUpdateOnly,
    },
    {
      label: 'Continuously enforced',
      icon: 'infinity',
      isVisible:
        item.installEnforcement === installTypes.CONTINUOUS &&
        !item.isUpdateOnly,
    },
    {
      label: 'Install once',
      icon: 'install-once-16px',
      isVisible: item.installEnforcement === installTypes.ONCE,
    },
  ].sort(sortIsVisible);

  return (
    <Link
      to={item.defaultConfiguration.getUrl({ id: item.id })}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <LICard>
        <Flex alignItems="center">
          <Flex alignItems="center" gap="md" flex="1">
            <LIIcon src={icon} alt={item.name} />
            <Flex flow="column" alignItems="start" flex="1">
              <Heading size="4" css={{ fontWeight: 500, lineHeight: '$3' }}>
                {highlightedText(item.name, searchTerm)}
              </Heading>
              <Text size="1" css={{ fontWeight: 500, color: '$neutral70' }}>
                {highlightedText(item.defaultConfiguration.name, searchTerm)}
                {item.instanceName &&
                  highlightedText(` - ${item.instanceName}`, searchTerm)}
              </Text>
            </Flex>
          </Flex>

          <Flex gap="xl">
            {item.type !== apiTypes.DEP_PROFILE && (
              <Flex gap="md" alignItems="center">
                {statusKinds.map(
                  ({ key, label, icon: statusIcon, color: statusColor }) => (
                    <Flex key={key} gap="xs" pr5>
                      <LIStatus
                        key={key}
                        name={statusIcon as IconNames}
                        color={statusColor}
                      />
                      <Text
                        size="1"
                        css={{ fontWeight: 500, color: '$neutral70' }}
                      >
                        {item[key]} {label}
                      </Text>
                    </Flex>
                  ),
                )}
              </Flex>
            )}

            <Flex justifyContent="center" alignItems="center">
              {deviceRunsOn.map(({ device, isVisible }) => (
                <FlowTippy
                  key={device}
                  content={
                    <TippyContainer gap="xs">
                      {deviceTypes[device.toUpperCase()]}
                    </TippyContainer>
                  }
                >
                  <Box
                    css={{
                      visibility: isVisible ? 'unset' : 'hidden',
                    }}
                  >
                    <Icon
                      style={{ height: '20px', width: '20px' }}
                      name={deviceToIcon[device]}
                    />
                  </Box>
                </FlowTippy>
              ))}
            </Flex>

            <Flex gap="xs" justifyContent="center" alignItems="center">
              {modifiers.map(({ label, isVisible, icon: modifierIcon }) => (
                <FlowTippy
                  key={label}
                  content={<TippyContainer>{label}</TippyContainer>}
                >
                  <Box
                    css={{
                      visibility: isVisible ? 'unset' : 'hidden',
                    }}
                  >
                    <Icon
                      style={{ height: '16px', width: '16px' }}
                      name={modifierIcon as IconNames}
                    />
                  </Box>
                </FlowTippy>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </LICard>
    </Link>
  );
};

export default LibraryItem;
