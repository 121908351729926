/* istanbul ignore file */
import { Icon } from '@kandji-inc/bumblebee';
import {
  Box,
  Button,
  Flex,
  Heading,
  styled as NectarStyled,
  Select,
  Tabs,
  Text,
} from '@kandji-inc/nectar-ui';
import { api } from 'app/api/base';
import { API_BASE } from 'app/api/domains';
import { blueprintTypes, colors } from 'app/common/constants';
import { AddDevicesCard } from 'app/components/common/AddDevicesCard';
import { downloadFromBlob } from 'app/components/common/helpers';
import { getCompanyInfo } from 'app/components/integrations/Apple/api';
import history from 'app/router/history';
import { withPermissions } from 'contexts/account';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import featureFlags from 'src/config/feature-flags';
import styled from 'styled-components';
import {
  enableEnrollmentCode as callEnableEnrollmentCode,
  generateNewEnrollmentCode as callGenerateNewEnrollmentCode,
} from '../app/_actions/blueprint';
import {
  toggleEnrollmentPortalStatus as callToggleEnrollmentPortalStatus,
  toggleEnrollmentPortalStatus,
} from '../app/_actions/company';
import {
  setModal as callSetModal,
  setSnackbar as callSetSnackbar,
} from '../app/_actions/ui';
import AwesomeIconCircle from '../app/components/interface/AwesomeIconCircle';
import ToggleButtonNew from '../app/components/interface/buttons/ToggleButtonNew';
import ConfigureAPNSIcon from '../assets/img/configure-apns.svg';
import { ADEListView } from './ADEListView/ADEListView';
import { ManualEnrollment } from './ManualEnrollment/ManualEnrollment';

const Container = styled('div')`
  display: grid;
  margin: 27px 0 35px;
  gap: 15px;
`;

const EnrollmentEnabledContainer = NectarStyled('div', {
  marginLeft: -49,
  marginRight: -48,
  marginTop: 0,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '$4',
  gap: '$3',
});

const MainHeader = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 30px;
  font-weight: 600;
  line-height: 39px;
  color: ${colors.shark500};
`;

const SecondaryHeader = styled('div')`
  margin-top: 18px;
  font-family: var(--font-family-primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: ${colors.shark500};
`;

const MinorTitle = styled('div')`
  margin-bottom: 10px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: ${colors.shark500};
`;

const MainSection = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const PortalLink = styled('a')`
  font-family: var(--font-family-primary);
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline !important;
  color: #498bee !important;
  cursor: pointer;
`;

const TitleRowWrapper = styled('div')`
  display: grid;
  grid-template-columns: 350px 264px auto;
  padding-left: 20px;
  margin-bottom: 12px;
`;

const TitleRowElement = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${colors.shark500};
`;

const BlueprintRows = styled('div')`
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 14px;
`;

const BlueprintRowWrapper = styled('div')`
  display: grid;
  grid-template-columns: 350px 264px auto 30px;
  height: 71px;
  padding: 15px 30px 15px 20px;
  background: #f6f7f9;
  border-radius: 4px;
`;

const BlueprintNameWrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  margin-right: 10px;
`;

const BlueprintToggleButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BlueprintName = styled('div')`
  margin-left: 10px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  color: ${colors.shark500};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${(props) => !props.active && 'opacity: 0.3'};
`;

const BlueprintCodeWrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const BlueprintCode = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: ${colors.shark500};
  ${(props) => !props.active && 'opacity: 0.3'};
`;

const ChangeCode = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  width: 120px;
  font-family: var(--font-family-primary);
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  cursor: pointer;
  ${(props) => !props.active && 'opacity: 0.3'};
  svg {
    font-size: 14px;
    margin-right: 9px;
  }
`;

const ENROLLMENT_TABS = {
  AUTOMATED: 'automated',
  MANUAL: 'manual',
};

class AddDevices extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDownloading: false,
      chosenBlueprintId: null,
      apkUrl: null,
      hasMDMConfig: false,
    };
    this.generateNewCode = this.generateNewCode.bind(this);
    this.renderEnrollDeviceSection = this.renderEnrollDeviceSection.bind(this);
    this.renderEnrollmentPortalStatusSection =
      this.renderEnrollmentPortalStatusSection.bind(this);
    this.renderBlueprintsSection = this.renderBlueprintsSection.bind(this);
    this.toggleEnrollmentPortal = this.toggleEnrollmentPortal.bind(this);
    this.prepareCodeToRepresentation =
      this.prepareCodeToRepresentation.bind(this);
    this.enableAccessCode = this.enableAccessCode.bind(this);
    this.onDownload = this.onDownload.bind(this);
  }

  componentDidMount() {
    getCompanyInfo()
      .then(({ is_mdm: hasMDMConfig }) => {
        this.setState({ hasMDMConfig });
        if (!hasMDMConfig) {
          document.body.classList.add('white-bg');
        }
      })
      .finally(() => this.setState({ isLoading: false }));
    window.scroll({ top: 0 }); // move to /contexts/interface.js
  }

  componentWillUnmount() {
    document.body.classList.remove('white-bg');
  }

  generateNewCode(blueprintId) {
    const { generateNewEnrollmentCode } = this.props;
    return generateNewEnrollmentCode(blueprintId);
  }

  enableAccessCode(blueprintId, data) {
    const { enableEnrollmentCode, setSnackbar } = this.props;
    this.setState({ isLoading: true }, () =>
      enableEnrollmentCode(blueprintId, data)
        .catch(() => setSnackbar('Something went wrong'))
        .finally(() => this.setState({ isLoading: false })),
    );
  }

  renderEnrollDeviceSection() {
    const { chosenBlueprintId, isDownloading } = this.state;
    const { blueprints } = this.props;

    return (
      <Flex gap="lg" alignItems="end">
        <Select
          label="Blueprint"
          placeholder="Select Blueprint"
          searchable
          options={blueprints.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
          onChange={(e) => this.setState({ chosenBlueprintId: e })}
        />
        <Button
          variant="primary"
          onClick={this.onDownload}
          disabled={!chosenBlueprintId || isDownloading}
        >
          {isDownloading ? 'Downloading...' : 'Download'}
        </Button>
      </Flex>
    );
  }

  renderEnrollmentPortalStatusSection() {
    return (
      <>
        <MinorTitle>Enrollment Portal Link</MinorTitle>
        <MainSection>
          <PortalLink
            href={`${window.location.protocol}//${window.location.hostname}/enroll`}
            target="_blank"
          >
            {`${window.location.protocol}//${window.location.hostname}/enroll`}
          </PortalLink>
        </MainSection>
      </>
    );
  }

  prepareCodeToRepresentation(code) {
    if (!code) {
      return 'No code';
    }
    return `${code.slice(0, 3)}-${code.slice(3, 6)}`;
  }

  renderBlueprintsSection() {
    const { blueprints, companyInfo, setModal, permissions } = this.props;
    const disabled =
      !permissions.canManageBlueprints ||
      !get(companyInfo, 'is_enrollment_portal_active');
    return (
      <>
        <TitleRowWrapper>
          <TitleRowElement>Blueprint</TitleRowElement>
          <TitleRowElement>enrollment code</TitleRowElement>
        </TitleRowWrapper>
        <BlueprintRows>
          {blueprints
            .sort((a, b) =>
              a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
            )
            .map((blueprint) => (
              <BlueprintRowWrapper>
                <BlueprintNameWrapper>
                  <AwesomeIconCircle
                    icon={`ss-icon ${blueprint.icon}`}
                    color={blueprint.color}
                    size="42px"
                    border=""
                  />
                  <BlueprintName
                    active={
                      !disabled && get(blueprint, 'enrollment_code.is_active')
                    }
                  >
                    {blueprint.name}
                  </BlueprintName>
                </BlueprintNameWrapper>
                <BlueprintCodeWrapper>
                  <BlueprintCode
                    active={
                      !disabled && get(blueprint, 'enrollment_code.is_active')
                    }
                  >
                    {this.prepareCodeToRepresentation(
                      get(blueprint, 'enrollment_code.code'),
                    )}
                  </BlueprintCode>
                  <ChangeCode
                    active={
                      !disabled && get(blueprint, 'enrollment_code.is_active')
                    }
                    onClick={() => {
                      if (
                        !disabled &&
                        get(blueprint, 'enrollment_code.is_active')
                      ) {
                        setModal('WIDE_DIALOG', {
                          text: 'Generate New Code?',
                          caption:
                            'The previous enrollment code for this Blueprint will be revoked, and a new one will be generated.',
                          effectText: 'Generate new code',
                          effectAction: () =>
                            this.generateNewCode(blueprint.id),
                        });
                      }
                    }}
                  >
                    <Icon name="arrow-right-arrow-left" size="sm" />
                    <span>Change code</span>
                  </ChangeCode>
                </BlueprintCodeWrapper>
                <div />
                <BlueprintToggleButtonWrapper>
                  <ToggleButtonNew
                    onToggle={() =>
                      this.enableAccessCode(blueprint.id, {
                        is_active: !get(blueprint, 'enrollment_code.is_active'),
                      })
                    }
                    checked={get(blueprint, 'enrollment_code.is_active')}
                    small
                    disabled={disabled}
                  />
                </BlueprintToggleButtonWrapper>
              </BlueprintRowWrapper>
            ))}
        </BlueprintRows>
      </>
    );
  }

  onDownload() {
    const { chosenBlueprintId } = this.state;
    const { setSnackbar } = this.props;

    this.setState({ isDownloading: true });

    api(`${API_BASE}mdm/enroll-authed/${chosenBlueprintId}`)
      .get({}, { responseType: 'blob' })
      .then((response) => {
        downloadFromBlob(response.data);
        this.setState({ isDownloading: false });
      })
      .catch(() => {
        setSnackbar('Error creating and downloading enrollment profile');
        this.setState({ isDownloading: false });
      });
  }

  toggleEnrollmentPortal() {
    const { companyInfo, toggleEnrollmentPortalStatus, setSnackbar } =
      this.props;
    this.setState({ isLoading: true }, () =>
      toggleEnrollmentPortalStatus({
        is_enrollment_portal_active: !get(
          companyInfo,
          'is_enrollment_portal_active',
        ),
      })
        .catch(() => setSnackbar('Something went wrong'))
        .finally(() => this.setState({ isLoading: false })),
    );
  }

  renderManualEnrollment() {
    const { companyInfo, permissions } = this.props;
    const isMDM = get(companyInfo, 'is_mdm');
    const isEnrollmentPortalActive = get(
      companyInfo,
      'is_enrollment_portal_active',
    );
    const isEnrollmentPageEnabled = featureFlags.getFlag(
      'core_022824_enrollment-page',
    );

    return (
      <>
        {isMDM && (
          <AddDevicesCard
            key={2361}
            title="Enroll a single device"
            description={`Select the Blueprint you’d like to enroll a device in and click the Download button.
           'A one-time use enrollment profile will be generated and downloaded.'`}
          >
            {this.renderEnrollDeviceSection()}
          </AddDevicesCard>
        )}
        {isMDM && (
          <>
            <Heading
              size="1"
              css={
                isEnrollmentPageEnabled
                  ? { fontWeight: '$medium', padding: '0 $5' }
                  : { fontWeight: '$medium' }
              }
            >
              Enrollment Portal
            </Heading>
            <AddDevicesCard
              key={4523}
              title="Status"
              description="The Enrollment Portal allows your organization’s users to self-enroll their devices into
               Kandji. You may choose to allow use of the Enrollment Portal by turning it on or off."
              switchButton
              switchButtonDisabled={!permissions.canManageBlueprints}
              active={get(companyInfo, 'is_enrollment_portal_active')}
              onToggle={this.toggleEnrollmentPortal}
            >
              {this.renderEnrollmentPortalStatusSection()}
            </AddDevicesCard>
            {isEnrollmentPortalActive && (
              <AddDevicesCard
                key={8678}
                title="Enrollment code access"
                description="The Enrollment Portal uses Blueprint-specific codes to determine which Blueprint will be
               used for enrolling a device. You may generate new codes, or choose to disable enrollment access
               for certain Blueprints."
              >
                {this.renderBlueprintsSection()}
              </AddDevicesCard>
            )}
          </>
        )}
        {!isMDM && (
          <Flex flow="column" alignItems="center" pt10>
            {' '}
            <img
              src={ConfigureAPNSIcon}
              alt="configure-apns-icon"
              style={{ width: '230px', height: '120px' }}
            />
            <Heading size="4" css={{ fontWeight: '$medium' }}>
              Apple Push Notification service is not configured.
            </Heading>
            <Text>
              You will not be able to add devices until this is complete.{' '}
            </Text>
            <Button
              variant="primary"
              css={{ marginTop: '$4' }}
              onClick={() => history.push('/my-company/integrations')}
            >
              Configure APNs
            </Button>
          </Flex>
        )}
      </>
    );
  }

  renderWithTabs() {
    const {
      location,
      isDEPConfigured,
      companyInfo,
      toggleEnrollmentPortalStatus,
    } = this.props;

    const isManualEnrollmentPageEnabled = featureFlags.getFlag(
      'core_071024_manual_enrollment_portal',
    );

    const enrollmentTabs = [
      {
        label: 'Automated Device Enrollment',
        tabId: ENROLLMENT_TABS.AUTOMATED,
        onClick: () => {
          params.set('tab', ENROLLMENT_TABS.AUTOMATED);
          history.replace({ search: params.toString() });
        },
      },
      {
        label: 'Manual Enrollment',
        tabId: ENROLLMENT_TABS.MANUAL,
        onClick: () => {
          params.set('tab', ENROLLMENT_TABS.MANUAL);
          history.replace({ search: params.toString() });
        },
      },
    ];

    const params = new URLSearchParams(location.search);
    const defaultTab = params.get('tab') ?? ENROLLMENT_TABS.AUTOMATED;

    const enrollmentPortalActive = get(
      companyInfo,
      'is_enrollment_portal_active',
    );

    const vppInfo = companyInfo.vpp_info;
    const isWarningBannerOpen =
      vppInfo && vppInfo.is_configured && !vppInfo.has_control;

    return (
      <>
        <Tabs.Container
          css={{
            flex: 1,
            overflow: 'hidden',
            "& [role='tablist']": { padding: '0 $5' },
          }}
          defaultTabId={defaultTab}
          tabs={enrollmentTabs}
        >
          <Tabs.Content tabId="automated">
            <ADEListView
              isADEConfigured={isDEPConfigured}
              isWarningBannerOpen={isWarningBannerOpen}
            />
          </Tabs.Content>
          <Tabs.Content tabId="manual">
            {isManualEnrollmentPageEnabled ? (
              <ManualEnrollment
                enrollmentPortalActive={enrollmentPortalActive}
                toggleEnrollmentPortalStatus={toggleEnrollmentPortalStatus}
              />
            ) : (
              <Container>{this.renderManualEnrollment()}</Container>
            )}
          </Tabs.Content>
        </Tabs.Container>
      </>
    );
  }

  render() {
    const isEnrollmentPageEnabled = featureFlags.getFlag(
      'core_022824_enrollment-page',
    );

    if (isEnrollmentPageEnabled) {
      return (
        <EnrollmentEnabledContainer>
          <Heading
            size="1"
            css={{
              fontWeight: '$medium',
              color: '$neutral90',
              padding: '0 $5',
            }}
          >
            Enrollment
          </Heading>
          {this.renderWithTabs()}
        </EnrollmentEnabledContainer>
      );
    }

    return (
      <Container>
        <Heading
          size="1"
          css={{
            fontWeight: '$medium',
            color: '$neutral90',
          }}
        >
          Add Devices
        </Heading>
        {this.renderManualEnrollment()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  companyInfo: state.account.company,
  isDEPConfigured: state.account.company.is_dep,
  blueprints: state.data.blueprints,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSnackbar: callSetSnackbar,
      setModal: callSetModal,
      toggleEnrollmentPortalStatus: callToggleEnrollmentPortalStatus,
      enableEnrollmentCode: callEnableEnrollmentCode,
      generateNewEnrollmentCode: callGenerateNewEnrollmentCode,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withPermissions(AddDevices)),
);
