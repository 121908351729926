import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { constants } from '../../../common';
import createDetectionDateParam from '../../../common/params/detectionDateParam';
import createMultiEnumParam from '../../../common/params/multiselectFilterParam';
import createPaginationParam from '../../../common/params/paginationParam';
import createSearchQueryParam from '../../../common/params/searchQueryParam';
import {
  createSortByParam,
  createSortParam,
} from '../../../common/params/sortParam';
import type {
  DevicesFiltersState,
  PageSize,
  SortColumnDirection,
  SortColumnName,
  ThreatPosture,
  ThreatStatus,
} from '../../../threat.types';
import defaultParams from './defaultParams';

const StatusParam = createMultiEnumParam<ThreatStatus>(
  constants.THREAT_STATUS_LIST,
  defaultParams.STATUS,
);

const MalwarePostureParam = createMultiEnumParam<ThreatPosture>(
  constants.THREAT_POSTURE_LIST,
  defaultParams.MALWARE_POSTURE,
);

const PupPostureParam = createMultiEnumParam<ThreatPosture>(
  constants.THREAT_POSTURE_LIST,
  defaultParams.PUP_POSTURE,
);

const DetectionDateParam = createDetectionDateParam<DetectionDateFilterFields>(
  defaultParams.DETECTION_DATE,
);

const SearchQueryParam = createSearchQueryParam<string>(
  defaultParams.SEARCH_QUERY,
);

export const filtersParamConfig = {
  status: StatusParam,
  detectionDate: DetectionDateParam,
  malwarePosture: MalwarePostureParam,
  pupPosture: PupPostureParam,
  query: SearchQueryParam,
};

const { PageParam, PageSizeParam } = createPaginationParam<number, PageSize>(
  defaultParams.PAGE,
  defaultParams.PAGE_SIZE,
);

export const paginationParamConfig = {
  page: PageParam,
  pageSize: PageSizeParam,
};

const SortByParam = createSortByParam<SortColumnName>(
  constants.THREAT_TABLE_SORTABLE_FIELDS,
  defaultParams.SORT_BY,
);

const SortOrderParam = createSortParam<SortColumnDirection>(
  constants.THREAT_SORT_ORDER,
  defaultParams.SORT_ORDER,
);

export const sortParamConfig = {
  sortBy: SortByParam,
  sortOrder: SortOrderParam,
};

export const defaultFilters: DevicesFiltersState = {
  status: defaultParams.STATUS,
  detectionDate: defaultParams.DETECTION_DATE,
  malwarePosture: defaultParams.MALWARE_POSTURE,
  pupPosture: defaultParams.PUP_POSTURE,
  query: defaultParams.SEARCH_QUERY,
};
