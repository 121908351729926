import {
  Flex,
  Icon,
  type IconNames,
  SelectV2Primitives as Select,
  styled,
} from '@kandji-inc/nectar-ui';

const TriggerIcon = styled(Icon, {
  width: 20,
  height: 20,
});

const StyledSelectContent = styled(Select.Content, {
  width: 'var(--radix-select-trigger-width)',
  maxHeight: 320,
  margin: '4px 0',
  zIndex: 1,
});

interface ManualEnrollmentAuthSelectOption {
  label: string;
  value: string;
  icon: IconNames;
}

interface ManualEnrollmentAuthSelectProps {
  value: string;
  onValueChange: (value: string) => void;
  options: ManualEnrollmentAuthSelectOption[];
  disabled: boolean;
}

export function ManualEnrollmentAuthSelect({
  value,
  onValueChange,
  options,
  disabled = false,
}: ManualEnrollmentAuthSelectProps) {
  const triggerValue =
    options.find((o) => o.value === value)?.label || options[0].label;

  const triggerIconName =
    options.find((o) => o.value === value)?.icon || options[0].icon;

  return (
    <Select.Root value={value} onValueChange={onValueChange}>
      <Select.Trigger
        data-testid="manual-enrollment-auth-select"
        variant="input"
        compact
        disabled={disabled}
        css={{
          minWidth: 320,
        }}
      >
        <Select.Value>
          <Flex alignItems="center" gap="sm">
            <Icon name={triggerIconName} />
            {triggerValue}
          </Flex>
        </Select.Value>
        <Select.Icon>
          <TriggerIcon name="fa-angle-down-small" />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <StyledSelectContent position="popper">
          <Select.Viewport>
            {options.map((option) => (
              <Select.Item value={option.value} key={option.value}>
                <Icon name={option.icon} />
                <Select.ItemText>{option.label}</Select.ItemText>
              </Select.Item>
            ))}
          </Select.Viewport>
        </StyledSelectContent>
      </Select.Portal>
    </Select.Root>
  );
}
