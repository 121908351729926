import { Paragraph } from '@kandji-inc/nectar-ui';
import type {
  AllowedSelectValue,
  SelectOptionMemberList,
} from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSidebarToast } from 'src/hooks/useSidebarToast';
import ActionDialog from '../../../../components/action-dialog';
import type { MsDeviceComplianceInputs } from '../../../integrator/types/ms-device-compliance.types';
import useAddDevicePlatform from '../../hooks/use-add-device-platform';
import useGetUserGroupList from '../../hooks/use-get-user-group-list';
import type { IntegrationDetails } from '../../types/schema';
import { DEVICE_PLATFORMS } from '../details-integration/details-integration';
import { getPlatformLabel } from '../details-integration/details-integration';
import type { PlatformStatus } from '../details-integration/details-integration';
import SelectUserGroup from '../select-user-group';

type Props = {
  isDialogOpen: boolean;
  platform: PlatformStatus;
  integrationId: string;
  integrationDetails: IntegrationDetails;
  handleToggle: (args: any) => void;
};

const AddDevicePlatform = (props: Props) => {
  const {
    integrationDetails,
    platform,
    isDialogOpen,
    integrationId,
    handleToggle,
  } = props;

  const [groupOptions, setUserGroupOptions] = useState<
    SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>
  >([]);
  const platformLabel = getPlatformLabel(platform);

  const methods = useForm({
    mode: 'onChange',
    values: {
      macOsDevices:
        platform === DEVICE_PLATFORMS.macos
          ? true
          : !!integrationDetails?.macos_entra_group_ids?.length,
      iosDevices:
        platform === DEVICE_PLATFORMS.ios
          ? true
          : !!integrationDetails?.ios_entra_group_ids?.length,
      macOsDevices_groups: integrationDetails?.macos_entra_group_ids ?? [],
      iosDevices_groups: integrationDetails?.ios_entra_group_ids ?? [],
    },
  });

  const field =
    platform === DEVICE_PLATFORMS.macos ? 'macOsDevices' : 'iosDevices';

  useGetUserGroupList({ setUserGroupOptions });

  const handleNextClick = () => {
    const formInputs = methods.getValues();
    methods.trigger().then((isValid) => {
      if (isValid) {
        handleAddPlatform({ formInputs });
      }
    });
  };

  const { displayToast } = useSidebarToast();

  const addPlatformMutation = useAddDevicePlatform(integrationId);
  const isAdding = addPlatformMutation.isPending;

  const handleAddPlatform = ({
    formInputs,
  }: { formInputs: MsDeviceComplianceInputs }) => {
    addPlatformMutation.mutate(
      { formInputs },
      {
        onSuccess: () => {
          const title = `${platformLabel} device platform successfully added`;
          displayToast({
            title,
            content:
              'To deploy device compliance to Intune, you’ll need to add and install Microsoft Authenticator to your library.',
            variant: 'success',
          });
        },
        onError: () => {
          displayToast({
            title: 'Something went wrong',
            content: 'Please try again.',
            variant: 'error',
          });
        },
        onSettled: () => {
          handleToggle(null);
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <ActionDialog
        variant="primary"
        size="md"
        title={`Add ${platformLabel} platform`}
        content={
          <DialogContent
            field={field}
            options={groupOptions}
            platformLabel={platformLabel}
          />
        }
        isOpen={isDialogOpen}
        nextText="Add"
        nextClick={handleNextClick}
        backText="Cancel"
        backClick={() => handleToggle(null)}
        isProcessing={isAdding}
      />
    </FormProvider>
  );
};

const DialogContent = ({
  field,
  options,
  platformLabel,
}: {
  field: 'macOsDevices' | 'iosDevices';
  options: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>;
  platformLabel: string;
}) => (
  <>
    <Paragraph>
      Select the group that was configured in your Intune portal when
      configuring partner compliance for {platformLabel}.
    </Paragraph>
    <SelectUserGroup
      field={field}
      options={options}
      size="lg"
      css={{ paddingLeft: 0 }}
    />
  </>
);

export default AddDevicePlatform;
